import {ActionReducerMap, ActionReducer, MetaReducer} from '@ngrx/store';
import {isDevMode} from '@angular/core';
import {authFeatureKey, AuthState, authReducer, AuthEffects, AuthActions} from 'auth';
import {coreFeatureKey} from './core/core.selectors';
import {CoreState} from './core/core.state';
import {coreReducer} from './core/core.reducers';
import {CoreEffects} from './core/core.effects';
import {EnumsState} from "./enums/enums.state";
import {enumsFeatureKey} from "./enums/enums.selectors";
import {enumsReducer} from "./enums/enums.reducers";
import {EnumsEffects} from "./enums/enums.effects";
import {EntityNotificationEffects} from "./entities/entity-notification.effects";
import {emailFeatureKey} from "./email/email.selectors";
import {emailReducer, EmailState} from "./email/email.reducers";
import {EmailEffects} from "./email/email.effects";

export interface AppState {
  [authFeatureKey]: AuthState;
  [coreFeatureKey]: CoreState;
  [enumsFeatureKey]: EnumsState;
  [emailFeatureKey]: EmailState;
}

export const reducers: ActionReducerMap<AppState> = {
  [authFeatureKey]: authReducer,
  [coreFeatureKey]: coreReducer,
  [enumsFeatureKey]: enumsReducer,
  [emailFeatureKey]: emailReducer,
};

function logoutReset(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: AppState, action) => reducer(action.type === AuthActions.logout.type ? {
    [authFeatureKey]: state[authFeatureKey],
  } : state, action);
}

function actionTypeLogger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    console.log(action.type);
    return reducer(state, action);
  }
}

export const metaReducers: MetaReducer<AppState>[] = isDevMode() ? [actionTypeLogger, logoutReset] : [logoutReset];

export const effects = [
  AuthEffects,
  CoreEffects,
  EnumsEffects,
  EntityNotificationEffects,
  EmailEffects,
];
