import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NgIf} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatDividerModule} from "@angular/material/divider";
import {collectionToKeyValue, SafePipe} from "caig-utils";
import {SessionCase} from "../../../../core/interface/session.interface";
import {RenderedEmail} from "../../../../core/service/email.service";
import {Employee} from "../../../../core/interface/employees.interface";
import {CustomizeEventComponent} from "../../../customize-event/customize-event.component";
import {DynamicFormModule, SelectField} from "dynamic-form";
import {ActivatedRoute, Router} from "@angular/router";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'lib-email-preview',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    SafePipe,
    CustomizeEventComponent,
    MatCheckboxModule,
    DynamicFormModule,
    FormsModule,
  ],
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailPreviewComponent implements OnInit {
  @Input({required: true}) settlement: SessionCase | null = null;
  @Input({required: true}) preview: RenderedEmail | null = null;
  @Input({required: true}) defaultEventCode: number | undefined;
  @Input() employees: Employee[] | null = null;

  @Output() send = new EventEmitter<SendPayload>();

  employeeField!: SelectField;
  includeAltEmail: boolean = true;

  private router = inject(Router);
  private route = inject(ActivatedRoute);

  ngOnInit() {
    if (this.employees) {
      const selectedEmployee = this.route.snapshot.queryParamMap.get('employeeId');
      this.employeeField = new SelectField({
        name: 'employee',
        label: 'Employee',
        options: collectionToKeyValue<Employee, 'name', 'id'>('name', 'id')(this.employees),
        appearance: 'outline',
        subscriptSizing: 'dynamic',
        defaultValue: selectedEmployee ? parseInt(selectedEmployee) : this.employees[0]?.id,
        onChange: (employeeId) => this.router.navigate([], {queryParams: { employeeId }}),
      });
    }
  }

  emitSend(toggle: CustomizeEventComponent) {
    const payload: SendPayload = toggle.isActive ? toggle.form.value : {};
    if (this.employees) {
      payload.includeAltEmail = this.includeAltEmail;
    }
    this.send.emit(payload);
  }
}

export interface SendPayload {
  eventCode?: number;
  eventMessage?: string;
  includeAltEmail?: boolean;
}
