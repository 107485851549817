import {BaseColumn} from './base-column';
import {VsTableColumnConfig, VsTableColumnDataType} from "../types";

export class DateColumn<T> extends BaseColumn<T> {
  public format: string;
  public dataType = VsTableColumnDataType.Date;
  constructor(config: VsTableColumnConfig<T>) {
    super(config);
    this.format = config.format || 'shortDate';
  }
}
