import {VsTableColumn} from "./types";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";

export const calcProp = (column: VsTableColumn) => '_' + column.field;

export const rowIndex = (viewport: CdkVirtualScrollViewport, viewportIndex: number) =>
  viewport.getRenderedRange().start + viewportIndex;

export const focusFirstMatFormField = (element: HTMLElement) => {
  if (element.tagName.toLowerCase() === 'mat-form-field') {
    const input = element.querySelector('input');
    if (input) {
      (input as HTMLElement).focus();
      return;
    }
  }
  for (let i = 0; i < element.children.length; i++) {
    focusFirstMatFormField(element.children[i] as HTMLElement);
    if (document.activeElement !== document.body) {
      break;
    }
  }
}
