<div class="flex flex-col h-full">
  <div class="flex justify-between items-center p-2">
    <div class="p-2 mr-4">
      <h1 class="m-0">Events</h1>
    </div>
  </div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Filters</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mt-8">
      <dynamic-form *ngIf="formFields$ | async as fields"
                    [form]="form"
                    [fields]="fields"
                    [model]="formModel$ | async"></dynamic-form>
      <div class="flex justify-center gap-6">
        <button mat-raised-button color="primary" (click)="applyFilters()">Apply</button>
        <button mat-raised-button color="warn" [disabled]="!(filterActive$ | async)" (click)="clearFilters()">Clear</button>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="flex-grow mt-10">
    <vs-table
      [data]="events$ | async"
      [columns]="columns"
      cacheKey="events-list"
      enableRowClick
      (rowClick)="viewEmployee($event.row)"
    ></vs-table>
  </div>
</div>
