import {Component, inject, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {filter, tap} from 'rxjs/operators';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {selectAuthLoading, selectAuthError} from '../../store/auth.selectors';
import {AuthActions} from '../../store/action-types';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  private store = inject(Store);
  private cd = inject(ChangeDetectorRef);

  public loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  public isLoggingIn$ = this.store.select(selectAuthLoading);
  public loginError$ = this.store.select(selectAuthError)
    .pipe(
      filter((err): err is string => !!err),
      tap(() => {
        this.loginForm.enable();
        this.cd.detectChanges();
      }),
    );

  public login(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.loginForm.disable();
    const { username, password } = this.loginForm.value as { username: string, password: string };
    this.store.dispatch(AuthActions.login({ withCredentials: { username, password } }));
  }
}
