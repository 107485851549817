import {Directive, Input} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MatFormFieldAppearance, SubscriptSizing} from "@angular/material/form-field";
import {ThemePalette} from "@angular/material/core";
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Directive()
export class CustomFormControl {
  private _required: boolean = false;
  private _disabled: boolean = false;

  @Input({required: true}) form!: FormGroup;
  @Input({required: true}) controlName!: string;

  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() color: ThemePalette = 'primary';
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() value: any;
  @Input() hintAlign: 'start' | 'end' | undefined;
  @Input() hint: string | undefined;
  @Input()
  get required(): boolean { return this._required }
  set required(req: any) { this._required = coerceBooleanProperty(req) }
  @Input()
  get disabled(): boolean { return this._disabled }
  set disabled(dis: any) { this._disabled = coerceBooleanProperty(dis) }

  get control(): FormControl {
    return this.form.controls[this.controlName] as FormControl;
  }
}
