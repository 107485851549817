import {Injectable, inject} from '@angular/core';
import {MatSnackBarConfig, MatSnackBar} from '@angular/material/snack-bar';
import {Subject, debounceTime} from 'rxjs';
import {NotificationMessage} from './interfaces';
import {DetailedSnackbarComponent} from './detailed-snackbar/detailed-snackbar.component';

@Injectable({providedIn: 'root'})
export class NotificationService {
  private static readonly DEFAULT_ACTION = 'CLOSE';
  private static readonly DEFAULT_CONFIG: MatSnackBarConfig = {
    duration: 10000,
    horizontalPosition: 'right',
    verticalPosition: 'top',
  };

  private snackBar = inject(MatSnackBar);
  private notification$ = new Subject<NotificationMessage>();

  constructor() {
    this.notification$
      .pipe(debounceTime(200))
      .subscribe((msg) => this.openSnackbar(msg));
  }

  public showSimpleMessage(
    message: string,
    action: string = NotificationService.DEFAULT_ACTION,
    config: MatSnackBarConfig = {...NotificationService.DEFAULT_CONFIG},
  ): void {
    this.notification$.next({message, action, config, isDetailed: false});
  }
  public showDetailedMessage(
    message: string,
    details: any,
    config: MatSnackBarConfig = {...NotificationService.DEFAULT_CONFIG},
  ): void {
    config.data = {message, details};
    this.notification$.next({message, action: '', config, isDetailed: true});
  }
  private openSnackbar(notification: NotificationMessage): void {
    if (notification.isDetailed) {
      this.snackBar.openFromComponent(DetailedSnackbarComponent, notification.config);
    } else {
      this.snackBar.open(notification.message, notification.action, notification.config);
    }
  }
}
