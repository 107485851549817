<currency [form]="form"
          [controlName]="config.name"
          [appearance]="config.appearance"
          [color]="config.color"
          [placeholder]="config.placeholder"
          [required]="config.required"
          [disableNegative]="config.disableNegative"
          [hint]="config.hint?.message"
          [hintAlign]="config.hint?.align"
          [subscriptSizing]="config.subscriptSizing"
          [label]="config.label"></currency>
