import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class PhoneService {
  private static readonly HTTP_OPTIONS = {params: {token: 'Uzh4t4mRB3AGvbz99CeMcpkWHVb8hXRJ'}};
  private http = inject(HttpClient);

  lookup(phoneNumbers: string[]): Observable<PhoneNumberInfo[]> {
    return this.http.post<PhoneNumberInfo[]>(
      environment.phoneApiUrl + '/phoneNumber/lookup',
      phoneNumbers,
      PhoneService.HTTP_OPTIONS,
    );
  }

  sms(to: string, body: string): Observable<SMSResponse> {
    return this.http.post<SMSResponse>(
      environment.phoneApiUrl + '/sms',
      { to, body },
      PhoneService.HTTP_OPTIONS,
    );
  }

  bulkSms(to: string[], body: string): Observable<void> {
    return this.http.post<void>(
      environment.phoneApiUrl + '/sms/bulk',
      { to, body },
      PhoneService.HTTP_OPTIONS,
    );
  }

  ttsCall(to: string, body: string): Observable<SMSResponse> {
    return this.http.post<SMSResponse>(
      environment.phoneApiUrl + '/voice',
      { to, body },
      PhoneService.HTTP_OPTIONS,
    );
  }

  bulkTtsCall(to: string[], body: string): Observable<void> {
    return this.http.post<void>(
      environment.phoneApiUrl + '/voice/bulk',
      { to, body },
      PhoneService.HTTP_OPTIONS,
    );
  }

  voiceCallToken(): Observable<VoiceCallToken> {
    return this.http.get<VoiceCallToken>(environment.phoneApiUrl + '/voice/token');
  }
}

export interface SMSResponse {
  accountSid: string;
  apiVersion: string;
  body: string;
  dateCreated: string;
  dateSent: string;
  dateUpdated: string;
  directions: any;
  errorCode: string;
  errorMessage: string;
  from: any;
  messagingServiceSid: string;
  numMedia: string;
  numSegments: string;
  price: string;
  priceUnit: string;
  sid: string;
  status: any;
  subresourceUris: { media: string };
  to: string;
  uri: string;
}

export interface PhoneNumberInfo {
  number: string;
  standardFormat: string;
  nationalFormat: string;
  countryCode: string;
  carrier: string;
  type: string;
  sms: boolean;
  error: string;
  errorCause: string;
}

export interface VoiceCallToken {
  id: string;
  token: string;
}
