import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Employee} from "../../core/interface/employees.interface";
import {NgIf} from "@angular/common";

@Component({
  selector: 'lib-employee-notes',
  standalone: true,
  imports: [
    NgIf,
  ],
  templateUrl: './employee-notes.component.html',
  styleUrls: ['./employee-notes.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeNotesComponent {
  @Input({required: true}) employee!: Employee;
}
