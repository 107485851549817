import { NgModule } from '@angular/core';
import {EmployeesRoutingModule} from "./employees-routing.module";
import {EmployeesListComponent} from "./employees-list/employees-list.component";
import {ViewEmployeeComponent} from "./view-employee/view-employee.component";

@NgModule({
  imports: [
    EmployeesRoutingModule,
    EmployeesListComponent,
    ViewEmployeeComponent,
  ]
})
export class EmployeesModule { }
