import {FieldType} from "../types";
import {BaseField, BaseFieldConfig} from "./base-field";

export class CurrencyField extends BaseField<string | number> {
  override type = FieldType.Currency;
  placeholder: string;
  disableNegative: boolean;
  constructor(config: CurrencyFieldConfig) {
    super(config);
    this.placeholder = config.placeholder || '';
    this.disableNegative = !!config.disableNegative;
  }
}

export interface CurrencyFieldConfig extends BaseFieldConfig<string | number> {
  placeholder?: string;
  disableNegative?: boolean;
}
