import moment from "moment";

export function convertToMoment(value: any): moment.Moment | null {
  if (!value) {
    return null;
  }
  const m = moment(value);
  return m.isValid() ? m : null;
}

export function convertToString(value?: moment.Moment | null): string | null {
  return value ? value.format('YYYY-MM-DD') : null;
}
