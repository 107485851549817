export function currencyRound(n: number) {
  return Number(n.toFixed(2));
}

export function sumCurrencyBy<T>(items: T[], project: (i: T) => number) {
  return items
    .map(project)
    .reduce((sum, item) => currencyRound(sum + item), 0);
}

export function sumCurrency(numbers: number[]) {
  return numbers
    .reduce((sum, item) => currencyRound(sum + item), 0);
}
