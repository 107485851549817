import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {first, tap} from "rxjs/operators";
import {isTruthy} from "caig-utils";
import {map} from "rxjs";
import {SessionRole} from "../../enum/session.enums";
import {selectUser} from "../../store/core/core.selectors";

export const adminGuard: (role: SessionRole.Administrator | SessionRole.Superadmin) => CanActivateFn = (role) => (route, state) => {
  const router = inject(Router);
  const store = inject(Store);
  return store.select(selectUser).pipe(
    first(isTruthy),
    map((user) => {
      if (user.roleId === role) {
        return true;
      }
      switch (role) {
        case SessionRole.Administrator:
          return user.roleId === SessionRole.Superadmin;
        default:
          return false;
      }
    }),
    tap((canActivate) => {
      if (!canActivate) {
        router.navigateByUrl('/');
      }
    })
  );
};
