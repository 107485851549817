import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from 'notification';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnDestroy {
  @Input({required: true}) public endpoint!: string;
  @Output() public uploaded = new EventEmitter<File[]>();

  public isLoading = false;
  public queuedFiles: File[] = [];
  private onDestroy$ = new Subject<void>();
  private http = inject(HttpClient);
  private notifications = inject(NotificationService);
  private cd = inject(ChangeDetectorRef);

  public chooseFile(files: FileList | null): void {
    if (!files || files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      this.queuedFiles.unshift(files[i]);
    }
  }
  public upload(): void {
    const formData = new FormData();
    this.queuedFiles.forEach((f) => formData.append('files', f));
    this.isLoading = true;
    const resetLoading = () => {
      this.isLoading = false;
      this.cd.detectChanges();
    };
    this.http.post(this.endpoint, formData).pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: () => {
          resetLoading();
          this.uploaded.emit(this.queuedFiles);
          this.notifications.showSimpleMessage(`Successfully uploaded ${this.queuedFiles.length} file${this.queuedFiles.length === 1 ? '' : 's'}`);
          this.queuedFiles = [];
        },
        error: () => resetLoading(),
      });
  }
  public ngOnDestroy(): void {
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }
}
