import {Component, Inject, OnInit, signal, WritableSignal} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {FormGroup} from "@angular/forms";
import {BehaviorSubject, combineLatest, firstValueFrom, map, Observable, startWith} from "rxjs";
import {BaseField, CheckboxField, DynamicFormModule, InputField, InputFieldButton} from "dynamic-form";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {UserEntityService} from "../../service/entity-data/user-entity.service";
import {NotificationService} from "notification";
import {Store} from "@ngrx/store";
import {PasswordService} from "../../service/password.service";
import {selectAuthState} from "auth";
import {AsyncPipe, NgIf} from "@angular/common";
import {PasswordRequirementsComponent} from "../password-requirements/password-requirements.component";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'lib-change-password',
  standalone: true,
  imports: [
      NgIf,
      MatDialogModule,
      PasswordRequirementsComponent,
      DynamicFormModule,
      MatButtonModule,
      AsyncPipe,
  ],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public form = new FormGroup({});
  public validPassword$ = new BehaviorSubject<boolean>(false);
  public fields: WritableSignal<BaseField<any>[][] | null> = signal(null);
  public disableSave$!: Observable<boolean>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangePasswordData,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private dataService: UserEntityService,
    private notifications: NotificationService,
    private clipboard: Clipboard,
    private store: Store,
    private passwordService: PasswordService,
  ) {
  }
  public async ngOnInit() {
    const invalidForm$ = this.form.statusChanges
      .pipe(
        map((status) => status !== 'VALID'),
        startWith(true)
      );
    this.disableSave$ = combineLatest([invalidForm$, this.validPassword$])
      .pipe(
        map(([invalidForm, validPassword]) => invalidForm || !validPassword)
      );
    const newPassword = new InputField({
      name: 'password',
      label: 'New Password',
      inputType: 'password',
      required: true,
    });
    const confirmPassword = new InputField({
      name: 'confirmPassword',
      label: 'Confirm New Password',
      inputType: 'password',
      required: true,
    });
    confirmPassword.buttons = [ visibilityButton(confirmPassword) ];
    newPassword.buttons = [ visibilityButton(newPassword) ];
    const fields: BaseField<any>[][] = [[newPassword], [confirmPassword]];
    if (this.data?.self) {
      const authState = await firstValueFrom(this.store.select(selectAuthState));
      const storedPassword = this.data.copyAuthPassword ? authState.loginPassword : undefined;
      const currentPassword = new InputField({
        name: 'currentPassword',
        label: 'Current Password',
        inputType: 'password',
        required: true,
        defaultValue: storedPassword || undefined,
        disabled: !!storedPassword,
      });
      currentPassword.buttons = [ visibilityButton(currentPassword) ];
      fields.unshift([ currentPassword ]);
    } else {
      newPassword.buttons.push(
        this.passwordService.generatePasswordButton(this.form),
        copyContentButton(this.clipboard, this.notifications),
      );
      if (this.data) {
        fields.push([ changePasswordOnNextLogin ]);
      }
    }
    this.fields.set(fields);
  }
  public save(): void {
    const formValue = this.form.getRawValue();
    if (this.data) {
      this.form.disable();
      this.dataService.patch(this.data.userId, formValue, this.data.self)
        .then(() => {
          this.notifications.showSimpleMessage('Successfully changed password');
          this.dialogRef.close(true);
        })
        .catch(() => this.form.enable());
    } else {
      this.dialogRef.close(formValue);
    }
  }
}

export const visibilityButton = (field: InputField): InputFieldButton => {
  const button = {
    icon: 'visibility',
    callback: () => {
      field.inputType = field.inputType === 'password' ? 'text' : 'password';
      button.icon = button.icon === 'visibility' ? 'visibility_off' : 'visibility';
      button.tooltip = button.tooltip === 'Show Password' ? 'Hide Password' : 'Show Password';
    },
    tooltip: 'Show Password',
  };
  return button;
};

export const copyContentButton = (clipboard: Clipboard, notifications: NotificationService): InputFieldButton => {
  return {
    icon: 'content_copy',
    callback: (value: string) => {
      clipboard.copy(value);
      notifications.showSimpleMessage('Copied password to clipboard');
    },
    disable: (value: string) => !value,
    tooltip: 'Copy password to clipboard',
  };
}

export const changePasswordOnNextLogin = new CheckboxField({
  name: 'mustChangePassword',
  label: 'User must change password on next login',
  defaultValue: true,
});

export interface ChangePasswordData {
  userId: number;
  self: boolean;
  copyAuthPassword?: boolean;
}
