import {inject} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NotificationService} from "notification";
import {Observable, tap} from "rxjs";

export abstract class EntityBaseService<T extends EntityBase> {
  protected http = inject(HttpClient);
  protected notifications = inject(NotificationService);

  protected abstract baseUrl: string;
  protected abstract entityName: string;
  protected abstract entityNamePlural: string;

  getAll(params?: HttpParams): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl, {params});
  }

  get(id: string | number, params?: HttpParams): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${id}`, {params});
  }

  patch(id: string | number, payload: Partial<T>, params?: HttpParams): Observable<T> {
    return this.notify(
      this.http.patch<T>(`${this.baseUrl}/${id}`, payload, {params}),
      'updated',
      false,
    );
  }

  save(entity: Partial<T>, params?: HttpParams): Observable<T> {
    if (hasId(entity)) {
      return this.notify(
        this.http.put<T>(`${this.baseUrl}/${entity.id}`, entity, {params}),
        'updated',
        false,
      );
    }
    return this.notify(
      this.http.post<T>(this.baseUrl, entity, {params}),
      'created',
      false,
    );
  }

  remove(ids: (string | number) | string[] | number[], params?: HttpParams): Observable<void> {
    if (Array.isArray(ids)) {
      return this.notify(
        this.http.post<void>(`${this.baseUrl}/bulkDelete`, ids, {params}),
        'deleted',
        true,
      );
    }
    return this.notify(
      this.http.delete<void>(`${this.baseUrl}/${ids}`, {params}),
      'deleted',
      false,
    );
  }

  createMany(entities: Omit<T, 'id'>[]): Observable<void> {
    return this.notify(
      this.http.post<void>(`${this.baseUrl}/bulk`, entities),
      'created',
      true,
    );
  }

  protected notify<K>(obs$: Observable<K>, action: 'created' | 'deleted' | 'updated', plural: boolean): Observable<K> {
    const msg = `Successfully ${action} ${plural ? this.entityNamePlural : this.entityName}`;
    return obs$.pipe(tap(() => this.notifications.showSimpleMessage(msg)));
  }
}

interface EntityBase {
  id: string | number;
}

function hasId<T extends EntityBase>(entity: Partial<T>): entity is T {
  return !!entity.id;
}
