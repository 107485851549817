import {Injectable} from "@angular/core";
import {EntityStoreBaseService} from "./entity-store-base.service";
import {EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {SettlementUser} from "./settlement-user-entity.service";
import {SessionRole} from "../../enum/session.enums";

@Injectable({providedIn: 'root'})
export class UserEntityService extends EntityStoreBaseService<User> {
  constructor(serviceFactory: EntityCollectionServiceElementsFactory) {
    super('User', serviceFactory);
  }
}

export interface User extends SettlementUser {
  portals: string; // comma separated Portals
  roleId: SessionRole;
  roleName: string;
  settlements?: any[];
}
