import {forwardRef, Injectable, Type} from "@angular/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import moment from "moment";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatFormFieldControl} from "@angular/material/form-field";

@Injectable()
export class CustomMomentDateAdapter extends MomentDateAdapter {
  private static readonly APP_DATE_FORMATS = [
    'YYYY-MM-DD', 'MM/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/DD/YYYY',
    'YY-MM-DD', 'MM/DD/YY', 'M/D/YY', 'MM/D/YY', 'M/DD/YY'
  ];
  override parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    if (value && typeof value === 'string') {
      return moment(value, CustomMomentDateAdapter.APP_DATE_FORMATS, this.locale, true);
    }
    return super.parse(value, parseFormat);
  }
}

const APP_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const getDateProviders = (component: Type<any>) => [
  {
    provide: MatFormFieldControl,
    useExisting: forwardRef(() => component),
  },
  {
    provide: DateAdapter,
    useClass: CustomMomentDateAdapter,
  },
  {
    provide: MAT_DATE_LOCALE,
    useValue: 'en-US',
  },
  {
    provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    useValue: { useUtc: true },
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: APP_FORMATS,
  }
];
