import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {ProcessingMessage, SidenavComponent, SidenavComponentMessage} from "sidenav-stack";
import {ComposedEmail, EmailService} from "../../../../core/service/email.service";
import {Observable, tap} from "rxjs";
import {MatDividerModule} from "@angular/material/divider";
import {AsyncPipe, NgIf} from "@angular/common";
import {SafePipe} from "caig-utils";

@Component({
  selector: 'lib-view-email',
  standalone: true,
  imports: [
    MatDividerModule,
    NgIf,
    SafePipe,
    AsyncPipe,
  ],
  templateUrl: './view-email.component.html',
  styleUrls: ['./view-email.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewEmailComponent implements SidenavComponent<void>, OnInit {
  @Input({required: true}) id!: string;
  @Output() controlMsg = new EventEmitter<SidenavComponentMessage>(true);

  private emailService = inject(EmailService);

  email$!: Observable<ComposedEmail>;

  ngOnInit() {
    this.controlMsg.emit(new ProcessingMessage(true));
    this.email$ = this.emailService.getEmail(this.id).pipe(
      tap(() => this.controlMsg.emit(new ProcessingMessage(false)))
    );
  }
}
