import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {BaseField, DynamicFormModule, SelectField} from "dynamic-form";
import {MatToolbarModule} from "@angular/material/toolbar";
import {AsyncPipe, NgIf} from "@angular/common";
import {
  combineLatest,
  debounceTime, filter,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  withLatestFrom
} from "rxjs";
import {Employee} from "../../../../core/interface/employees.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {EmailService} from "../../../../core/service/email.service";
import {first, shareReplay} from "rxjs/operators";
import {LoadingService} from "../../../../core/service/loading.service";
import {MatIconModule} from "@angular/material/icon";
import {NotificationService} from "notification";
import {EmailPreviewComponent, SendPayload} from "../email-preview/email-preview.component";
import {SendEmailComponent} from "../send-email.component";
import {FormGroup} from "@angular/forms";
import {selectUser} from "../../../../core/store/core/core.selectors";
import {isTruthy} from "caig-utils";

@Component({
  selector: 'lib-single-email',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    MatToolbarModule,
    MatIconModule,
    DynamicFormModule,
    EmailPreviewComponent,
  ],
  templateUrl: './single-email.component.html',
  styleUrls: ['./single-email.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleEmailComponent extends SendEmailComponent {
  private route = inject(ActivatedRoute);
  private loadingService = inject(LoadingService);
  private emailService = inject(EmailService);
  private router = inject(Router);
  private notifications = inject(NotificationService);

  employee$: Observable<Employee> = this.route.data.pipe(
    map((data) => data['employee']),
    shareReplay(1),
  );

  private employeeEmailAddresses$ = this.employee$.pipe(
    map((employee) => [employee.email, employee.emailAlt].filter((e) => !!e)),
    shareReplay(1),
  );

  addressForm: FormGroup = new FormGroup({});
  addressFields: BaseField<any>[][] = [
    [
      new SelectField({
        name: 'fromAddress',
        label: 'From:',
        options: combineLatest([
          this.store.select(selectUser).pipe(filter(isTruthy)),
          this.settlement$,
        ]).pipe(
          map(([user, settlement]) => [settlement.adminEmail, user.email].filter((e) => !!e)),
        ),
        required: true,
      }),
    ]
  ];
  addressModel$ = combineLatest([this.employeeEmailAddresses$, this.settlement$]).pipe(
    map(([addresses, settlement]) => ({
      toAddress: addresses[0],
      fromAddress: settlement.adminEmail,
    })),
  );

  preview$ = this.templateForm.valueChanges.pipe(
    debounceTime(100),
    withLatestFrom(this.employee$),
    switchMap(([value, employee]) => {
      const {templateId, signatureId} = this.templateForm.getRawValue();
      if (!templateId || !signatureId) {
        return of(null);
      }
      return this.loadingService.load(this.emailService.renderEmail({templateId, signatureId, employeeId: employee.id}))
        .pipe(startWith(null));
    }),
    shareReplay(1),
  );

  constructor() {
    super();
    this.addressFields[0].unshift(new SelectField({
      name: 'toAddress',
      label: 'To:',
      options: this.employeeEmailAddresses$,
      required: true,
    }));
  }

  sendEmail(event: SendPayload) {
    combineLatest([this.settlement$, this.employee$])
      .pipe(
        first(),
        switchMap(([settlement, employee]) => this.loadingService.load(
          this.emailService.sendEmail({
            fromAddress: this.addressForm.value.fromAddress,
            toAddress: this.addressForm.value.toAddress,
            ccAddress: settlement.adminCc,
            templateId: this.templateForm.value.templateId,
            signatureId: this.templateForm.value.signatureId,
            toName: employee.name,
            employeeId: employee.id,
            ...event,
          }).pipe(map(() => employee))
        ))
      )
      .subscribe((employee) => {
        this.notifications.showSimpleMessage(`Successfully sent email to ${employee.name}`);
        this.router.navigate(['../'], {relativeTo: this.route, queryParamsHandling: 'preserve'});
      });
  }
}
