import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {MatChipsModule} from "@angular/material/chips";
import {Employee} from "../../core/interface/employees.interface";

@Component({
  selector: 'lib-employee-tags',
  standalone: true,
  imports: [
    MatChipsModule,
    NgForOf,
    NgIf
  ],
  templateUrl: './employee-tags.component.html',
  styleUrls: ['./employee-tags.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeTagsComponent {
  @Input({required: true}) employee!: Employee;
}
