import {AppEventType} from "../../interface/events.interface";
import {EmployeeStatus, ParticipationStatus} from "../../interface/employees.interface";
import {CallType} from "../../enum/call-types.enums";
import {UserRole} from "../../interface/session.interface";

class StoredEnums<T> {
  loading = false;
  loaded = false;
  error = false;
  values: T[] | null = null;
}

export interface EnumsState {
  employeeStatus: StoredEnums<EmployeeStatus>;
  allStates: StoredEnums<string>;
  settlementStates: StoredEnums<string>;
  bueLocations: StoredEnums<string>;
  bueLocals: StoredEnums<string>;
  bueRegions: StoredEnums<string>;
  eventTypesPortal: StoredEnums<AppEventType>;
  eventTypesEmail: StoredEnums<AppEventType>;
  callTypes: StoredEnums<CallType>;
  userRoles: StoredEnums<UserRole>;
  participationStatuses: StoredEnums<ParticipationStatus>;
  tags: StoredEnums<string>;
}

export const initialEnumsState: EnumsState = {
  employeeStatus: new StoredEnums<EmployeeStatus>(),
  allStates: new StoredEnums<string>(),
  settlementStates: new StoredEnums<string>(),
  bueLocations: new StoredEnums<string>(),
  bueLocals: new StoredEnums<string>(),
  bueRegions: new StoredEnums<string>(),
  eventTypesPortal: new StoredEnums<AppEventType>(),
  eventTypesEmail: new StoredEnums<AppEventType>(),
  callTypes: new StoredEnums<CallType>(),
  userRoles: new StoredEnums<UserRole>(),
  participationStatuses: new StoredEnums<ParticipationStatus>(),
  tags: new StoredEnums<string>(),
};
