import {Component} from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {InputField} from "../../class/input-field";

@Component({
  selector: 'lib-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css'],
})
export class InputFieldComponent extends BaseFieldComponent<InputField> {

}
