import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  MenuMessage,
  ProcessingMessage,
  SidenavComponent,
  SidenavComponentMenuItem,
  SidenavComponentMessage
} from "sidenav-stack";
import {OverlayService} from "../../core/service/overlay.service";
import {Employee} from "../../core/interface/employees.interface";
import {PhoneCallOverlayComponent} from "../phone-call-overlay/phone-call-overlay.component";
import {TwilioVoiceService} from "../../core/service/twilio-voice.service";
import {MatCardModule} from "@angular/material/card";
import {DatePipe, NgIf} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {TimePipe} from "caig-utils";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'lib-phone-call-sidenav',
  standalone: true,
  imports: [
    NgIf,
    DatePipe,
    TimePipe,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCardModule,
    PhoneCallOverlayComponent,
  ],
  templateUrl: './phone-call-sidenav.component.html',
  styleUrls: ['./phone-call-sidenav.component.css'],
})
export class PhoneCallSidenavComponent implements SidenavComponent<void>, OnInit, OnDestroy {
  private overlayService = inject(OverlayService);
  private cd = inject(ChangeDetectorRef);
  twilioService = inject(TwilioVoiceService);

  private callButton: SidenavComponentMenuItem = {
    name: 'Call',
    callback: () => this.call(),
    disabled: true,
    color: 'primary',
  };
  private hangUpButton: SidenavComponentMenuItem = {
    name: 'Hang Up',
    callback: () => this.hangUp(),
    disabled: true,
    color: 'warn',
  };
  private timerCallback = () => {
    if (this.twilioService.activeCall) {
      this.elapsedTime = (new Date().getTime() - this.twilioService.activeCall.startTime.getTime()) / 1000 / 60 / 60;
      this.cd.detectChanges();
    }
  };
  private callTimer = setInterval(() => this.timerCallback(), 1000);

  @Output() controlMsg = new EventEmitter<SidenavComponentMessage<void>>();
  @Input({required: true}) public employee!: Employee;

  statusText: StatusText = StatusText.Ready;
  selectedPhoneNumber: string | undefined;
  elapsedTime: number | undefined;

  ngOnInit() {
    if (this.overlayService.isOpened) {
      this.overlayService.close();
      if (this.twilioService.activeCall) {
        this.twilioService.replaceListener(this.twilioService.activeCall.call, 'disconnect', () => {
          this.statusText = StatusText.Ready;
          this.hangUp();
        });
        this.selectedPhoneNumber = this.twilioService.activeCall.phoneNumber;
        this.statusText = StatusText.Active;
      }
      this.updateMenu();
      this.timerCallback();
    } else {
      this.controlMsg.emit(new ProcessingMessage(true));
      this.twilioService.initializeDevice().subscribe({
        complete: () => {
          this.updateMenu();
          this.controlMsg.emit(new ProcessingMessage(false));
        }
      });
    }
    this.setFormModel();
  }

  ngOnDestroy() {
    if (this.twilioService.activeCall) {
      this.overlayService.open(PhoneCallOverlayComponent);
    } else {
      this.twilioService.destroyDevice();
    }
    clearInterval(this.callTimer);
  }

  updateMenu(): void {
    const device = this.twilioService.device;
    this.hangUpButton.disabled = !this.twilioService.activeCall;
    this.callButton.disabled = !device || !this.hangUpButton.disabled || !this.selectedPhoneNumber;
    this.emitMenu();
  }

  private async call(): Promise<void> {
    if (this.selectedPhoneNumber) {
      const onAccept = () => {
        this.statusText = StatusText.Active;
        this.updateMenu();
        this.cd.detectChanges();
      };
      const onReject = () => {
        this.statusText = StatusText.Ready;
        this.updateMenu();
        this.cd.detectChanges();
      }
      await this.twilioService.call(this.selectedPhoneNumber, onAccept, onReject, onReject);
      if (this.twilioService.activeCall) {
        this.statusText = StatusText.Calling;
        this.callButton.disabled = true;
        this.emitMenu();
      }
    }
  }

  private hangUp(): void {
    if (this.twilioService.activeCall) {
      this.twilioService.activeCall.call.disconnect();
      this.elapsedTime = undefined;
      this.updateMenu();
    }
  }

  private setFormModel(): void {
    const numbers = [this.employee.phone, this.employee.phoneCell, this.employee.phoneWork];
    if (numbers.filter((n) => !!n).length === 1) {
      this.selectedPhoneNumber = numbers[0];
    }
  }

  private emitMenu(): void {
    this.controlMsg.emit(new MenuMessage([this.hangUpButton, this.callButton]));
  }
}

enum StatusText {
  Ready = 'Ready to call',
  Calling = 'Calling selected number...',
  Active = 'ACTIVE CALL',
}
