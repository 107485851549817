<div class="flex h-full flex-col">
  <h1>{{editMode ? 'Edit' : 'Create'}} User</h1>
  <mat-card>
    <mat-card-content>
      <mat-spinner *ngIf="editMode && !user; else showForm"></mat-spinner>
      <ng-template #showForm>
        <dynamic-form *ngIf="isSuperAdmin$ | async" mergeFields [fields]="superAdminFields" [form]="form" [model]="user"></dynamic-form>
        <dynamic-form [fields]="fields" mergeFields [form]="form" [model]="user"></dynamic-form>
        <div *ngIf="showSettlements" style="height: 400px">
          <vs-table
                  [data]="settlements$ | async"
                  [columns]="settlementColumns"
                  [defaultSelectionFn]="selectUserSettlements"
                  [rowSelection]="form.enabled"
                  disableSettings
                  (selectionChange)="settlementSelection$.next($event.source.selected)"
          ></vs-table>
        </div>
      </ng-template>
    </mat-card-content>
    <mat-card-actions class="flex justify-between items-center">
      <a mat-stroked-button color="warn" routerLink="../">Cancel</a>
      <div class="flex gap-4">
        <button *ngIf="editMode" mat-raised-button color="primary" (click)="changePassword()">Change Password</button>
        <button mat-raised-button color="primary" [disabled]="!!(disableSave$ | async)" (click)="save()">Save</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
