<mat-tab-group class="h-full"
               mat-stretch-tabs
               [selectedIndex]="selectedIndex"
               (selectedIndexChange)="selectedIndex = $event">
  <mat-tab [disabled]="!files">
    <ng-template mat-tab-label>
      <mat-icon class="mr-4">list</mat-icon>Attachments
    </ng-template>
    <ng-template matTabContent>
      <vs-table
        cacheKey="documents-list"
        disableToolbar
        [rowMenuItems]="rowMenuItems"
        [tableMenuItems]="tableMenuItems"
        [data]="files"
        [columns]="columns"></vs-table>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>file_upload</mat-icon>Upload
    </ng-template>
    <ng-template matTabContent>
      <file-uploader
        endpoint="/api/attachedFile/forEmployee/{{employee.id}}"
        (uploaded)="filesUploaded($event)">
      </file-uploader>
    </ng-template>
  </mat-tab>
</mat-tab-group>
