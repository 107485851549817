import {BaseColumn} from './base-column';
import {VsTableColumnConfig, VsTableColumnDataType} from "../types";

export class CurrencyColumn<T> extends BaseColumn<T> {
  public sum: boolean;
  public format: string | undefined;
  public dataType = VsTableColumnDataType.Currency;
  public negateValue: boolean;
  public compareField: Extract<keyof T, string> | undefined;
  constructor(config: VsTableColumnConfig<T>) {
    super(config);
    this.sum = !!config.sum;
    this.format = config.format;
    this.align = config.align || 'end';
    this.negateValue = !!config.negateValue;
    this.compareField = config.compareField;
  }
}
