<h1 mat-dialog-title>What's New</h1>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <div *ngFor="let change of data | keyvalue">
    <div *ngIf="(change.value | keyvalue)?.length" style="margin-bottom: 16px;">
      <h1>{{change.key}}</h1>
      <div *ngFor="let category of change.value | keyvalue">
        <h2>{{category.key}}</h2>
        <mat-list>
          <mat-list-item *ngFor="let change of category.value">{{change}}</mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
