import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {ServiceWorkerService} from "../service-worker.service";
import {map} from "rxjs";

export const initializeGuard: CanActivateFn = (route, state) => {
  const swService = inject(ServiceWorkerService);
  return swService.checkForUpdate().pipe(
    map(() => true),
  );
};
