import {BaseDateField, BaseDateFieldConfig} from "./base-date-field";
import {FieldType} from "../types";
import {DateRangeValue} from 'date';

export class DateRangeField extends BaseDateField<DateRangeValue> {
  type: FieldType.DateRange = FieldType.DateRange;
  disableStartDate: boolean;
  disableStartDateToggle: boolean;
  constructor(config: DateRangeFieldConfig) {
    super(config);
    this.disableStartDate = !!config.disableStartDate;
    this.disableStartDateToggle = !!config.disableStartDateToggle;
  }
}

export interface DateRangeFieldConfig extends BaseDateFieldConfig<DateRangeValue> {
  disableStartDate?: boolean;
  disableStartDateToggle?: boolean;
}
