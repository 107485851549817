import {NgModule, ModuleWithProviders} from '@angular/core';
import { DetailedSnackbarComponent } from './detailed-snackbar/detailed-snackbar.component';
import { DetailDialogComponent } from './detail-dialog/detail-dialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    DetailedSnackbarComponent,
    DetailDialogComponent
  ],
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [],
    }
  }
}
