<div [formGroup]="form">
  <label id="df-radio-label">{{config.label}}{{config.required ? '*' : ''}}</label>
  <mat-radio-group
    aria-labelledby="df-radio-label"
    [style.flex-direction]="config.layout"
    [color]="config.color"
    [required]="config.required"
    [formControlName]="config.name">
    <mat-radio-button *ngFor="let option of options$ | async"
                      [labelPosition]="config.labelPosition"
                      [value]="option.value">
      {{option.key}}
    </mat-radio-button>
  </mat-radio-group>
  <mat-progress-bar mode="indeterminate" [color]="config.color" *ngIf="!(options$ | async)"></mat-progress-bar>
  <mat-error *ngIf="control.hasError('required')">A selection is required.</mat-error>
</div>
