<h3 mat-dialog-title>
  <span *ngIf="data.rowName">{{data.rowName(data.row)}}'s </span>
  <span>{{data.column.title}}</span>
</h3>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography min-w-96">
  <vs-table-cell [column]="data.column" [row]="data.row" [rowHeight]="data.rowHeight" [isExpanded]="true"></vs-table-cell>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
