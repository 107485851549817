import {Directive, inject, OnDestroy, OnInit, signal, WritableSignal} from "@angular/core";
import {of, Subject, switchMap, takeUntil, tap, withLatestFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {EntityStoreBaseService} from "./core/service/entity-data/entity-store-base.service";

@Directive()
export abstract class EntityList<T> implements OnInit, OnDestroy {
  protected abstract dataService: EntityStoreBaseService<T>;
  protected route = inject(ActivatedRoute);
  protected onDestroy$ = new Subject<void>();
  entities: WritableSignal<T[]> = signal([]);
  ngOnInit() {
    this.route.queryParams
      .pipe(
        withLatestFrom(this.dataService.entities$, this.dataService.loaded$),
        switchMap(([params, entities, loaded]) => {
          if (Object.keys(params).length) {
            return this.dataService.getWithQuery(params)
              .pipe(tap(() => this.dataService.setLoaded(loaded)));
          }
          this.dataService.loadIfNotLoadedOrLoading();
          return of(entities);
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((queried) => this.entities.set(queried));

    this.dataService.entities$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((entities) => this.entities.set(entities));
  }

  ngOnDestroy() {
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }
}
