<div class="flex h-full flex-col gap-3">
  <mat-toolbar class="mat-elevation-z6">
    <div class="flex flex-grow flex-col justify-center items-center">
      <div class="text-2xl">Compose Email</div>
      <div class="text-lg">{{(employee$ | async)?.name}}</div>
    </div>
  </mat-toolbar>
  <div class="flex flex-grow flex-col">
    <dynamic-form [form]="addressForm" [fields]="addressFields" [model]="addressModel$ | async"></dynamic-form>
    <dynamic-form [form]="templateForm" [fields]="templateFields" [model]="templateFormModel$ | async"></dynamic-form>
    <div class="flex flex-col flex-grow p-4 gap-4">
      <div *ngIf="templateForm.invalid" class="flex gap-1 ml-4">
        <div class="text-lg">Select templates to begin</div>
        <mat-icon>arrow_outward</mat-icon>
      </div>
      <lib-email-preview
        (send)="sendEmail($event)"
        [preview]="preview$ | async"
        [defaultEventCode]="(templateFormModel$ | async)?.defaultEventCode"
        [settlement]="settlement$ | async"></lib-email-preview>
    </div>
  </div>
</div>
