import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {MatCardModule} from "@angular/material/card";
import {AsyncPipe, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {FormGroup, Validators} from "@angular/forms";
import {BaseField, DynamicFormModule, InputField, SelectField} from "dynamic-form";
import {Store} from "@ngrx/store";
import {collectionToKeyValue$, isTruthy} from "caig-utils";
import {AppPortal, SessionRole} from "../../core/enum/session.enums";
import {selectUserRoles} from "../../core/store/enums/enums.selectors";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from "rxjs";
import {UserSettlement} from "../../core/interface/session.interface";
import {User, UserEntityService} from "../../core/service/entity-data/user-entity.service";
import {selectIsSuperAdmin, selectSessionSettlements} from "../../core/store/core/core.selectors";
import {TextColumn, VsTableColumn, VsTableModule} from "vs-table";
import {
  ChangePasswordComponent,
  ChangePasswordData,
  changePasswordOnNextLogin,
  copyContentButton,
  visibilityButton
} from "../../core/component/change-password/change-password.component";
import {PasswordService} from "../../core/service/password.service";
import {Clipboard} from "@angular/cdk/clipboard";
import {NotificationService} from "notification";
import {MatDialog} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {EnumsActions} from "../../core/store/enums/action-types";
import {CORE_CONFIG} from "../../core/consts/injection-tokens";

@Component({
  selector: 'lib-edit-user',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    RouterLink,
    MatButtonModule,
    MatCardModule,
    VsTableModule,
      MatProgressSpinnerModule,
      DynamicFormModule,
  ],
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  private config = inject(CORE_CONFIG);
  private dataService = inject(UserEntityService);
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private passwordService = inject(PasswordService);
  private clipboard = inject(Clipboard);
  private notifications = inject(NotificationService);
  private dialog = inject(MatDialog);
  private router = inject(Router);
  private onDestroy$ = new Subject<void>();
  public form = new FormGroup({});
  public superAdminFields: BaseField<any>[][] = [
    [
      new SelectField({
        name: '_portals',
        label: 'Portals',
        options: Object.keys(AppPortal),
        multiple: true,
        required: true,
      })
    ]
  ];
  public fields: BaseField<any>[][] = [
    [
      new InputField({
        name: 'firstName',
        label: 'First Name',
        required: true,
      }),
      new InputField({
        name: 'lastName',
        label: 'Last Name',
        required: true,
      }),
    ],
    [
      new InputField({
        name: 'email',
        label: 'Email',
        validators: [Validators.email],
        required: true,
      }),
    ],
    [
      new SelectField({
        name: 'roleId',
        label: 'Role',
        options: collectionToKeyValue$(this.store.select(selectUserRoles), 'name', 'id'),
        required: true,
        onChange: (value) => this.showSettlements = value !== SessionRole.Superadmin,
      })
    ],
    [
      new InputField({
        name: 'username',
        label: 'Username',
        required: true,
      }),
    ],
  ];
  public settlementSelection$ = new BehaviorSubject<UserSettlement[]>([]);
  public user: User | null = null;
  public disableSave$!: Observable<boolean>;
  public settlements$: Observable<any[]> = this.store.select(selectSessionSettlements).pipe(filter(isTruthy));
  public settlementColumns: VsTableColumn<UserSettlement>[] = [
    new TextColumn({
      title: 'Code',
      field: 'code',
    })
  ];
  public showSettlements = false;
  public selectUserSettlements: ((s: UserSettlement) => boolean) | undefined;
  public isSuperAdmin$ = this.store.select(selectIsSuperAdmin);

  editMode = this.route.snapshot.paramMap.has('id');

  ngOnInit() {
    this.disableSave$ = combineLatest([this.form.valueChanges.pipe(startWith(this.form.value)), this.settlementSelection$])
      .pipe(
        map(([value, settlements]: [any, any[]]) => this.form.status !== 'VALID' || (value.roleId !== SessionRole.Superadmin && !settlements.length)),
        startWith(true)
      );
    this.route.params
      .pipe(
        filter((params) => !!params['id']),
        tap(() => this.user = null),
        switchMap((params) => this.dataService.getByKey(params['id'])),
        takeUntil(this.onDestroy$),
      )
      .subscribe((user) => {
        this.user = user;
        const _portals = user.portals?.split(',');
        setTimeout(() => this.form.patchValue({_portals}, {emitEvent: false}));
        this.showSettlements = user.roleId !== SessionRole.Superadmin;
        this.selectUserSettlements = (s) => !!user.settlements?.find((us) => us.id === s.id);
        this.settlementSelection$.next(user.settlements || []);
      });
    if (!this.route.snapshot.params['id']) {
      const passwordField = new InputField({
        name: 'password',
        label: 'Password',
        required: true,
        inputType: 'password',
      });
      passwordField.buttons = [
        visibilityButton(passwordField),
        this.passwordService.generatePasswordButton(this.form),
        copyContentButton(this.clipboard, this.notifications),
      ];
      this.fields.push([ passwordField ], [ changePasswordOnNextLogin ]);
    }
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'userRoles'}));
  }

  ngOnDestroy() {
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }

  public changePassword(): void {
    if (this.user) {
      const data: ChangePasswordData = { userId: this.user.id, self: false };
      this.dialog.open(ChangePasswordComponent, { data });
    }
  }

  public save(): void {
    const changes: any = {...this.user, ...this.form.value, settlements: this.settlementSelection$.value};
    if (changes._portals) {
      changes.portals = changes._portals.join(',');
      delete changes._portals;
    } else {
      changes.portals = this.config.portal;
    }
    this.form.disable();
    const request$ = changes.id ? this.dataService.update(changes) : this.dataService.add(changes, {isOptimistic: false});
    request$.subscribe(() => {
      this.router.navigate(['../'], {relativeTo: this.route});
    }, () => this.form.enable());
  }
}
