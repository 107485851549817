<mat-form-field [subscriptSizing]="subscriptSizing" [formGroup]="form" [appearance]="appearance" [color]="color">
  <mat-label>{{label}}</mat-label>
  <date-range-input #rangeInput
                   [dateFilter]="dateFilter"
                   [minDate]="minDate"
                   [maxDate]="maxDate"
                   [placeholder]="placeholder"
                   [formControlName]="controlName"
                   [disableStartDate]="disableStartDate"
                   [required]="required"
                   [rangepicker]="picker"></date-range-input>
  <mat-hint [align]="hintAlign || 'start'">{{hint}}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">{{label}} is required.</mat-error>
  <mat-error *ngIf="rangeInput.range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  <mat-error *ngIf="rangeInput.range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  <mat-slide-toggle matSuffix
                    *ngIf="!disableStartDateToggle"
                    [checked]="!disableStartDate"
                    (change)="toggleStartDate(rangeInput)"
                    matTooltip="{{disableStartDate ? 'Ignore' : 'Include'}} start date">
  </mat-slide-toggle>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <button *ngIf="rangeInput.range.value.start || rangeInput.range.value.end"
          matSuffix
          mat-icon-button
          [disabled]="control.disabled"
          (click)="rangeInput.range.reset()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-date-range-picker #picker
                         [startView]="startView || 'month'"
                         [startAt]="startDate"
                         [touchUi]="touchUi"
                         [dateClass]="dateClass"></mat-date-range-picker>
</mat-form-field>
