import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  WritableSignal
} from '@angular/core';
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {
    CurrencyColumn,
    DateColumn,
    IconColumn,
    NumberColumn,
    TextColumn,
    VsTableColumn,
    VsTableMenuItem,
    VsTableModule,
} from "vs-table";
import {AsyncPipe, KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {Router, RouterModule} from "@angular/router";
import {combineLatest, filter, map, switchMap} from "rxjs";
import {ThemePalette} from "@angular/material/core";
import {MatBadgeModule} from "@angular/material/badge";
import {ConfirmDialogComponent, ConfirmDialogData, isTruthy} from "caig-utils";
import {MatDialog} from "@angular/material/dialog";
import {EmployeesFilterComponent} from "../employees-filter/employees-filter.component";
import {SidenavStackService} from "sidenav-stack";
import {AddEmployeeComponent} from "../add-employee/add-employee.component";
import {Employee} from "../../core/interface/employees.interface";
import {EntityList} from "../../entity-list";
import {LoadingService} from "../../core/service/loading.service";
import {Store} from "@ngrx/store";
import {selectSettlementContext} from "../../core/store/core/core.selectors";
import {ThemeService} from "../../core/service/theme.service";
import {AssignUserComponent} from "../../core/component/assign-user/assign-user.component";

@Component({
  selector: 'lib-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    KeyValuePipe,
    VsTableModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonToggleModule,
    NgForOf,
    NgIf,
    RouterModule,
    EmployeesFilterComponent,
  ],
})
export class EmployeesListComponent extends EntityList<Employee> {
  private static readonly VIEW_MODE_STORAGE = 'EMP_VIEW_MODE';

  protected dataService = inject(EmployeeEntityService);
  private dialog = inject(MatDialog);
  private loadingService = inject(LoadingService);
  private router = inject(Router);
  private sidenavService = inject(SidenavStackService);
  private store = inject(Store);
  private themeService = inject(ThemeService);

  private settlementId$ = this.store.select(selectSettlementContext);

  isLoading$ = this.dataService.loading$;
  columns = computed(() => {
    const sharedColumns: VsTableColumn<Employee>[] = [
      new IconColumn({
        field: 'notes',
        title: 'Notes',
        styles: {
          minWidth: '40px',
          maxWidth: '40px',
        },
        filter: false,
        sort: false,
        draggable: false,
        calculate: (row) => row.notes ? 'description' : '',
        tooltip: (row) => row.notes,
        displayTitle: false,
      }),
      new TextColumn({
        field: 'id',
        title: 'ID',
      }),
      new TextColumn({
        field: 'firstName',
        title: 'First',
      }),
      new TextColumn({
        field: 'lastName',
        title: 'Last',
      }),
    ];
    return [
      ...sharedColumns,
      ...this.getViewModeColumns(),
    ];
  });
  toolbarButtons: ToolbarButton[] = [
    {
      label: 'New Registration',
      icon: 'add_circle',
      callback: () => this.sidenavService.open('New Registration', AddEmployeeComponent, { model: {status: 'Registered'} }),
    },
    {
      label: 'New Employee',
      icon: 'add_circle',
      callback: () => this.sidenavService.open('New Employee', AddEmployeeComponent),
    },
    {
      label: 'Upload Employees',
      icon: 'cloud_upload',
      disabled: true,
    },
    {
      label: 'Update from Experian',
      icon: 'upload',
      disabled: true,
    },
    {
      label: 'Update from CSV',
      icon: 'upload',
      disabled: true,
    },
    {
      label: 'Update Amounts',
      icon: 'upload',
      disabled: true,
    },
  ];
  rowMenuItems: VsTableMenuItem<Employee>[] = [
    {
      name: () => 'Assign',
      callback: (row) => this.assignUser([row]),
    },
    {
      name: () => 'Add to payroll',
      callback: (row) => this.addToPayroll([row]),
    },
    {
      name: () => 'View',
      callback: (row) => this.viewEmployee(row),
    },
    {
      name: () => 'Edit',
      callback: (row) => this.router.navigate([row.id, 'edit'], {relativeTo: this.route.parent})
    },
    {
      name: () => 'Delete',
      callback: (row) => {
        const data: ConfirmDialogData = {
          title: 'Confirm Delete',
          text: `Are you sure you want to delete ${row.name}?`,
          confirmText: 'Yes',
        };
        this.dialog.open(ConfirmDialogComponent, {data})
          .afterClosed()
          .pipe(
            filter(isTruthy),
            switchMap(() => this.dataService.delete(row.id))
          )
          .subscribe();
      },
    }
  ];
  tableMenuItems: VsTableMenuItem<Employee[]>[] = [
    {
      name: () => 'Bulk assign',
      callback: (rows) => this.assignUser(rows),
    },
    {
      name: () => 'Bulk add to payroll',
      callback: (rows) => this.addToPayroll(rows),
    },
    {
      name: () => 'Bulk email',
      callback: (rows) => this.bulkEmail(rows),
    }
  ];
  settlementIdPainter$ = combineLatest([this.settlementId$, this.themeService.isDarkTheme$]).pipe(
    map(([settlementId, isDark]) => ((row: Employee) => settlementId && settlementId !== row.settlementId ? isDark ? 'rgba(210,210,210,0.9)' : 'rgba(50,50,50,0.7)' : '')),
  );
  rowTooltip$ = this.settlementId$.pipe(
    map((settlementId) => ((row: Employee) => settlementId && settlementId !== row.settlementId ? `This employee belongs to a different case - ${row.settlementCode} (ID: ${row.settlementId})` : '')),
  );
  viewModes = EmployeeViewMode;
  viewMode: WritableSignal<EmployeeViewMode>;

  constructor() {
    super();

    const viewMode = localStorage.getItem(EmployeesListComponent.VIEW_MODE_STORAGE);
    const isViewMode = (stored: string): stored is EmployeeViewMode =>
      Object.values<string>(EmployeeViewMode).includes(stored);
    const initialViewMode = viewMode && isViewMode(viewMode) ? viewMode : EmployeeViewMode.Basic;
    this.viewMode = signal(initialViewMode);

    effect(() => {
      const viewMode = this.viewMode();
      localStorage.setItem(EmployeesListComponent.VIEW_MODE_STORAGE, viewMode);
    });
  }

  openFilters() {
    this.dialog.open(EmployeesFilterComponent)
      .afterClosed()
      .subscribe((value) => this.filterEmployees(value));
  }

  filterEmployees(queryParams: any) {
    if (queryParams) {
      this.router.navigate([], {replaceUrl: true, queryParams: omitEmptyArrays(queryParams)});
    }
  }

  compareViewModes() {
    return 0;
  }

  private getViewModeColumns(): VsTableColumn<Employee>[] {
    switch (this.viewMode()) {
      case EmployeeViewMode.BUE:
        return [
          new CurrencyColumn({
            title: 'Gross',
            field: 'stateAddlamt',
          }),
          new CurrencyColumn({
            title: 'Est. Check',
            field: 'estTotal',
          }),
          new TextColumn({
            title: 'BUE Region',
            field: 'bueRegion',
          }),
          new TextColumn({
            title: 'BUE Local',
            field: 'bueLocal',
          }),
          new TextColumn({
            title: 'BUE Location',
            field: 'bueLocation',
          }),
          new IconColumn({
            title: 'BUE Current',
            field: 'bueCurrent',
            calculate: (row) => row.bueCurrent ? 'check' : 'close',
            color: (row) => row.bueCurrent ? 'green' : 'red',
          }),
          new IconColumn({
            title: 'BUE Member',
            field: 'bueUnionMember',
            calculate: (row) => row.bueUnionMember ? 'check' : 'close',
            color: (row) => row.bueUnionMember ? 'green' : 'red',
          }),
          new TextColumn({
            title: 'Email',
            field: 'email',
          }),
          new TextColumn({
            title: 'Email Alt',
            field: 'emailAlt',
          }),
          new IconColumn({
            title: 'Emails?',
            field: 'emailIsInvalid',
            calculate: (row) => row.emailIsInvalid ? 'close' : 'check',
            color: (row) => row.emailIsInvalid ? 'red' : 'green',
          }),
          new TextColumn({
            title: 'Phone',
            field: 'phone',
          }),
          new TextColumn({
            title: 'Phone Work',
            field: 'phoneWork',
          }),
          new TextColumn({
            title: 'Phone Cell',
            field: 'phoneCell',
          }),
          new IconColumn({
            title: 'Phones?',
            field: 'phoneIsInvalid',
            calculate: (row) => row.phoneIsInvalid ? 'close' : 'check',
            color: (row) => row.phoneIsInvalid ? 'red' : 'green',
          }),
          new TextColumn({
            title: 'Status',
            field: 'status',
          }),
        ];
      case EmployeeViewMode.Basic:
        return [
          new CurrencyColumn({
            title: 'Gross',
            field: 'stateAddlamt',
          }),
          new CurrencyColumn({
            title: 'Est. Check',
            field: 'estTotal',
          }),
          new TextColumn({
            title: 'City',
            field: 'city',
          }),
          new TextColumn({
            title: 'State',
            field: 'state',
          }),
          new TextColumn({
            title: 'Zip',
            field: 'zip',
          }),
          new IconColumn({
            title: 'Address?',
            field: 'addressIsInvalid',
            calculate: (row) => row.addressIsInvalid ? 'close' : 'check',
            color: (row) => row.addressIsInvalid ? 'red' : 'green',
          }),
          new IconColumn({
            title: 'Emails?',
            field: 'emailIsInvalid',
            calculate: (row) => row.emailIsInvalid ? 'close' : 'check',
            color: (row) => row.emailIsInvalid ? 'red' : 'green',
          }),
          new IconColumn({
            title: 'Phones?',
            field: 'phoneIsInvalid',
            calculate: (row) => row.phoneIsInvalid ? 'close' : 'check',
            color: (row) => row.phoneIsInvalid ? 'red' : 'green',
          }),
          new TextColumn({
            title: 'Status',
            field: 'status',
          }),
          new TextColumn({
            title: 'User',
            field: 'username',
          }),
          new DateColumn({
            title: 'Assigned',
            field: 'assignedDate',
          }),
          new DateColumn({
            title: 'Last Contacted',
            field: 'contactedDate',
          }),
          new IconColumn({
            title: 'Not Contacted',
            field: 'contacted',
            calculate: (row) => row.contacted ? 'check_box_outline_blank' : 'check_box',
            color: (row) => row.contacted ? '' : 'green',
          }),
        ];
      case EmployeeViewMode.Financial:
        return [
          new TextColumn({
            title: 'Middle',
            field: 'middleName',
          }),
          new CurrencyColumn({
            title: 'Donation',
            field: 'donation',
          }),
          new CurrencyColumn({
            title: 'SPOT BP',
            field: 'spotBp',
          }),
          new CurrencyColumn({
            title: 'CTOT BP',
            field: 'ctotBp',
          }),
          new CurrencyColumn({
            title: 'Att.',
            field: 'attimpCost',
          }),
          new CurrencyColumn({
            title: 'ERSS',
            field: 'estEmployerSs',
          }),
          new CurrencyColumn({
            title: 'ERMC',
            field: 'estEmployerMc',
          }),
          new CurrencyColumn({
            title: 'Cost',
            field: 'estCostShare',
          }),
          new CurrencyColumn({
            title: 'IRS',
            field: 'estFedWh',
          }),
          new CurrencyColumn({
            title: 'IRS+',
            field: 'fedAddlamt',
          }),
          new CurrencyColumn({
            title: 'State',
            field: 'estStateWh',
          }),
          new CurrencyColumn({
            title: 'State+',
            field: 'stateAddlamt',
          }),
          new CurrencyColumn({
            title: 'EESS',
            field: 'estEmployeeSs',
          }),
          new CurrencyColumn({
            title: 'EEMC',
            field: 'estEmployeeMc',
          }),
          new CurrencyColumn({
            title: 'SPOT LD',
            field: 'spotLd',
          }),
          new CurrencyColumn({
            title: 'CTOT LD',
            field: 'ctotLd',
          }),
          new CurrencyColumn({
            title: 'Total',
            field: 'estTotal',
          }),
          new TextColumn({
            title: 'State',
            field: 'state',
          }),
          new IconColumn({
            title: 'FX',
            field: 'fedExempt',
            calculate: (row) => row.fedExempt ? 'check' : 'close',
            color: (row) => row.fedExempt ? 'green' : 'red',
          }),
          new IconColumn({
            title: 'SSX',
            field: 'fedSsExempt',
            calculate: (row) => row.fedSsExempt ? 'check' : 'close',
            color: (row) => row.fedSsExempt ? 'green' : 'red',
          }),
          new IconColumn({
            title: 'MCX',
            field: 'fedMcExempt',
            calculate: (row) => row.fedMcExempt ? 'check' : 'close',
            color: (row) => row.fedMcExempt ? 'green' : 'red',
          }),
          new NumberColumn({
            title: 'Est. S%',
            field: 'estEffStateRate',
          }),
          new NumberColumn({
            title: 'S%',
            field: 'stateRate',
          }),
          new IconColumn({
            title: 'SX',
            field: 'stateExempt',
            calculate: (row) => row.stateExempt ? 'check' : 'close',
            color: (row) => row.stateExempt ? 'green' : 'red',
          }),
          new TextColumn({
            title: 'Status',
            field: 'status',
          }),
        ];
      default:
        return [];
    }
  }

  viewEmployee(employee: Employee): void {
    this.router.navigate([employee.id], {relativeTo: this.route.parent, queryParamsHandling: 'preserve'});
  }

  private bulkEmail(employees: Employee[]): void {
    this.loadingService.load(this.dataService.createBatch(employees.map((e) => e.id)))
      .subscribe(({batchId}) => this.router.navigate(['batch-email', batchId], {relativeTo: this.route}));
  }

  private assignUser(employees: Employee[]): void {
    this.dialog.open(AssignUserComponent, {data: employees});
  }

  private addToPayroll(employees: Employee[]): void {
    this.loadingService.load(this.dataService.createBatch(employees.map((e) => e.id)))
      .subscribe(({batchId}) => this.router.navigate(['/payrolls', 'add', batchId]));
  }
}

interface ToolbarButton {
  label: string;
  routerLink?: string;
  icon?: string;
  color?: ThemePalette;
  disabled?: boolean;
  callback?: () => void;
}

enum EmployeeViewMode {
  Basic = 'basic',
  BUE = 'bue',
  Financial = 'financial',
}

function omitEmptyArrays<T extends object>(obj: T): Partial<T> {
  const result: Partial<T> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (!(Array.isArray(value) && value.length === 0)) {
      result[key as keyof T] = value;
    }
  }
  return result;
}
