import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {VsTableExpandedDetailConfig} from "../types";

@Component({
  selector: 'lib-expanded-dialog',
  templateUrl: './expanded-dialog.component.html',
  styleUrls: ['./expanded-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedDialogComponent<T> {
  data: VsTableExpandedDetailConfig<T> = inject(MAT_DIALOG_DATA);
}
