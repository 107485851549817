<div class="flex h-full flex-col">
  <div class="flex gap-5 text-lg">
    <b>Currently assigned to:</b>
    <div *ngIf="employee.userId; else notAssigned" class="flex gap-5">
      <div>{{employee.username}}</div>
      <div>(as of {{employee.assignedDate | date:'shortDate'}})</div>
    </div>
    <ng-template #notAssigned>
      <div>N/A</div>
    </ng-template>
  </div>
  <div class="flex flex-grow">
    <vs-table
      cacheKey="employee-contact"
      [data]="employee.contacts"
      [columns]="columns"
      disableToolbar>
    </vs-table>
  </div>
</div>
