import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'record',
  standalone: true,
})
export class RecordPipe implements PipeTransform {
  transform(value: any): Record<string, any> {
    return value as Record<string, any>;
  }
}
