import {
  Component,
  ElementRef,
  Inject, Input,
  OnDestroy, OnInit,
  Optional, Self,
  ViewChild
} from '@angular/core';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from "@angular/material/form-field";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl, Validators,
} from "@angular/forms";
import {Subject} from "rxjs";
import {FocusMonitor} from "@angular/cdk/a11y";
import {BooleanInput, coerceBooleanProperty} from "@angular/cdk/coercion";
import {NumberRange} from "../../../interfaces";

@Component({
  selector: 'lib-number-range-input',
  templateUrl: './number-range-input.component.html',
  styleUrls: ['./number-range-input.component.css'],
  providers: [{provide: MatFormFieldControl, useExisting: NumberRangeInputComponent}],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class NumberRangeInputComponent implements ControlValueAccessor, MatFormFieldControl<NumberRange>, OnInit, OnDestroy {
  static nextId = 0;
  @ViewChild('start') startInput!: ElementRef;
  @ViewChild('end') endInput!: ElementRef;

  parts: FormGroup<{
    start: FormControl<number | null>;
    end: FormControl<number | null>;
  }>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'number-range-input';
  id = `number-range-input-${NumberRangeInputComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: {start, end},
    } = this.parts;

    return !start && !end;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): NumberRange | null {
    if (this.parts.valid) {
      const {
        value: {start, end},
      } = this.parts;
      return new NumberRange(start!, end!);
    }
    return null;
  }
  set value(range: NumberRange | null) {
    const {start, end} = range || new NumberRange(null, null);
    this.parts.setValue({start, end});
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    _formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = _formBuilder.group({
      start: [null as number | null],
      end: [null as number | null],
    });
  }

  ngOnInit() {
    if (this.required) {
      this.parts.controls.start.addValidators(Validators.required);
      this.parts.controls.end.addValidators(Validators.required);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (!control.value) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.number-range-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      const width = this._formField._elementRef.nativeElement.clientWidth;
      const offsetX = event.offsetX;
      if (offsetX <= width / 2) {
        this.startInput.nativeElement.focus();
      } else {
        this.endInput.nativeElement.focus();
      }
    }
  }

  writeValue(range: NumberRange | null): void {
    this.value = range;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
