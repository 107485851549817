import {Component, Input} from '@angular/core';
import {CustomFormControl} from "caig-utils";
import {MatButtonModule} from "@angular/material/button";
import {DateFilterFn, MatCalendarCellClassFunction, MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {convertToMoment, convertToString} from "../../util-functions";
import moment from "moment";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {DatepickerInputComponent} from "../datepicker-input/datepicker-input.component";
import {CalendarStartView} from "../../types";

@Component({
  selector: 'datepicker',
  standalone: true,
  imports: [
    DatepickerInputComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    NgIf,
    ReactiveFormsModule,
  ],
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent extends CustomFormControl {
  private _minDate: moment.Moment | null = null;
  private _maxDate: moment.Moment | null = null;
  private _touchUi: boolean = false;

  @Input() dateFilter!: DateFilterFn<moment.Moment | null>;
  @Input() startView: CalendarStartView = 'month';
  @Input() startDate: moment.Moment | null = null;
  @Input() dateClass!: MatCalendarCellClassFunction<moment.Moment>;
  @Input() set touchUi(value: any) { this._touchUi = coerceBooleanProperty(value) }
  get touchUi() { return this._touchUi }
  @Input() set minDate(value: any) { this._minDate = convertToMoment(value) }
  get minDate() { return convertToString(this._minDate) }
  @Input() set maxDate(value: any) { this._maxDate = convertToMoment(value) }
  get maxDate() { return convertToString(this._maxDate) }
}
