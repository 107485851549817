export enum AppPortal {
  CallCenter = 'CallCenter',
  CAIG = 'CAIG',
  Survey = 'Survey',
}

export enum SessionRole {
  User = 1,
  Administrator,
  Superadmin,
  INACTIVE,
  Client
}
