import {InjectionToken} from "@angular/core";
import {NavSection} from "../interface/navigation.interface";
import {UserMenuItemRouted} from "../interface/user-menu.interface";
import {EntityStoreBaseService} from "../service/entity-data/entity-store-base.service";
import {AppPortal} from "../enum/session.enums";

export const CORE_CONFIG = new InjectionToken<CoreConfig>('CORE_CONFIG');

export interface CoreConfig {
  resetEntitiesServices: {new (...args: any[]): EntityStoreBaseService<any>}[];
  portal: AppPortal;
  navigationMenu?: NavSection[];
  userMenu?: UserMenuItemRouted[];
}
