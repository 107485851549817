import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {AutocompleteField} from "../../class/autocomplete-field";
import {BehaviorSubject, combineLatest, map, Observable, shareReplay, tap} from "rxjs";
import {MatInput} from "@angular/material/input";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'lib-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.css'],
})
export class AutocompleteFieldComponent<T> extends BaseFieldComponent<AutocompleteField<T>> implements OnInit {
  options$!: Observable<T[]>;
  filteredOptions$!: Observable<T[]>;
  valueChange$ = new BehaviorSubject<string>('');

  @ViewChild(MatInput, {static: true}) inputRef!: MatInput;

  ngOnInit() {
    this.options$ = this.config.options.pipe(
      tap((options) => this.setDefaultValue(options)),
      shareReplay(),
    );
    this.filteredOptions$ = combineLatest([this.options$, this.valueChange$]).pipe(
      map(([options, value]) => {
        if (!value) {
          return options;
        }
        const val = value.toLowerCase();
        return options.filter((o) => (o[this.config.displayField] as string).toLowerCase().startsWith(val));
      }),
    );
  }
  displayFn(option: T): string {
    const displayField = this.config.displayField;
    return option && option[displayField] ? option[displayField] as string : '';
  }
  inputChange(input: MatInput) {
    if (!input.value) {
      this.control.patchValue(null);
    }
  }
  onAddItem(addItem: () => void, trigger: MatAutocompleteTrigger) {
    addItem();
    trigger.closePanel();
    this.control.patchValue(null);
  }
  private setDefaultValue(options: T[]): void {
    if (!this.config.defaultValue) {
      return;
    }
    const option = options.find((o) => o[this.config.itemKey] == this.config.defaultValue);
    if (option) {
      const displayVal = this.displayFn(option);
      this.valueChange$.next(displayVal);
      setTimeout(() => this.inputRef.value = displayVal);
    }
  }
}
