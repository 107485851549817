import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import {AppDataChanges} from "../../interface/app-data.interface";
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'lib-whats-new',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    KeyValuePipe,
    MatDialogModule,
    MatListModule,
    MatButtonModule,
  ],
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsNewComponent {
  data: AppDataChanges = inject(MAT_DIALOG_DATA);
}
