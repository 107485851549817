import {ChangeDetectionStrategy, Component, inject, signal} from '@angular/core';
import {ToolbarButton, ViewEmployeeBase} from "./view-employee-base";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {EntityIteratorComponent} from "../../entity-iterator/entity-iterator.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {MatDividerModule} from "@angular/material/divider";
import {MatGridListModule} from "@angular/material/grid-list";
import {DynamicFormModule} from "dynamic-form";
import {MatCardModule} from "@angular/material/card";
import {EmployeeContactInfoComponent} from "../employee-contact-info/employee-contact-info.component";
import {EmployeeStatusComponent} from "../employee-status/employee-status.component";
import {EmployeeTagsComponent} from "../employee-tags/employee-tags.component";
import {EmployeeContactComponent} from "../employee-contact/employee-contact.component";
import {EmployeeSurveysComponent} from "../employee-surveys/employee-surveys.component";
import {EmployeeTaxComponent} from "../employee-tax/employee-tax.component";
import {EmployeeEventsComponent} from "../employee-events/employee-events.component";
import {EmployeeDocumentsComponent} from "../employee-documents/employee-documents.component";
import {EmployeeAmountsComponent} from "../employee-amounts/employee-amounts.component";
import {EmployeePaymentsComponent} from "../employee-payments/employee-payments.component";
import {first} from "rxjs/operators";
import {ConfirmDialogComponent, ConfirmDialogData, isTruthy} from "caig-utils";
import {MatDialog} from "@angular/material/dialog";
import {map, Observable, switchMap} from "rxjs";
import {EmployeeNotesComponent} from "../employee-notes/employee-notes.component";
import {SidenavStackService} from "sidenav-stack";
import {ViewPdfComponent} from "../../core/component/view-pdf/view-pdf.component";
import {LoadingService} from "../../core/service/loading.service";

@Component({
  selector: 'lib-view-employee',
  standalone: true,
  imports: [
    AsyncPipe,
    EntityIteratorComponent,
    MatButtonModule,
    MatIconModule,
    NgForOf,
    NgIf,
    RouterLink,
    MatDividerModule,
    MatGridListModule,
    DynamicFormModule,
    MatCardModule,
    EmployeeContactInfoComponent,
    EmployeeStatusComponent,
    EmployeeTagsComponent,
    EmployeeContactComponent,
    EmployeeSurveysComponent,
    EmployeeTaxComponent,
    EmployeeEventsComponent,
    EmployeeDocumentsComponent,
    EmployeeAmountsComponent,
    EmployeePaymentsComponent,
    EmployeeNotesComponent,
  ],
  templateUrl: './view-employee.component.html',
  styleUrls: ['./view-employee.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewEmployeeComponent extends ViewEmployeeBase {
  private sidenavService = inject(SidenavStackService);
  private loadingService = inject(LoadingService);
  private dialog = inject(MatDialog);
  toolbarButtons: Observable<ToolbarButton[]> = this.employee$.pipe(
    map((employee) => {
      const buttons: ToolbarButton[] = [
        {
          label: 'Employees',
          icon: 'chevron_left',
          routerLink: '/employees',
        },
        {
          label: 'Edit',
          icon: 'edit',
          routerLink: 'edit',
        },
        {
          label: 'Manual Paycheck',
          icon: 'payments',
          disabled: true,
        },
        {
          label: 'Pay',
          disabled: true,
        },
        {
          label: 'D/L Dues Forms',
          disabled: true,
        },
        {
          label: 'D/L Tax Form',
          disabled: true,
        },
        {
          label: 'Delete',
          icon: 'delete',
          callback: () => this.deleteEmployee(),
          color: 'warn',
        },
      ];
      if (employee?.signedReleaseId) {
        const signedReleaseId = employee.signedReleaseId;
        buttons.splice(-1, 0, {
          label: 'Signed Release',
          icon: 'picture_as_pdf',
          callback: () => this.getSignedRelease(signedReleaseId),
          color: 'primary',
        })
      }
      return buttons;
    })
  );

  private deleteEmployee() {
    this.employee$
      .pipe(first(isTruthy))
      .subscribe((employee) => {
        const data: ConfirmDialogData = {
          title: 'Confirm Delete',
          text: `Are you sure you want to delete ${employee.name}?`,
          confirmText: 'Yes',
        };
        this.dialog.open(ConfirmDialogComponent, {data})
          .afterClosed()
          .pipe(
            first(isTruthy),
            switchMap(() => this.dataService.delete(employee.id)),
          )
          .subscribe({
            next: () => {
              this.router.navigate(['/employees'], {replaceUrl: true, queryParamsHandling: 'preserve'});
            }
          });
      });
  }

  getSignedRelease(signedReleaseId: string) {
    const docUrl$ = this.dataService.getSignedReleaseUrl(signedReleaseId);
    this.loadingService.load(docUrl$).subscribe((docUrl) =>
      this.sidenavService.open<void>('Signed Release PDF', ViewPdfComponent, {docUrl}));
  }
}
