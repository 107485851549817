<mat-form-field [subscriptSizing]="subscriptSizing" [formGroup]="form" [appearance]="appearance" [color]="color">
  <mat-label>{{label}}</mat-label>
  <mat-icon matPrefix>attach_money</mat-icon>
  <currency-input [placeholder]="placeholder"
                  [required]="required"
                  [disableNegative]="disableNegative"
                  [formControlName]="controlName"></currency-input>
  <button *ngIf="control.value"
          matSuffix
          mat-icon-button
          [disabled]="control.disabled"
          (click)="control.patchValue(null)">
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="control.hasError('required')">{{label}} is required.</mat-error>
  <mat-hint [align]="hintAlign || 'start'">{{hint}}</mat-hint>
</mat-form-field>
