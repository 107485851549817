import {AuthToken} from '../interfaces';

export interface AuthState {
  token: AuthToken | null;
  tokenRefreshing: boolean;
  loginPassword: string | null;
  loading: boolean;
  error: string | null;
}

export const initialAuthState: AuthState = {
  token: null,
  tokenRefreshing: false,
  loginPassword: null,
  loading: false,
  error: null,
};
