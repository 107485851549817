import {createReducer, on} from '@ngrx/store';
import {initialCoreState} from './core.state';
import {CoreActions} from './action-types';

export const coreReducer = createReducer(
  initialCoreState,
  on(CoreActions.initializeSession, (state) => ({
    ...state,
    loading: true,
  })),
  on(CoreActions.sessionInitialized, (state, { session }) => ({
    ...state,
    session,
    loading: false,
    error: null,
    settlementId: state.settlementId && session.settlements.find((s) => s.id === state.settlementId) ? state.settlementId : (session.settlements[0]?.id || null),
  })),
  on(CoreActions.changeSettlementContext, (state, { settlementId }) => ({
    ...state,
    settlementId,
  })),
  on(CoreActions.sessionInitializeError, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(CoreActions.updateSettlements, (state, { settlement }) => {
    if (!state.session) {
      return state;
    }
    const settlementIndex = state.session.settlements.findIndex((s) => s.id === settlement.id);
    const updatedSettlements = settlementIndex === -1
      ? [settlement, ...state.session.settlements]
      : state.session.settlements.map((s, index) =>
        index === settlementIndex ? settlement : s
      );
    return {
      ...state,
      session: {
        ...state.session,
        settlements: updatedSettlements
      }
    };
  })
);
