import {Injectable} from "@angular/core";
import {EntityBaseService} from "./entity-base.service";

@Injectable({providedIn: 'root'})
export class SignatureBlockService extends EntityBaseService<SignatureBlock> {
  protected baseUrl = 'api/email/signatureblock';
  protected entityName = 'signature block';
  protected entityNamePlural = this.entityName + 's';
}

export interface SignatureBlock {
  id: string;
  title: string;
  signature: string;
  isDefault: boolean;
  isSettlementScoped: boolean;
}
