import {SidenavComponentMenuItem} from './interfaces';

export class TitleMessage {
  constructor(public title: string) { }
}

export class MenuMessage {
  constructor(public menu: SidenavComponentMenuItem[]) { }
}

export class ProcessingMessage {
  constructor(public isProcessing: boolean) { }
}

export class CloseMessage<T = any> {
  constructor(public saveResult: T | null) { }
}
