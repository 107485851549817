import {Injectable} from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  get() {
    const annualSegment = (date: moment.Moment) => () => ({
      week: () => ({
        start: () => this.momentToISO(date.startOf('week')),
        end: () => this.momentToISO(date.endOf('week')),
      }),
      month: () => ({
        start: () => this.momentToISO(date.startOf('month')),
        end: () => this.momentToISO(date.endOf('month')),
      }),
      quarter: () => ({
        start: () => this.momentToISO(date.startOf('quarter')),
        end: () => this.momentToISO(date.endOf('quarter')),
      }),
      year: () => ({
        start: () => this.momentToISO(date.startOf('year')),
        end: () => this.momentToISO(date.endOf('year')),
      })
    });
    return {
      today: () => this.momentToISO(moment()),
      tomorrow: () => this.momentToISO(moment().add(1, 'day')),
      yesterday: () => this.momentToISO(moment().subtract(1, 'day')),
      current: annualSegment(moment()),
      next: annualSegment(moment().add(1, 'year')),
      last: annualSegment(moment().subtract(1, 'year')),
    };
  }
  ISOtoMoment(date: string): moment.Moment {
    return moment(date);
  }
  momentToISO(date: moment.Moment): string {
    return date.format('YYYY-MM-DD');
  }
}
