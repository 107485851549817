<mat-card cdkDrag
          [@enterExit]="animationState"
          (@enterExit.done)="onAnimationDone()"
          (cdkDragEnded)="dragEnd($event)">
  <div class="example-handle" cdkDragHandle>
    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  </div>
  <div class="flex flex-grow justify-between items-center gap-8">
    <div>{{ elapsedTime | time }}</div>
    <button *ngIf="twilioService.activeCall?.call as call" mat-flat-button #mb [color]="call.isMuted() ? 'accent' : 'warn'" (click)="call.mute(!call.isMuted())">
      {{mb.color === 'warn' ? 'MUTE' : 'UN-MUTE'}}
    </button>
    <div class="flex flex-col items-center justify-center">
      <div>{{twilioService.identity}} speaking to {{twilioService.activeCall?.phoneNumber}}</div>
      <div *ngIf="twilioService.activeCall && twilioService.activeCall.call.isMuted()" class="accent-red-800 text-lg font-bold">Your microphone is currently muted</div>
    </div>
    <button mat-raised-button color="warn" (click)="startExitAnimation()">Hang Up</button>
  </div>
</mat-card>
