import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthState} from './auth.state';

export const authFeatureKey = 'auth';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectAuthToken = createSelector(
  selectAuthState,
  (state) => state.token
);

export const selectAuthLoading = createSelector(
  selectAuthState,
  (state) => state.loading
);

export const selectAuthError = createSelector(
  selectAuthState,
  (state) => state.error
);

export const selectIsAuthenticated = createSelector(
  selectAuthToken,
  (token) => !!token
);

const selectAuthTokenRefreshing = createSelector(
  selectAuthState,
  (state) => state.tokenRefreshing,
);

export const selectAuthTokenWithRefreshStatus = createSelector(
  selectAuthToken,
  selectAuthTokenRefreshing,
  (token, tokenRefreshing) => ({ token, tokenRefreshing }),
);
