import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {VersionReadyEvent} from "@angular/service-worker";
import {interval, Subject, takeUntil} from "rxjs";
import {AppData} from "../../interface/app-data.interface";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatBadgeModule} from "@angular/material/badge";
import {TimePipe} from "caig-utils";
import moment from 'moment';

@Component({
  selector: 'lib-update-timer',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatBadgeModule,
    TimePipe,
  ],
  templateUrl: './update-timer.component.html',
  styleUrls: ['./update-timer.component.css']
})
export class UpdateTimerComponent implements OnInit, OnDestroy {
  @Input() public updateEvent!: VersionReadyEvent;
  @Input() public disabled!: boolean;
  @Output() public installUpdate = new EventEmitter<void>();

  public duration!: number;

  private readonly onDestroy$ = new Subject<void>();

  public ngOnInit(): void {
    const appData = this.updateEvent.latestVersion.appData as AppData;
    const minutes = appData.forceReloadDuration || 0;
    if (minutes) {
      const startTime = moment();
      const duration = minutes / 60;
      this.duration = duration;
      interval(950)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          const now = moment();
          const diff = now.diff(startTime, 'hours', true);
          this.duration = duration - diff;
          if (this.duration < 0.0003) {
            this.duration = 0;
            this.onDestroy$.next(void 0);
            this.installUpdate.emit(void 0);
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }
}
