import {Component, inject, ChangeDetectionStrategy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'lib-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailDialogComponent {
  private clipboard = inject(Clipboard);
  public dialogRef = inject(MatDialogRef<DetailDialogComponent>);
  public data: { message: string, details: any } = inject(MAT_DIALOG_DATA);

  public copyDetailsToClipboard(): void {
    this.clipboard.copy(JSON.stringify(this.data.details, null, 2));
  }
}
