import {inject, Injectable} from "@angular/core";
import {EntityBaseService} from "../../core/service/entity-data/entity-base.service";
import {Observable, tap} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {AppEvent, EmployeeEvent} from "../../core/interface/events.interface";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {Employee} from "../../core/interface/employees.interface";
import {MergeStrategy} from "@ngrx/data";

@Injectable({providedIn: 'root'})
export class EventsDataService extends EntityBaseService<AppEvent> {
  baseUrl = '/api/event';
  entityName = 'event';
  entityNamePlural = this.entityName+'s';

  private employeeService = inject(EmployeeEntityService);

  addForEmployee(employee: Employee, payload: Partial<EmployeeEvent>, params?: HttpParams): Observable<EmployeeEvent> {
    return this.notify(
      this.http.post<EmployeeEvent>(`/api/event/forEmployee/${employee.id}`, payload, {params}),
      'created',
      false,
    ).pipe(
      tap(() => this.employeeService.getByKey(employee.id, {mergeStrategy: MergeStrategy.OverwriteChanges}))
      // tap((event) => this.employeeService.updateOneInCache({
      //   ...employee,
      //   events: [event, ...employee.events],
      //   contacts: [(), ...employee.contacts], // @TODO - how to determine if event belongs in contacts?
      // })),
    );
  }
}
