import {ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Employee, EmployeePayment, PaymentStatus} from "../../core/interface/employees.interface";
import {MatGridListModule} from "@angular/material/grid-list";
import {
  AsyncPipe,
  CurrencyPipe,
  DatePipe,
  KeyValue,
  LowerCasePipe,
  NgClass,
  NgForOf,
  NgIf,
  UpperCasePipe
} from "@angular/common";
import {Store} from "@ngrx/store";
import {SidenavStackService} from "sidenav-stack";
import {selectIsSuperAdmin} from "../../core/store/core/core.selectors";
import {round} from "lodash";
import {ViewPdfComponent} from "../../core/component/view-pdf/view-pdf.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'lib-employee-payments',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgClass,
    AsyncPipe,
    CurrencyPipe,
    LowerCasePipe,
    DatePipe,
    UpperCasePipe,
    MatTooltipModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './employee-payments.component.html',
  styleUrls: ['./employee-payments.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeePaymentsComponent implements OnChanges {
  private store = inject(Store);
  private sidenavService = inject(SidenavStackService);

  @Input({required: true}) employee!: Employee;

  props: KeyValue<keyof EmployeePayment, string>[] = [
    {
      key: 'spotBp',
      value: 'SPOT BP',
    },
    {
      key: 'ctotBp',
      value: 'CTOT BP',
    },
    {
      key: 'spotLd',
      value: 'SPOT LD',
    },
    {
      key: 'ctotLd',
      value: 'CTOT LD',
    },
    {
      key: 'costShare',
      value: 'Cost Share',
    },
    {
      key: 'fedWh',
      value: 'Federal Wh'
    },
    {
      key: 'fedAddlamt',
      value: 'Federal+',
    },
    {
      key: 'employerSs',
      value: 'Employer Ss',
    },
    {
      key: 'employeeSs',
      value: 'Employee Ss',
    },
    {
      key: 'employerMc',
      value: 'Employer Mc',
    },
    {
      key: 'employeeMc',
      value: 'Employee Mc',
    },
    {
      key: 'stateWh',
      value: 'State Wh',
    },
    {
      key: 'stateAddlamt',
      value: 'State+',
    },
    {
      key: 'addlamt',
      value: 'Other+'
    },
    {
      key: 'donation',
      value: 'Donation',
    },
    {
      key: 'total',
      value: 'Check Amt',
    },
  ];
  addlProps: KeyValue<keyof EmployeePaymentView, string>[] = [
    {
      key: 'paidTotal',
      value: '',
    },
    {
      key: 'diff',
      value: '',
    },
  ];
  payments!: EmployeePaymentView[];
  summary!: EmployeePaymentView;
  isSuperAdmin$ = this.store.select(selectIsSuperAdmin);

  ngOnChanges(changes: SimpleChanges) {
    if (this.employee.payments) {
      this.payments = this.employee.payments.map((p, i) => {
        const paidTotal = p.total + (this.employee.payments[i - 1] ? this.employee.payments[i - 1].total : 0);
        return { ...p, paidTotal, diff: this.employee.estTotal - paidTotal};
      });
      this.summary = this.props.reduce((prev, curr) => {
        prev[curr.key] = this.payments.reduce((p, c) => round(p + Number(c[curr.key] || 0), 2), 0);
        return prev;
      }, {} as any);
    }
  }
  viewPDF(payment: EmployeePayment): void {
    this.sidenavService.open<void>(`Payment for ${this.employee.name}`, ViewPdfComponent, { docUrl: payment.docUrl });
  }
  edit(payment: EmployeePayment): void {
    // @TODO
  }
  remove(payment: EmployeePayment): void {
    // @TODO
  }
}

interface EmployeePaymentView extends EmployeePayment {
  paidTotal: number;
  diff: number;
}
