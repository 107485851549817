<div class="flex flex-col gap-5">
  <div *ngIf="dialogRef.disableClose" mat-dialog-title>
    Please create a new password
  </div>
  <h2>Password requirements:</h2>
  <lib-password-requirements [form]="form" (isValid)="validPassword$.next($event)"></lib-password-requirements>
  <dynamic-form *ngIf="fields() as fs" [fields]="fs" [form]="form"></dynamic-form>
  <button mat-raised-button color="primary" [disabled]="!!(disableSave$ | async)" (click)="save()">Save</button>
  <button *ngIf="dialogRef.disableClose" mat-button (click)="dialogRef.close()">
    Remind me later
  </button>
</div>
