import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {PhoneTextComponent, PhoneTextData} from "../phone-text/phone-text.component";
import {MatDialog} from "@angular/material/dialog";
import {SidenavStackService} from "sidenav-stack";
import {Employee} from "../../core/interface/employees.interface";
import {PhoneCallSidenavComponent} from "../phone-call-sidenav/phone-call-sidenav.component";

@Component({
  selector: 'lib-phone-menu',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './phone-menu.component.html',
  styleUrls: ['./phone-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneMenuComponent {
  private dialog = inject(MatDialog);
  private sidenavService = inject(SidenavStackService);
  @Input({required: true}) employee!: Employee;
  sms(tts: boolean) {
    const data: PhoneTextData = { entity: this.employee, tts };
    this.dialog.open(PhoneTextComponent, {data, width: '400px'});
  }
  call() {
    this.sidenavService.open('Phone Call', PhoneCallSidenavComponent, { employee: this.employee });
  }
}
