import {Component, inject, OnInit} from '@angular/core';
import {PhoneNumberInfo, PhoneService} from "../../core/service/phone.service";
import {AutocompleteField, BaseField, DynamicFormModule, SelectField, TextareaField} from "dynamic-form";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "notification";
import {collectionToKeyValue, isTruthy} from "caig-utils";
import {map, Observable, startWith, tap} from "rxjs";
import {FormGroup, Validators} from "@angular/forms";
import {AsyncPipe, NgIf} from "@angular/common";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatButtonModule} from "@angular/material/button";
import {MAT_SELECT_SCROLL_STRATEGY} from "@angular/material/select";
import {Overlay} from "@angular/cdk/overlay";
import {MAT_AUTOCOMPLETE_SCROLL_STRATEGY} from "@angular/material/autocomplete";

@Component({
  selector: 'app-phone-text',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDialogModule,
    DynamicFormModule,
  ],
  templateUrl: './phone-text.component.html',
  styleUrls: ['./phone-text.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.noop(),
      deps: [Overlay]
    },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.noop(),
      deps: [Overlay]
    },
  ]
})
export class PhoneTextComponent implements OnInit {
  data: PhoneTextData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);
  private phoneService = inject(PhoneService);
  private notifications = inject(NotificationService);

  form = new FormGroup({});
  fields$!: Observable<BaseField<any>[][]>;
  invalidForm$ = this.form.statusChanges
    .pipe(
      map((status) => status !== 'VALID'),
      startWith(true),
    );

  ngOnInit() {
    const messageTemplates: MessageTemplate[] = [
      {
        id: '1',
        name: 'Greeting',
        value: 'Hello!',
      },
      {
        id: '2',
        name: 'Alt. Greeting',
        value: 'Hey!',
      },
    ];
    const phoneNumbers = [this.data.entity.phone, this.data.entity.phoneWork, this.data.entity.phoneCell].filter(isTruthy);
    const availablePhoneNumbers$ = this.phoneService.lookup(phoneNumbers)
      .pipe(
        map((numbers) => {
          const filterFunc = this.data.tts ? filterTtsNumber : filterSmsNumbers;
          return numbers.filter(filterFunc);
        }),
        tap((numbers) => {
          if (!numbers.length) {
            this.notifications.showSimpleMessage(`None of the phone numbers associated with this employee are capable of receiving ${this.data.tts ? 'TTS calls' : 'SMS'}`);
            this.dialogRef.close();
          }
        })
      );
    const msgBox: TextareaField = new TextareaField({
      name: 'message',
      label: 'Message',
      required: true,
      onChange: (value) => setMessageHint(value, msgBox),
    });
    setMessageHint('', msgBox);
    if (this.data.tts) {
      msgBox.validators = [Validators.minLength(40)];
    }
    this.fields$ = availablePhoneNumbers$
      .pipe(
        map((numbers) => [
          [
            new SelectField({
              name: 'phoneNumbers',
              label: 'Phone Numbers',
              options: collectionToKeyValue<any, any, any>('formatted', 'number')(
                numbers.map((n) => ({
                  ...n,
                  formatted: `${n.nationalFormat} - (${n.type ? n.type.toUpperCase() : 'UNKNOWN'})`
                }))
              ),
              multiple: true,
              defaultValue: numbers.length ? [numbers[0].number] : [],
            }),
          ],
          [
            new AutocompleteField<MessageTemplate>({
              name: 'messageTemplate',
              label: 'Message Template',
              itemKey: 'id',
              displayField: 'name',
              options: messageTemplates,
              onChange: (templateId, form) => {
                if (form.enabled) {
                  if (templateId) {
                    const template = messageTemplates.find((t) => t.id === templateId);
                    if (template) {
                      form.patchValue({message: template.value});
                    }
                  } else {
                    form.patchValue({message: ''});
                  }
                }
              }
            }),
          ],
          [ msgBox ],
        ])
      );
  }

  send(): void {
    const value: any = this.form.value;
    const numbers: string[] = value.phoneNumbers;
    const message: string = value.message;
    this.form.disable();
    let request$: Observable<any>;
    if (this.data.tts) {
      request$ = numbers.length > 1 ?
        this.phoneService.bulkTtsCall(numbers, message) : this.phoneService.ttsCall(numbers[0], message);
    } else {
      request$ = numbers.length > 1 ?
        this.phoneService.bulkSms(numbers, message) : this.phoneService.sms(numbers[0], message);
    }
    request$.subscribe(() => {
      this.dialogRef.close();
      this.notifications.showSimpleMessage(`${this.data.tts ? 'TTS Call placed' : 'SMS sent'} to ${this.data.entity.name}`);
    }, () => this.form.enable());
  }
}

export interface PhoneTextData {
  entity: {
    phone?: string;
    phoneWork?: string;
    phoneWorkExt?: string;
    phoneCell?: string;
    name: string;
    id: number;
  };
  tts: boolean;
}

interface MessageTemplate {
  id: string;
  name: string;
  value: string;
}

function filterSmsNumbers(number: PhoneNumberInfo): boolean {
  return number.sms;
}

function filterTtsNumber(number: PhoneNumberInfo): boolean {
  return number.type !== null;
}

function setMessageHint(message: string, messageBox: TextareaField): void {
  messageBox.hint = {
    message: `${message ? message.length : 0} characters`,
    align: 'start',
  };
}
