import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldType} from "../types";
import {DateFilterFn, MatCalendarCellClassFunction} from "@angular/material/datepicker";
import moment from "moment";
import {CalendarStartView, convertToMoment} from "date";

export abstract class BaseDateField<T> extends BaseField<T> {
  abstract override type: FieldType.Date | FieldType.DateRange;
  minDate: string | undefined;
  maxDate: string | undefined;
  startDate: moment.Moment | null;
  dateClass: MatCalendarCellClassFunction<moment.Moment>;
  dateFilter: DateFilterFn<moment.Moment | null>;
  touchUi: boolean;
  startView: CalendarStartView;
  constructor(config: BaseDateFieldConfig<T>) {
    super(config);
    this.minDate = config.minDate;
    this.maxDate = config.maxDate;
    this.startDate = convertToMoment(config.startDate);
    this.dateClass = config.dateClass as MatCalendarCellClassFunction<moment.Moment>;
    this.dateFilter = config.dateFilter as DateFilterFn<moment.Moment | null>;
    this.touchUi = !!config.touchUi;
    this.startView = config.startView || 'month';
    this.flex = config.flex === undefined ? 0 : config.flex;
  }
}

export interface BaseDateFieldConfig<T> extends BaseFieldConfig<T> {
  minDate?: string;
  maxDate?: string;
  startDate?: string;
  dateClass?: MatCalendarCellClassFunction<moment.Moment>;
  dateFilter?: DateFilterFn<moment.Moment | null>;
  touchUi?: boolean;
  startView?: CalendarStartView;
}
