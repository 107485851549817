import {FormControl, FormGroup} from "@angular/forms";
import {BaseField} from "../class/base-field";

export abstract class BaseFieldComponent<T extends BaseField<any>> {
  public config!: T;
  public form!: FormGroup;

  public get control(): FormControl {
    return this.form.get(this.config.name) as FormControl;
  }
}
