import {ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AttachedFile, Employee} from "../../core/interface/employees.interface";
import {MatTabsModule} from "@angular/material/tabs";
import {MatIconModule} from "@angular/material/icon";
import {TextColumn, VsTableColumn, VsTableMenuItem, VsTableModule} from "vs-table";
import {FileUploadModule} from "file-upload";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {ConfirmDialogComponent, isTruthy, openFile, SimpleChangesTyped} from "caig-utils";
import {filter, switchMap} from "rxjs";
import {differenceBy} from "lodash";

@Component({
  selector: 'lib-employee-documents',
  standalone: true,
  imports: [
    MatTabsModule,
    MatIconModule,
    VsTableModule,
    FileUploadModule,
  ],
  templateUrl: './employee-documents.component.html',
  styleUrls: ['./employee-documents.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeDocumentsComponent implements OnChanges {
  private http = inject(HttpClient);
  private dialog = inject(MatDialog);
  private employeeService = inject(EmployeeEntityService);

  @Input({required: true}) employee!: Employee;

  files: AttachedFile[] | null = null;
  selectedIndex: number = 0;
  columns: VsTableColumn<AttachedFile>[] = [
    new TextColumn({
      title: 'File Name',
      field: 'name',
    }),
    new TextColumn({
      title: 'Content Type',
      field: 'contentType',
    })
  ];
  public rowMenuItems: VsTableMenuItem<AttachedFile>[] = [
    {
      name: () => 'Download',
      callback: (row) => this.download(row),
    },
    {
      name: () => 'Delete',
      callback: (row) => this.remove([row]),
    }
  ];
  public tableMenuItems: VsTableMenuItem<AttachedFile[]>[] = [
    {
      name: () => 'Download Selection',
      callback: (rows) => this.bulkDownload(rows),
    },
    {
      name: () => 'Delete Selection',
      callback: (rows) => this.remove(rows),
    }
  ];

  ngOnChanges(changes: SimpleChangesTyped<this>) {
    if (changes.employee?.currentValue) {
      this.files = this.employee.attachedFiles?.length ? this.employee.attachedFiles : null;
      this.selectedIndex = this.files ? 0 : 1;
    }
  }

  filesUploaded(files: File[]): void {
    this.employeeService.getByKey(this.employee.id);
  }

  private download(file: AttachedFile): void {
    this.http.get(`/api/attachedFile/${file.id}`, { responseType: 'blob' })
      .subscribe((blob) => openFile(blob, file.name));
  }
  private remove(files: AttachedFile[]): void {
    const ids = files.map((f) => f.id);
    const request$ = files.length > 1 ?
      this.http.post(`/api/attachedFile/bulkDelete`, ids) :
      this.http.delete(`/api/attachedFile/${files[0].id}`);
    const title = 'Confirm Delete';
    const text = `Are you sure you want to delete ${files.length > 1 ? `all ${files.length} files` : files[0].name}?`;
    this.dialog.open(ConfirmDialogComponent, {data: {text, title}})
      .afterClosed()
      .pipe(
        filter(isTruthy),
        switchMap(() => request$)
      )
      .subscribe(() => {
        if (this.files) {
          this.files = differenceBy(this.files, files, 'id');
          this.employeeService.updateOneInCache({...this.employee, attachedFiles: this.files});
        }
      });
  }
  private bulkDownload(files: AttachedFile[]): void {
    this.http.post('/api/attachedFile/bulk', files.map((f) => f.id), { responseType: 'blob' })
      .subscribe((blob) => openFile(blob, this.employee.name));
  }
}
