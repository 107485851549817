import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EmailState} from "./email.reducers";

export const emailFeatureKey = 'email';
export const selectEmailState = createFeatureSelector<EmailState>(emailFeatureKey);

export const selectEmailTemplates = createSelector(
  selectEmailState,
  state => state.emailTemplates,
);

export const selectEmailSignatures = createSelector(
  selectEmailState,
  state => state.emailSignatures,
);

export const selectFields = createSelector(
  selectEmailState,
  state => state.fields,
);
