import {Component, Input} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {NgIf} from "@angular/common";
import {CurrencyInputComponent} from "../currency-input/currency-input.component";
import {ReactiveFormsModule} from "@angular/forms";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {CustomFormControl} from "caig-utils";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'currency',
  standalone: true,
  imports: [
    CurrencyInputComponent,
    MatFormFieldModule,
    MatIconModule,
    NgIf,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css'],
})
export class CurrencyComponent extends CustomFormControl {
  private _disableNegative: boolean = false;

  @Input()
  get disableNegative() { return this._disableNegative }
  set disableNegative(value: any) { this._disableNegative = coerceBooleanProperty(value) }
}
