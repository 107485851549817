import {inject, Injectable} from "@angular/core";
import {Actions, createEffect} from "@ngrx/effects";
import {NotificationService} from "notification";
import {EntityOp, ofEntityOp} from "@ngrx/data";
import {tap} from "rxjs";

@Injectable()
export class EntityNotificationEffects {
  private actions$ = inject(Actions);
  private notificationService = inject(NotificationService);

  private actionMessages: Record<AllowedOps, string> = {
    [EntityOp.SAVE_UPDATE_MANY_SUCCESS]: 'updated entities',
    [EntityOp.SAVE_ADD_MANY_SUCCESS]: 'added entities',
    [EntityOp.SAVE_DELETE_MANY_SUCCESS]: 'deleted entities',
    [EntityOp.SAVE_UPDATE_ONE_SUCCESS]: 'updated entity',
    [EntityOp.SAVE_DELETE_ONE_SUCCESS]: 'deleted entity',
    [EntityOp.SAVE_ADD_ONE_SUCCESS]: 'added entity',
    [EntityOp.SAVE_UPSERT_ONE_SUCCESS]: 'updated entity',
    [EntityOp.SAVE_UPSERT_MANY_SUCCESS]: 'updated entities',
  };

  private createSuccessEffect(actionType: AllowedOps) {
    return createEffect(() =>
        this.actions$.pipe(
          ofEntityOp(actionType),
          tap(() => this.notificationService.showSimpleMessage(`Successfully ${this.actionMessages[actionType]}!`))
        ),
      { dispatch: false }
    );
  }

  updateSuccess$ = this.createSuccessEffect(EntityOp.SAVE_UPDATE_ONE_SUCCESS);
  addSuccess$ = this.createSuccessEffect(EntityOp.SAVE_ADD_ONE_SUCCESS);
  deleteSuccess$ = this.createSuccessEffect(EntityOp.SAVE_DELETE_ONE_SUCCESS);
  updateManySuccess$ = this.createSuccessEffect(EntityOp.SAVE_UPDATE_MANY_SUCCESS);
  addManySuccess$ = this.createSuccessEffect(EntityOp.SAVE_ADD_MANY_SUCCESS);
  deleteManySuccess$ = this.createSuccessEffect(EntityOp.SAVE_DELETE_MANY_SUCCESS);
}

type AllowedOps =
  EntityOp.SAVE_UPDATE_MANY_SUCCESS |
  EntityOp.SAVE_ADD_MANY_SUCCESS |
  EntityOp.SAVE_DELETE_MANY_SUCCESS |
  EntityOp.SAVE_UPDATE_ONE_SUCCESS |
  EntityOp.SAVE_DELETE_ONE_SUCCESS |
  EntityOp.SAVE_ADD_ONE_SUCCESS |
  EntityOp.SAVE_UPSERT_ONE_SUCCESS |
  EntityOp.SAVE_UPSERT_MANY_SUCCESS
;
