import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CoreState} from './core.state';
import {SessionRole} from "../../enum/session.enums";

export const coreFeatureKey = 'core';
export const selectCoreState = createFeatureSelector<CoreState>(coreFeatureKey);
export const selectSession = createSelector(
  selectCoreState,
  (state) => state.session
);

export const selectUser = createSelector(
  selectSession,
  (session) => session?.user,
);

export const selectIsSuperAdmin = createSelector(
  selectUser,
  (user) => user ? user.roleId === SessionRole.Superadmin : user,
);

export const selectIsAdmin = createSelector(
  selectUser,
  selectIsSuperAdmin,
  (user, isSuperAdmin) => user ? (user.roleId === SessionRole.Administrator || isSuperAdmin) : user,
);

export const selectSessionError = createSelector(
  selectCoreState,
  (state) => state.error,
);

export const selectSessionLoading = createSelector(
  selectCoreState,
  (state) => state.loading,
);

export const selectSettlementContext = createSelector(
  selectCoreState,
  (state) => state.settlementId,
);

export const selectSessionSettlements = createSelector(
  selectCoreState,
  (state) => state.session?.settlements,
);

export const selectCurrentSettlement = createSelector(
  selectSettlementContext,
  selectSessionSettlements,
  (settlementId, settlements) => settlements?.find((s) => s.id === settlementId),
);
