import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import {DateFilterFn, MatDatepicker, MatDatepickerModule} from "@angular/material/datepicker";
import {MatInput, MatInputModule} from "@angular/material/input";
import moment from "moment";
import {convertToMoment, convertToString} from "../../util-functions";
import {MatFormFieldControl} from "@angular/material/form-field";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {getDateProviders} from "../../custom-moment-date-adapter";
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Subject} from "rxjs";

@Component({
  selector: 'datepicker-input',
  standalone: true,
  imports: [
    MatMomentDateModule,
    MatDatepickerModule,
    MatInputModule
  ],
  templateUrl: './datepicker-input.component.html',
  styleUrls: ['./datepicker-input.component.css'],
  providers: getDateProviders(DatepickerInputComponent),
})
export class DatepickerInputComponent implements MatFormFieldControl<string | null>, ControlValueAccessor, OnDestroy {
  static nextId = 0;

  private _value: moment.Moment | null = null;
  private _placeholder: string = '';
  private _required: boolean = false;
  private _disabled: boolean = false;
  private _focused: boolean = false;
  private _touched: boolean = false;
  private _minDate: moment.Moment | null = null;
  private _maxDate: moment.Moment | null = null;

  stateChanges = new Subject<void>();
  autofilled: boolean = false;
  controlType: string = 'datepicker-input';
  userAriaDescribedBy: string = '';

  @HostBinding() id = `${this.controlType}-${DatepickerInputComponent.nextId++}`;
  @HostBinding('class.floating') get shouldLabelFloat() { return this.focused || !this.empty }

  @ViewChild(MatInput, {read: ElementRef, static: true}) inputEl!: ElementRef;

  @Input({required: true}) datepicker!: MatDatepicker<moment.Moment>;
  @Input() dateFilter!: DateFilterFn<moment.Moment | null>;

  @Input()
  set value(value: any) { this._value = convertToMoment(value) }
  get value() { return convertToString(this._value) }
  @Input()
  get placeholder(): string { return this._placeholder }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  @Input()
  get required(): boolean { return this._required }
  set required(req: any) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  @Input()
  get disabled(): boolean { return this._disabled }
  set disabled(dis: any) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  @Input() set minDate(value: any) { this._minDate = convertToMoment(value) }
  get minDate() { return convertToString(this._minDate) }
  @Input() set maxDate(value: any) { this._maxDate = convertToMoment(value) }
  get maxDate() { return convertToString(this._maxDate) }
  get focused(): boolean { return this._focused }
  set focused(foc: boolean) {
    this._focused = foc;
    this.stateChanges.next();
  }
  get touched(): boolean { return this._touched }
  set touched(touch: boolean) {
    this._touched = touch;
    this.stateChanges.next();
  }
  get empty(): boolean { return !this.value }
  get errorState(): boolean {
    return !!this.ngControl && this.ngControl.errors !== null && this.touched;
  }

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.inputEl.nativeElement.focus();
    }
  }
  setDescribedByIds(ids: string[]): void {
    const controlElement = this.inputEl.nativeElement;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onTouched = () => {};
  onChange = (v: any) => {};

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
  onDateChange(date: any): void {
    this.writeValue(date);
    this.onChange(this.value);
  }
}
