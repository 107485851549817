<div class="login">
  <mat-card class="mat-elevation-z6">
    <mat-toolbar color="primary" class="mat-elevation-z6">Class Action Implementation Group</mat-toolbar>
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
      <mat-form-field class="full-width">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username">
        <mat-error>Username is required.</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-error>Password is required.</mat-error>
      </mat-form-field>
      <button mat-raised-button color="accent" type="submit" class="full-width" [disabled]="loginForm.disabled || loginForm.invalid">LOGIN</button>
      <mat-progress-bar *ngIf="isLoggingIn$ | async; else notLoggingIn" mode="indeterminate" color="primary"></mat-progress-bar>
      <ng-template #notLoggingIn>
        <lib-login-error *ngIf="loginError$ | async as msg" [message]="msg"></lib-login-error>
      </ng-template>
    </form>
  </mat-card>
</div>
