import {ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseField, DynamicFormModule, SelectField, TextareaField} from "dynamic-form";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormGroup} from "@angular/forms";
import {NgIf} from "@angular/common";
import {Store} from "@ngrx/store";
import {collectionToKeyValue$, SimpleChangesTyped} from "caig-utils";
import {selectEventTypesEmail, selectEventTypesPortal} from "../../core/store/enums/enums.selectors";
import {EnumsActions} from "../../core/store/enums/action-types";

@Component({
  selector: 'lib-customize-event',
  standalone: true,
  imports: [
    NgIf,
    DynamicFormModule,
    MatSlideToggleModule,
  ],
  templateUrl: './customize-event.component.html',
  styleUrls: ['./customize-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state('void', style({
        transform: 'translateX(100%)',
        opacity: 0,
      })),
      state('*', style({
        transform: 'translateX(0)',
        opacity: 1,
      })),
      transition(':enter', [
        animate('300ms ease-out')
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({
          transform: 'translateX(100%)',
          opacity: 0,
        }))
      ])
    ])
  ],
  exportAs: 'customizeEvent',
})
export class CustomizeEventComponent implements OnInit, OnChanges {
  @Input({required: true}) eventType!: 'eventTypesPortal' | 'eventTypesEmail';
  @Input({required: true}) defaultEventCode!: number;
  private store = inject(Store);
  form: FormGroup = new FormGroup({});
  fields!: BaseField<any>[][];
  isActive = false;

  ngOnInit() {
    const selector = this.eventType === 'eventTypesPortal' ? selectEventTypesPortal : selectEventTypesEmail;
    this.fields = [
      [
        new SelectField({
          name: 'eventCode',
          label: 'Type',
          options: collectionToKeyValue$(this.store.select(selector), 'description', 'code'),
          required: true,
          defaultValue: this.defaultEventCode,
          flex: 65,
        }),
        new TextareaField({
          name: 'eventMessage',
          label: 'Message',
          required: true,
          id: 'eventMessage',
          flex: 35,
        })
      ]
    ];
  }

  ngOnChanges(changes: SimpleChangesTyped<this>) {
    if (changes.defaultEventCode) {
      this.form.patchValue({eventCode: this.defaultEventCode});
    }
  }

  focusForm() {
    const inputEl = document.getElementById(this.fields[0][1].id!) as HTMLInputElement;
    inputEl?.focus();
  }

  toggleChange(checked: boolean) {
    this.isActive = checked;
    if (checked) {
      this.store.dispatch(EnumsActions.loadEnum({enumType: this.eventType}));
    }
  }
}
