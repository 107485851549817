import { Component } from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {PhoneNumberField} from "../../class/phone-number-field";

@Component({
  selector: 'lib-phone-number-field',
  templateUrl: './phone-number-field.component.html',
  styleUrls: ['./phone-number-field.component.css']
})
export class PhoneNumberFieldComponent extends BaseFieldComponent<PhoneNumberField> {

}
