import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class EmailService {
  private static readonly baseUrl = 'api/email';
  private http = inject(HttpClient);

  getTemplates(): Observable<EmailTemplateShort[]> {
    return this.http.get<EmailTemplateShort[]>(`${EmailService.baseUrl}/template`);
  }

  getOneTemplate(templateId: string): Observable<EmailTemplate> {
    return this.http.get<EmailTemplate>(`${EmailService.baseUrl}/template/${templateId}`);
  }

  getEmployeeTemplate(templateId: string, employeeId: string | number): Observable<EmployeeEmailTemplate> {
    return this.http.get<EmployeeEmailTemplate>(`${EmailService.baseUrl}/template/${templateId}/forEmployee/${employeeId}`);
  }

  saveTemplate(template: Partial<EmailTemplate>): Observable<EmailTemplate> {
    if (template.id) {
      return this.http.put<EmailTemplate>(`${EmailService.baseUrl}/template/${template.id}`, template);
    }
    return this.http.post<EmailTemplate>(`${EmailService.baseUrl}/template`, template);
  }

  deleteTemplate(templateId: string): Observable<void> {
    return this.http.delete<void>(`${EmailService.baseUrl}/template/${templateId}`);
  }

  getTemplatePlaceholders(): Observable<string[]> {
    return this.http.get<string[]>(`${EmailService.baseUrl}/template/field`);
  }

  sendEmail(email: ComposedEmail): Observable<ComposedEmail> {
    return this.http.post<ComposedEmail>(`${EmailService.baseUrl}`, email);
  }

  bulkSendEmail(email: BulkComposedEmail): Observable<void> {
    return this.http.post<void>(`${EmailService.baseUrl}/bulk`, email);
  }

  getEmail(emailId: string): Observable<ComposedEmail> {
    return this.http.get<ComposedEmail>(`${EmailService.baseUrl}/${emailId}`);
  }

  renderEmail(params: RenderEmailParams): Observable<RenderedEmail> {
    const { employeeId, signatureId } = params;
    const url = `${EmailService.baseUrl}/template/forEmployee/${employeeId}`;
    const payload = 'templateId' in params ?
      { templateId: params.templateId, signatureId } :
      { subject: params.subject, body: params.body, signatureId };
    return this.http.post<RenderedEmail>(url, payload);
  }
}

export interface EmailTemplateShort {
  id: string;
  title: string;
  defaultSignatureId?: string;
  defaultSignatureTitle?: string;
  defaultEventCode?: number;
}

export interface EmailTemplate extends EmailTemplateShort {
  subject: string;
  body: string;
}

export interface EmployeeEmailTemplate extends EmailTemplate {
  subjectRendered: string;
  bodyRendered: string;
}

export interface ComposedEmail extends Email {
  toName: string;
  toAddress: string;
  employeeId: number;
}

export interface BulkComposedEmail extends Email {
  batchId: string;
  includeAltEmail: boolean;
}

export interface Email {
  fromAddress?: string;
  ccAddress?: string;
  subject?: string;
  body?: string;
  templateId: string;
  signatureId?: string;
  eventCode?: number;
  eventMessage?: string;
}

export interface RenderedEmail {
  subject: string;
  subjectRendered: string;
  body: string;
  bodyRendered: string;
  from: string;
  to: string;
  signatureId: string;
  templateId: string;
}

type RenderEmailBaseParams = {
  employeeId: number;
  signatureId?: string;
};

type RenderEmailWithTemplate = RenderEmailBaseParams & {
  templateId: string;
  subject?: never;
  body?: never;
};

type RenderEmailWithSubjectAndBody = RenderEmailBaseParams & {
  templateId?: never;
  subject: string;
  body: string;
};

export type RenderEmailParams = RenderEmailWithTemplate | RenderEmailWithSubjectAndBody;
