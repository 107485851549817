import {FieldType} from "../types";
import {BaseDateField, BaseDateFieldConfig} from "./base-date-field";

export class DateField extends BaseDateField<string> {
  type: FieldType.Date = FieldType.Date;
  placeholder: string;

  constructor(config: DateFieldConfig) {
    super(config);
    this.placeholder = config.placeholder || '';
  }
}

interface DateFieldConfig extends BaseDateFieldConfig<string> {
  placeholder?: string;
}
