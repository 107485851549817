import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {Session, SessionCase} from "../../interface/session.interface";

export const initializeSession = createAction('[Core] Initialize Session');
export const sessionInitialized = createAction(
  '[Core] Session Initialized',
  props<{session: Session}>()
);
export const sessionInitializeError = createAction(
  '[Core] Session Initialize Error',
  props<{error: HttpErrorResponse}>()
);
export const changeSettlementContext = createAction(
  '[Case Selector] Change Case Context',
  props<{ settlementId: number, redirect?: string }>()
);
export const resetEntities = createAction(
  '[Case Selector] Reset Entities',
  props<{ redirect?: string }>()
);

export const updateSettlements = createAction(
  '[Case Editor] Create or Update Case',
  props<{ settlement: SessionCase }>(),
);
