import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatDialogModule, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from "@angular/common";

@Component({
  selector: 'lib-confirm-dialog',
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {
  public data: ConfirmDialogData = inject(MAT_DIALOG_DATA);
}

export interface ConfirmDialogData {
  text: string;
  title?: string;
  subtitle?: string;
  confirmText?: string;
  cancelText?: string;
  hideCancel?: boolean;
  alignActions?: 'start' | 'center' | 'end';
}
