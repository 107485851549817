<mat-form-field [subscriptSizing]="config.subscriptSizing" [formGroup]="form" [appearance]="config.appearance" [color]="config.color">
  <mat-label>{{config.label}}</mat-label>
  <textarea
    [id]="config.id || ''"
    [formControlName]="config.name"
    [placeholder]="config.placeholder"
    autocomplete="off"
    matInput
  ></textarea>
  <mat-hint *ngIf="config.hint as hint" [align]="hint.align || 'start'">{{hint.message}}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">{{config.label}} is required.</mat-error>
  <mat-error *ngIf="control.getError('maxlength') as err">{{config.label}} must be at most <strong>{{err.requiredLength}}</strong> characters</mat-error>
  <mat-error *ngIf="control.getError('minlength') as err">{{config.label}} must be at least <strong>{{err.requiredLength}}</strong> characters</mat-error>
</mat-form-field>
