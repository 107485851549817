import {BaseColumn} from './base-column';
import {VsTableColumnConfigCalculate, VsTableColumnDataType} from "../types";

export class CalculateColumn<T> extends BaseColumn<T> {
  public calculate: (row: T) => string;
  public dataType = VsTableColumnDataType.Calculate;
  constructor(config: VsTableColumnConfigCalculate<T>) {
    super(config);
    this.calculate = config.calculate;
  }
}
