import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldType} from "../types";
import {ThemePalette} from "@angular/material/core";

export class InputField extends BaseField<string> {
  type = FieldType.Input;
  placeholder: string;
  inputType: string;
  isReadonly: boolean;
  buttons: InputFieldButton[] | undefined;
  // @TODO - fill out remaining input properties

  constructor(config: InputFieldConfig) {
    super(config);
    this.placeholder = config.placeholder || '';
    this.inputType = config.inputType || 'text';
    this.isReadonly = !!config.isReadonly;
    this.buttons = config.buttons;
    // this.clearButton = config.clearButton ?? true;
    // @TODO - add trimmedRequiredValidator
  }
}

export interface InputFieldConfig extends BaseFieldConfig<string> {
  placeholder?: string;
  inputType?: string;
  isReadonly?: boolean;
  buttons?: InputFieldButton[];
}

export interface InputFieldButton {
  icon: string;
  callback: (value: string) => void;
  disable?: (value: string) => boolean;
  color?: ThemePalette;
  tooltip?: string;
}
