import {Component} from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {DateField} from "../../class/date-field";

@Component({
  selector: 'lib-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.css'],
})
export class DateFieldComponent extends BaseFieldComponent<DateField> {

}
