import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {UsersListComponent} from "./users-list/users-list.component";
import {EditUserComponent} from "./edit-user/edit-user.component";

const usersRoutes: Routes = [
  { path: '', component: UsersListComponent },
  { path: 'create', component: EditUserComponent },
  { path: ':id', component: EditUserComponent },
];

@NgModule({
  imports: [RouterModule.forChild(usersRoutes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
