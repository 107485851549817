import {Component, OnInit} from '@angular/core';
import {BaseFieldOptionsComponent} from "../base-field-options.component";
import {SelectField} from "../../class/select-field";
import {MatSelect} from "@angular/material/select";
import {Observable, map, filter, combineLatest, startWith, debounceTime} from "rxjs";
import {KeyValue} from "@angular/common";
import {isTruthy} from "caig-utils";

@Component({
  selector: 'lib-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.css'],
})
export class SelectFieldComponent extends BaseFieldOptionsComponent<SelectField> implements OnInit {
  firstSelectedOption$!: Observable<KeyValue<string | number | null, any>>;

  override ngOnInit() {
    super.ngOnInit();
      this.firstSelectedOption$ = combineLatest([
          this.control.valueChanges.pipe(startWith(null)),
          this.options$
      ])
          .pipe(
              debounceTime(100),
              map(([v, options]) => {
                  const value = this.control.value;
                  if (!value) {
                      return null;
                  }
                  const val = value[0];
                  return options.find((o) => o.value === val);
              }),
              filter(isTruthy),
          );

  }

  toggleSelectAll(checked: boolean, select: MatSelect): void {
    select.value = checked ? select.options.map((o) => o.value) : [];
  }
}
