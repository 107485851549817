<mat-form-field [subscriptSizing]="config.subscriptSizing" [formGroup]="form" [appearance]="config.appearance" [color]="config.color">
  <mat-label>{{config.label}}</mat-label>
  <input type="text"
         autocomplete="off"
         matInput
         #input="matInput"
         #trigger="matAutocompleteTrigger"
         [placeholder]="config.placeholder"
         [required]="config.required"
         [formControlName]="config.name"
         (change)="inputChange(input)"
         (keyup)="valueChange$.next(input.value)"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete"
                    autoActiveFirstOption
                    requireSelection
                    (optionSelected)="input.value = $event.option.viewValue"
                    [displayWith]="displayFn.bind(this)">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option[config.itemKey]">
      {{option[config.displayField]}}
    </mat-option>
    <mat-option *ngIf="config.onAddItem as addNew" class="add-new" (click)="onAddItem(addNew, trigger)">ADD NEW</mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="config.hint as hint" [align]="hint.align || 'start'">{{hint.message}}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">{{config.label}} is required.</mat-error>
  <button *ngIf="control.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          [disabled]="control.disabled"
          (click)="control.setValue(null); valueChange$.next(''); trigger.closePanel();">
    <mat-icon>close</mat-icon>
  </button>
  <mat-progress-bar mode="indeterminate" [color]="config.color" *ngIf="!(options$ | async)"></mat-progress-bar>
</mat-form-field>
