<div class="flex-1 flex-column gap-8">
  <div *ngIf="!isLoading" class="flex-1 flex-align-center-center" dropZone (dropped)="chooseFile($event)">
    <div>Drag & Drop</div>
  </div>
  <div *ngIf="!isLoading" class="flex-align-end-center">
    <button mat-raised-button color="accent" (click)="fileInput.click()">Choose File(s)</button>
  </div>
  <input #fileInput type="file" (change)="chooseFile(fileInput.files)" multiple />
  <h3 *ngIf="queuedFiles.length">{{isLoading ? 'Uploading' : 'Queued'}} File{{queuedFiles.length === 1 ? '' : 's'}} ({{queuedFiles.length}})</h3>
  <mat-list>
    <mat-list-item *ngFor="let file of queuedFiles; let i = index">
      <mat-icon *ngIf="!isLoading"
                matListItemIcon
                style="cursor: pointer"
                (click)="queuedFiles.splice(i, 1)">
        clear
      </mat-icon>
      <div matListItemTitle>{{file.name}}</div>
      <div matListItemLine> {{file.size | fileSize}} </div>
    </mat-list-item>
  </mat-list>
  <div *ngIf="queuedFiles.length" class="flex-row flex-align-spacebetween-center">
    <div class="flex-1">
      <button *ngIf="!isLoading" mat-button color="warn" (click)="queuedFiles = []">Clear All</button>
    </div>
    <div class="flex-1 flex-column flex-align-center-center">
      <button mat-raised-button
              color="primary"
              (click)="upload()"
              [disabled]="isLoading">
        Upload File{{queuedFiles.length === 1 ? '' : 's'}}
      </button>
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
    </div>
    <div class="flex-1"></div>
  </div>
</div>
