<input matInput
       autocomplete="off"
       (focus)="focused = true"
       (blur)="focused = false; touched = true; onTouched()"
       [matDatepicker]="datepicker"
       [matDatepickerFilter]="dateFilter"
       [placeholder]="placeholder"
       [min]="minDate"
       [max]="maxDate"
       [value]="value"
       [required]="required"
       [disabled]="disabled"
       (dateChange)="onDateChange($event.value)">
