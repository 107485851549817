import {Injectable} from "@angular/core";
import {EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {EntityStoreBaseService} from "./entity-store-base.service";

@Injectable({providedIn: 'root'})
export class SettlementUserEntityService extends EntityStoreBaseService<SettlementUser> {
  constructor(serviceFactory: EntityCollectionServiceElementsFactory) {
    super('SettlementUser', serviceFactory);
  }
}

export interface SettlementUser {
  email: string;
  firstName: string;
  id: number;
  isAdmin: boolean;
  isExternal: boolean;
  isSuperAdmin: boolean;
  isValidRole: boolean;
  lastName: string;
  name: string;
  username: string;
}
