import {AuthConfig, getRemainingHours} from 'auth';
import {environment} from "../../environments/environment";

export const authConfig: AuthConfig = {
  loginRoute: 'login',
    apiUrl: environment.authApiUrl+'/token/jwt',
    refreshToken: {
    apiUrl: environment.authApiUrl+'/token/jwtrefresh',
      refreshWhen: (token) => {
      const hours = getRemainingHours(token.expires);
      return !!hours && hours <= 2;
    },
  }
};
