import {Component, inject, ChangeDetectionStrategy} from '@angular/core';
import {Store} from '@ngrx/store';
import {CoreActions} from '../../store/core/action-types';
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-session-error',
  templateUrl: './session-error.component.html',
  styleUrls: ['./session-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
  ],
})
export class SessionErrorComponent {
  private store = inject(Store);

  public initialize(): void {
    this.store.dispatch(CoreActions.initializeSession());
  }
}
