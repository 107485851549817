import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {tap} from "rxjs/operators";
import {CORE_CONFIG} from "../../consts/injection-tokens";
import {CoreActions} from "../core/action-types";
import {EntityStoreBaseService} from "../../service/entity-data/entity-store-base.service";
import {Router} from "@angular/router";

@Injectable()
export class EntityEffects {
  private actions$ = inject(Actions);
  private config = inject(CORE_CONFIG);
  private entityServices: EntityStoreBaseService<any>[] = this.config.resetEntitiesServices.map((type) => inject(type));
  private router = inject(Router);

  resetEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.resetEntities),
      tap(async ({redirect}) => {
        this.entityServices.forEach((service) => service.clearCache());
        if (redirect) {
          await this.router.navigateByUrl(redirect);
        }
        window.location.reload();
      })
    ), {dispatch: false}
  );
}
