import {ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {Employee, EmployeeStatusFlat} from "../../core/interface/employees.interface";
import {Store} from "@ngrx/store";
import {selectEmployeeStatusesFlat} from "../../core/store/enums/enums.selectors";
import {isTruthy} from "caig-utils";
import {EnumsActions} from "../../core/store/enums/action-types";
import {combineLatest, filter, map, Observable} from "rxjs";

@Component({
  selector: 'lib-employee-status',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    AsyncPipe,
    MatChipsModule,
    MatIconModule,
  ],
  templateUrl: './employee-status.component.html',
  styleUrls: ['./employee-status.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeStatusComponent implements OnInit {
  private store = inject(Store);

  @Input({required: true}) employee$!: Observable<Employee | undefined>;

  statuses$!: Observable<string[]>;

  ngOnInit() {
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'employeeStatus'}));

    this.statuses$ = combineLatest([
      this.employee$.pipe(filter(isTruthy)),
      this.store.select(selectEmployeeStatusesFlat).pipe(filter(isTruthy))
    ])
      .pipe(
        map(([employee, statuses]) => {
          const result: string[] = [];
          const status = statuses.find((s) => s.name === employee.status);
          if (status) {
            const reverseStatusTree = (status: EmployeeStatusFlat) => {
              result.unshift(status.name);
              if (status.parent) {
                const parentStatus = statuses.find((s) => s.name === status.parent!.name);
                if (parentStatus) {
                  reverseStatusTree(parentStatus);
                }
              }
            };
            reverseStatusTree(status);
          }
          return result;
        })
      );
  }
}
