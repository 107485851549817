import {Injectable, inject} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import {Observable, catchError} from 'rxjs';
import {NotificationService} from 'notification';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private notification = inject(NotificationService);
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        this.notification.showDetailedMessage('An error has occurred', err);
        throw err;
      })
    );
  }
}
