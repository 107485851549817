<div class="flex gap-4" [style.width.px]="config.ext ? 315 : 230">
  <mat-form-field class="w-full" [subscriptSizing]="config.subscriptSizing" [formGroup]="form" [appearance]="config.appearance" [color]="config.color">
    <mat-label>{{config.label}}</mat-label>
    <mat-icon matSuffix>phone</mat-icon>
    <lib-phone-number-input [formControlName]="config.name" [required]="config.required" [showExt]="config.ext"></lib-phone-number-input>
    <mat-error *ngIf="control.hasError('required')">{{config.label}} is required.</mat-error>
    <mat-hint *ngIf="config.hint as hint" [align]="hint.align || 'start'">{{hint.message}}</mat-hint>
  </mat-form-field>
  <button *ngIf="config.menu" mat-icon-button [matMenuTriggerFor]="phoneMenu" [disabled]="!!config.menu.disabled && config.menu.disabled(control.value)">
    <mat-icon>{{config.menu.icon}}</mat-icon>
  </button>
  <mat-menu #phoneMenu="matMenu">
    <button mat-menu-item *ngFor="let item of config.menu?.items" (click)="item.callback()">
      <mat-icon *ngIf="item.icon" [color]="item.iconColor">{{item.icon}}</mat-icon>
      {{item.name}}
    </button>
  </mat-menu>
</div>
