<div class="flex h-full flex-col box-border p-4">
  <lib-entity-iterator [entities]="entities()" [entityIndex]="entityIndex()" entityUrl="employees" [isLoading]="isLoading$ | async"></lib-entity-iterator>
  <div class="flex overflow-x-auto overflow-y-hidden gap-4 h-24">
    <div class="min-w-[150px]" *ngFor="let btn of toolbarButtons | async">
      <a class="w-full"
         mat-stroked-button
         [color]="btn.color"
         [disabled]="!!btn.disabled"
         (click)="btn.callback ? btn.callback() : null"
         queryParamsHandling="preserve"
         [routerLink]="btn.routerLink">
        <mat-icon>{{btn.icon}}</mat-icon>
        <div>{{btn.label}}</div>
      </a>
    </div>
  </div>
  <h1 *ngIf="employee$ | async as emp">{{emp.firstName}} {{emp.lastName}} <span class="text-red-600" *ngIf="emp.deceased">[DECEASED]</span></h1>
  <mat-divider></mat-divider>
  <div *ngIf="employee$ | async as employee" class="mt-4 flex flex-grow overflow-auto">
    <mat-grid-list class="h-full w-full" gutterSize="12px" *ngIf="gridColumns$ | async as cols" [cols]="cols" rowHeight="55px">
      <mat-grid-tile rowspan="12">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Contact Information</mat-card-title>
          </mat-card-header>
          <mat-card-content class="flex flex-grow mb-2">
            <lib-employee-contact-info [employee]="employee"></lib-employee-contact-info>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="2">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Status</mat-card-title>
          </mat-card-header>
          <lib-employee-status [employee$]="employee$"></lib-employee-status>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="2">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Tags</mat-card-title>
          </mat-card-header>
          <lib-employee-tags [employee]="employee"></lib-employee-tags>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="2">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Notes</mat-card-title>
          </mat-card-header>
          <lib-employee-notes [employee]="employee"></lib-employee-notes>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="6">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Contacts with Employee (recent first)</mat-card-title>
          </mat-card-header>
          <lib-employee-contact [employee]="employee"></lib-employee-contact>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="2">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Tax Exemptions</mat-card-title>
          </mat-card-header>
          <lib-employee-tax [employee]="employee"></lib-employee-tax>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="6">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Surveys</mat-card-title>
          </mat-card-header>
          <lib-employee-surveys [employee]="employee"></lib-employee-surveys>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="10">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Events</mat-card-title>
          </mat-card-header>
          <lib-employee-events [employee]="employee"></lib-employee-events>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="6">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Documents</mat-card-title>
          </mat-card-header>
          <lib-employee-documents [employee]="employee"></lib-employee-documents>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="3" [colspan]="cols">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Amounts Owed</mat-card-title>
          </mat-card-header>
          <lib-employee-amounts [employee]="employee"></lib-employee-amounts>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile rowspan="4" [colspan]="cols">
        <mat-card class="mat-elevation-z2 h-full w-full pr-2 pl-2">
          <mat-card-header>
            <mat-card-title>Payments</mat-card-title>
          </mat-card-header>
          <lib-employee-payments [employee]="employee"></lib-employee-payments>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
