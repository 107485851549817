import {AppPortal, CoreConfig, EmployeeEntityService, SessionRole, SettlementUserEntityService} from "shared-modules";
import {authConfig} from "./auth.config";

export const coreConfig: CoreConfig = {
  authConfig: authConfig,
  portal: AppPortal.CallCenter,
  navigationMenu: [
    {
      roleIds: [
        SessionRole.User,
        SessionRole.Administrator,
        SessionRole.Superadmin,
      ],
      menu: [
        {
          label: 'Call List',
          route: 'call-list',
          icon: 'contact_phone',
        },
        {
          label: 'Events',
          route: 'events',
          icon: 'receipt_long',
        },
      ],
    },
    {
      title: 'Admin Menu',
      roleIds: [
        SessionRole.Administrator,
        SessionRole.Superadmin,
      ],
      menu: [
        {
          label: 'Users',
          route: 'users',
          icon: 'people',
        },
      ],
    },
  ],
  userMenu: [],
  resetEntitiesServices: [
    EmployeeEntityService,
    SettlementUserEntityService,
  ],
};
