<div role="group" class="number-range-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input class="number-range-input-element"
         matInput
         formControlName="start"
         placeholder="Start"
         aria-label="Start number"
         (input)="onChange(value)"
         #start>
  <span class="number-range-input-spacer">&ndash;</span>
  <input class="number-range-input-element"
         matInput
         formControlName="end"
         placeholder="End"
         aria-label="End number"
         (input)="onChange(value)"
         (keyup.backspace)="autoFocusPrev(parts.controls.end, start)"
         #end>
</div>
