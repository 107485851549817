import {Directive, inject, OnInit} from "@angular/core";
import {EmailActions} from "../../../core/store/email/action-types";
import {Store} from "@ngrx/store";
import {filter, map, Observable, Subject, withLatestFrom} from "rxjs";
import {selectEmailSignatures, selectEmailTemplates} from "../../../core/store/email/email.selectors";
import {collectionToKeyValue$, isTruthy} from "caig-utils";
import {shareReplay} from "rxjs/operators";
import {EMPTY_GUID} from "../../../core/consts/util";
import {selectCurrentSettlement} from "../../../core/store/core/core.selectors";
import {FormGroup} from "@angular/forms";
import {BaseField, SelectField} from "dynamic-form";
import {RenderedEmail} from "../../../core/service/email.service";
import {SendPayload} from "./email-preview/email-preview.component";

@Directive()
export abstract class SendEmailComponent implements OnInit {
  protected store = inject(Store);

  protected templateIdChange$ = new Subject<string>();
  protected emailTemplates$ = this.store.select(selectEmailTemplates).pipe(
    filter(isTruthy),
    shareReplay(1),
  );
  protected signatureTemplates$ = this.store.select(selectEmailSignatures).pipe(
    filter(isTruthy),
    map(addNoTemplateOption),
  );

  settlement$ = this.store.select(selectCurrentSettlement).pipe(
    filter(isTruthy),
    shareReplay(1),
  );

  templateForm: FormGroup = new FormGroup({});
  templateFields: BaseField<any>[][] = [
    [
      new SelectField({
        name: 'templateId',
        label: 'Email Template',
        options: collectionToKeyValue$(this.emailTemplates$, 'title', 'id'),
        required: true,
        onChange: (value) => this.templateIdChange$.next(value as string),
      }),
      new SelectField({
        name: 'signatureId',
        label: 'Signature Template',
        options: collectionToKeyValue$(this.signatureTemplates$, 'title', 'id'),
        disabled: true,
        // required: true,
      }),
    ],
  ];
  templateFormModel$ = this.templateIdChange$.pipe(
    withLatestFrom(this.emailTemplates$),
    map(([templateId, templates]) => templates.find((t) => t.id === templateId)),
    filter(isTruthy),
    map((template) => ({
      templateId: template.id,
      signatureId: template.defaultSignatureId || EMPTY_GUID,
      defaultEventCode: template.defaultEventCode,
    })),
    shareReplay(1),
  );

  abstract preview$: Observable<RenderedEmail | null>;

  ngOnInit() {
    this.store.dispatch(EmailActions.loadEmailSignatures());
    this.store.dispatch(EmailActions.loadEmailTemplates());
  }

  abstract sendEmail(event: SendPayload, ...args: any): void;
}

function addNoTemplateOption(templates: {id: string, title: string}[]): {id: string, title: string}[] {
  return [
    {
      id: EMPTY_GUID,
      title: '< No Template >'
    },
    ...templates,
  ];
}
