<div class="flex flex-col gap-4 password-requirements-backdrop">
    <div class="flex flex-grow flex-col gap-4" *ngFor="let check of checks">
        <div class="flex flex-row gap-4" [ngClass]="{'required': !check.valid, 'completed': check.valid}">
            <mat-icon class="password-requirements-icon">{{check.valid ? 'check_circle' : 'cancel'}}</mat-icon>
            <span class="flex flex-grow">{{check.description}}</span>
        </div>
        <div *ngFor="let subCheck of check.requirements"
             class="flex flex-row gap-4 password-requirements-indented"
             [ngClass]="{'completed': subCheck.valid}">
            <mat-icon
                    class="password-requirements-icon">{{subCheck.valid ? 'check_circle' : 'radio_button_unchecked'}}</mat-icon>
            <span class="flex flex-grow">{{subCheck.description}}</span>
        </div>
    </div>
</div>
