import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";

export const redirectGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const replaceStr = '/employees';
    if (state.url.startsWith(replaceStr)) {
        router.navigateByUrl(state.url.replace(replaceStr, '/call-list'));
        return false;
    }
    return true;
};
