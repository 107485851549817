import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import {BaseField, DynamicFormModule} from "dynamic-form";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'lib-form-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    DynamicFormModule,
  ],
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.css']
})
export class FormDialogComponent {
  data: FormDialogData = inject(MAT_DIALOG_DATA);
}

export interface FormDialogData {
  title: string;
  confirmText: string;
  fields: BaseField<any>[][];
}
