import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {EmployeesListComponent} from "./employees-list/employees-list.component";
import {ViewEmployeeComponent} from "./view-employee/view-employee.component";
import {EditEmployeeComponent} from "./edit-employee/edit-employee.component";
import {entityCollectionResolver} from "../core/service/resolvers/entity-collection.resolver";
import {EmployeeEntityService} from "../core/service/entity-data/employee-entity.service";
import {SingleEmailComponent} from "./email/components/single-email/single-email.component";
import {employeeResolver} from "./email/services/employee-resolver";
import {batchResolver} from "./email/services/batch-resolver";
import {BulkEmailComponent} from "./email/components/bulk-email/bulk-email.component";

const employeesRoutes: Routes = [
  { path: 'batch-email', pathMatch: 'full', redirectTo: '/employees' },
  {
    path: '',
    resolve: {
      entities: entityCollectionResolver(EmployeeEntityService),
    },
    children: [
      { path: '', component: EmployeesListComponent },
      { path: ':id', component: ViewEmployeeComponent },
    ],
  },
  { path: ':id/edit', component: EditEmployeeComponent },
  {
    path: ':id/email',
    component: SingleEmailComponent,
    resolve: { employee: employeeResolver },
  },
  {
    path: 'batch-email/:batchId',
    resolve: { employees: batchResolver },
    component: BulkEmailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(employeesRoutes)],
  exports: [RouterModule]
})
export class EmployeesRoutingModule { }
