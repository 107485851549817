import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectCoreState} from "../../store/core/core.selectors";
import {first, map, tap} from "rxjs";
import {CoreActions} from "../../store/core/action-types";

export const sessionGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  return store.select(selectCoreState).pipe(
    first((state) => !state.loading),
    tap((state) => {
      if (!state.session) {
        store.dispatch(CoreActions.initializeSession());
      }
    }),
    map(() => true),
  );
};
