import {ChangeDetectionStrategy, Component, inject, ViewEncapsulation} from '@angular/core';
import {DynamicFormModule, SelectField} from "dynamic-form";
import {Store} from "@ngrx/store";
import {map} from "rxjs";
import {AsyncPipe} from "@angular/common";
import {collectionToKeyValue$} from "caig-utils";
import {selectSettlementContext, selectSessionSettlements} from "../../store/core/core.selectors";
import {CoreActions} from "../../store/core/action-types";

@Component({
  selector: 'app-case-selector',
  templateUrl: './case-selector.component.html',
  styleUrls: ['./case-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    DynamicFormModule,
    AsyncPipe,
  ],
})
export class CaseSelectorComponent {
  private store = inject(Store);
  caseField = new SelectField({
    name: 'settlementId',
    label: 'Current Case',
    color: 'accent',
    options: collectionToKeyValue$(this.store.select(selectSessionSettlements), 'code', 'id'),
    appearance: 'outline',
    subscriptSizing: 'dynamic',
    onChange: (val) => this.store.dispatch(CoreActions.changeSettlementContext({settlementId: val as number})),
  });
  formModel$ = this.store.select(selectSettlementContext).pipe(
    map((settlementId) => ({settlementId}))
  );
}
