<mat-sidenav-container #container>
  <mat-sidenav
    #sidenav
    [opened]="false"
    (closedStart)="onClosing()"
    (closed)="onClosed()"
    (openedStart)="createContent()"
    mode="over"
    position="end"
    [style.width.%]="sidenavWidth$ | async">
    <mat-sidenav-content>
      <div class="flex-fill flex-column">
        <div *ngIf="isProcessing$ | async" class="loading-container">
          <mat-spinner diameter="100"></mat-spinner>
        </div>
        <mat-toolbar class="flex-row flex-align-spacebetween-center mat-elevation-z6">
          <div class="flex-row flex-align-start-center gap-8">
            <button mat-icon-button type="button" (click)="sidenav.close()" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <div>{{title$ | async}}</div>
          </div>
          <div class="flex-row gap-8">
            <button *ngFor="let button of menu$ | async"
                    [color]="button.color"
                    [disabled]="!!button.disabled"
                    (click)="button.callback()"
                    mat-raised-button>
              {{button.name}}
            </button>
          </div>
        </mat-toolbar>
        <div class="flex-1 component-container">
          <ng-container #content></ng-container>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav>
</mat-sidenav-container>
