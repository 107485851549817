import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, ReplaySubject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {cloneDeep, every} from 'lodash';
import {InputFieldButton} from "dynamic-form";

@Injectable({providedIn: 'root'})
export class PasswordService {
  private readonly _passwordWords$ = new ReplaySubject<string[]>();
  private readonly _passwordSubRequriements: PasswordRequirement[] = [
    {
      description: 'Lower case letters (a-z)',
      validation: (password) => /[a-z]/.test(password),
    },
    {
      description: 'Upper case letters (A-Z)',
      validation: (password) => /[A-Z]/.test(password),
    },
    {
      description: 'Numbers (i.e. 0-9)',
      validation: (password) => /\d/.test(password),
    },
    {
      description: 'Special characters (e.g. !@#$%^&*)',
      validation: (password) => /\W|_/g.test(password),
    },
  ];
  private readonly _passwordRequirements: PasswordRequirement[] = [
    {
      description: 'At least 8 characters in length',
      validation: (password) => password.length >= 8,
    },
    {
      description: 'Contains at least 3 of the following 4 types of characters:',
      validation: (password, confirmPassword, self) =>
        (self.requirements as PasswordRequirement[]).filter((requirement) => requirement.valid).length >= 3,
      requirements: this._passwordSubRequriements,
    },
    {
      description: 'Confirmation password must match new password',
      validation: (password, confirmPassword) => password === confirmPassword,
    }
  ];
  public get passwordRequirements(): PasswordRequirement[] {
    return cloneDeep(this._passwordRequirements);
  }
  constructor(private http: HttpClient) {
    this.loadPasswordWords();
  }

  private loadPasswordWords(): void {
    this.http.get('assets/words.txt', { responseType: 'text' }).subscribe({
      next: (wordList) => this._passwordWords$.next(wordList.split('\n')),
      error: () => this._passwordWords$.error('Unable to fetch word list'),
    });
  }
  public isValidPassword(password: string, confirmPassword: string, customRequirements?: PasswordRequirement[]): boolean {
    const requirements = customRequirements || this.passwordRequirements;
    checkRequirements(password, confirmPassword, requirements);
    return every(requirements, (req) => req.valid);
  }
  public async generatePassword(numWords: number = 2): Promise<string> {
    const words = await firstValueFrom(this._passwordWords$);
    let password = '';
    let availableWords = [...words];
    for (let i = 0; i < numWords; i++) {
      if (availableWords.length === 0) {
        availableWords = [...words];
      }
      const randomIndex = secureRandom(availableWords.length);
      const word = availableWords[randomIndex];
      availableWords.splice(randomIndex, 1); // Remove the word from the list
      password += formatWord(word) + generatePadding(); // Add the word and padding to the password
    }
    if (!this.isValidPassword(password, password)) {
      return this.generatePassword(numWords);
    }
    return password;
  }
  public generatePasswordButton(form: FormGroup, numWords: number = 2): InputFieldButton {
    return {
      icon: 'refresh',
      callback: async () => {
        const password = await this.generatePassword(numWords);
        form.patchValue({ password });
      },
      color: 'accent',
      tooltip: 'Generate random password',
    };
  }
}

// Helper function to generate a random number
function secureRandom(maxValue: number): number {
  return Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * maxValue);
}

// Function to randomly format a word
function formatWord(word: string): string {
  const format = secureRandom(3);
  switch (format) {
    case 0: // All lowercase
      return word.toLowerCase();
    case 1: // All uppercase
      return word.toUpperCase();
    case 2: // Capitalize first letter
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    default:
      return word;
  }
}

// Function to randomly generate a padding string
function generatePadding(): string {
  const symbols = ['!', '@', '#', '$', '%', '^', '&', '*'];
  const paddingLength = secureRandom(3) + 1; // Padding length between 1 and 3
  let padding = '';
  for (let i = 0; i < paddingLength; i++) {
    if (i % 2 === 0) { // Alternate between numbers and symbols
      padding += secureRandom(10); // Add a random number
    } else {
      padding += symbols[secureRandom(symbols.length)]; // Add a random symbol
    }
  }
  return padding;
}

export interface PasswordRequirement {
  description: string;
  validation: (password: string, confirmPassword: string, self: PasswordRequirement) => boolean;
  valid?: boolean;
  requirements?: PasswordRequirement[];
}

function checkRequirements(value: string, confirmPassword: string, checks: PasswordRequirement[]): void {
  checks.forEach((check) => {
    if (check.requirements) {
      checkRequirements(value, confirmPassword, check.requirements);
    }
    check.valid = value ? check.validation(value, confirmPassword, check) : false;
  });
}
