import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {VsTableColumn, VsTableColumnDataType} from "../types";
import {calcProp} from "../util";
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Component({
  selector: 'vs-table-cell',
  templateUrl: './vs-table-cell.component.html',
  styleUrls: ['./vs-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VsTableCellComponent<T> {
  @Input({required: true}) column!: VsTableColumn<T>;
  @Input({required: true}) row!: T;
  @Input({required: true}) rowHeight!: number;
  @Input({transform: coerceBooleanProperty}) isExpanded: boolean = false;
  @Output() expandChange = new EventEmitter<void>();
  readonly columnTypes = VsTableColumnDataType;
  get value() {
    const field = this.column.calculate ? calcProp(this.column) : this.column.field;
    return (this.row as any)[field] as any;
  }
  onCellClick(event: MouseEvent) {
    if (this.column.expandable) {
      event.stopPropagation();
      this.expandChange.emit(void 0);
    }
    if (this.column.dataType !== VsTableColumnDataType.Button) {
      this.emitOnClick(event);
    }
  }
  emitOnClick(event: MouseEvent) {
    if (this.column.onClick) {
      event.stopPropagation();
      this.column.onClick(this.value, this.row, this.column);
    }
  }
}
