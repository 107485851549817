<div class="flex h-full flex-col gap-5">
  <mat-card class="mat-elevation-z6 flex flex-col">
    <mat-card-header>
      <div mat-card-avatar></div>
      <mat-card-title>{{twilioService.identity}}</mat-card-title>
      <mat-card-subtitle>Call Center Agent</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col justify-center items-center">
        <h3 *ngIf="!selectedPhoneNumber">Select one of the available phone numbers to call</h3>
        <mat-form-field>
          <mat-label *ngIf="select.disabled">No available phone numbers exist for this employee</mat-label>
          <mat-select #select [disabled]="!employee.phone && !employee.phoneCell && !employee.phoneWork" placeholder="Phone number" [(value)]="selectedPhoneNumber" (selectionChange)="updateMenu()">
            <mat-option *ngIf="employee.phone as n" [value]="n">{{n}}</mat-option>
            <mat-option *ngIf="employee.phoneCell as n" [value]="n">{{n}}</mat-option>
            <mat-option *ngIf="employee.phoneWork as n" [value]="n + employee.phoneWorkExt">{{n}}<span *ngIf="employee.phoneWorkExt as x"> ({{x}})</span></mat-option>
          </mat-select>
          <mat-progress-bar mode="indeterminate" *ngIf="!twilioService.device"></mat-progress-bar>
        </mat-form-field>
        <h3 *ngIf="selectedPhoneNumber">{{statusText}}</h3>
        <div *ngIf="twilioService.activeCall as activeCall" class="flex flex-col justify-center items-center">
          <div>{{activeCall.startTime | date:'medium'}}</div>
          <div>{{elapsedTime | time}}</div>
          <div class="mt-6" *ngIf="activeCall.call as call">
            <div *ngIf="call.isMuted(); else mute" class="flex flex-col justify-center items-center gap-6">
              <button mat-flat-button color="accent" (click)="call.mute(false)">UN-MUTE</button>
              <div class="accent-red-800 text-lg font-bold">Your microphone is currently muted</div>
            </div>
            <ng-template #mute>
              <button mat-flat-button color="warn" (click)="call.mute(true)">MUTE</button>
            </ng-template>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
