import {combineLatest, filter, map, Observable, switchMap, takeUntil} from "rxjs";
import {computed, Directive, inject, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {ThemePalette} from "@angular/material/core";
import {Employee} from "../../core/interface/employees.interface";
import {isTruthy} from 'caig-utils';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {EntityList} from "../../entity-list";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {MergeStrategy} from "@ngrx/data";
import {shareReplay} from "rxjs/operators";

@Directive()
export abstract class ViewEmployeeBase extends EntityList<Employee> implements OnInit {
  protected dataService = inject(EmployeeEntityService);
  protected router = inject(Router);
  protected breakpointObserver = inject(BreakpointObserver);

  protected employeeId$ = this.route.params.pipe(
    map((params) => params['id']),
    filter(isTruthy),
  );
  protected employeeId = toSignal(this.employeeId$);

  isLoading$ = this.dataService.loading$;
  entityIndex = computed(() => {
    const id = this.employeeId();
    const entities = this.entities();
    return entities.findIndex((e) => e.id == id);
  });

  employee$: Observable<Employee | undefined> = combineLatest([toObservable(this.entities), this.employeeId$]).pipe(
    map(([entities, employeeId]) => {
      const employee = entities.find((e) => e.id == employeeId);
      return employee ? { ...employee } : employee;
    }),
    filter(isTruthy),
    shareReplay(1),
  );
  gridColumns$ = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(({matches}) => matches ? 1 : 2));

  abstract toolbarButtons: ToolbarButton[] | Observable<ToolbarButton[]>;

  override ngOnInit() {
    super.ngOnInit();

    this.employeeId$.pipe(
      switchMap((employeeId) => this.dataService.getByKey(employeeId, {mergeStrategy: MergeStrategy.OverwriteChanges})),
      takeUntil(this.onDestroy$),
    ).subscribe();
  }
}

export interface ToolbarButton {
  label: string;
  callback?: () => void;
  routerLink?: string;
  icon?: string;
  color?: ThemePalette;
  disabled?: boolean;
}
