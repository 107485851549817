import {Injectable, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {AUTH_CONFIG} from '../consts';
import {HttpClient} from '@angular/common/http';
import {AuthToken} from "../interfaces";

@Injectable()
export class AuthService {
  private config = inject(AUTH_CONFIG);
  private http = inject(HttpClient);

  public login(username: string, password: string): Observable<AuthToken> {
    return this.http.post<AuthToken>(this.config.apiUrl, {username, password});
  }

  public refreshToken(): Observable<AuthToken> {
    if (!this.config.refreshToken) {
      throw new Error('No apiUrl provided for refreshing token');
    }
    return this.http.post<AuthToken>(this.config.refreshToken.apiUrl, null);
  }
}
