import {ResolveFn} from "@angular/router";
import {inject} from "@angular/core";
import {first} from "rxjs/operators";
import {of, switchMap} from "rxjs";
import {Employee} from "../../../core/interface/employees.interface";
import {EmployeeEntityService} from "../../../core/service/entity-data/employee-entity.service";

export const employeeResolver: ResolveFn<Employee> = (route, state) => {
  const dataService = inject(EmployeeEntityService);
  return dataService.entityMap$.pipe(
    first(),
    switchMap((entityMap) => {
      const employeeId = Number(route.params['id']);
      const cached = entityMap[employeeId];
      return cached ? of(cached) : dataService.getByKey(employeeId);
    })
  );
}
