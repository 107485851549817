import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SidenavComponent, SidenavComponentMessage} from "sidenav-stack";
import {SafePipe} from "caig-utils";
import {NgIf} from "@angular/common";

@Component({
  selector: 'lib-view-pdf',
  standalone: true,
  imports: [
    NgIf,
    SafePipe,
  ],
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewPdfComponent implements SidenavComponent<void> {
  @Output() public controlMsg = new EventEmitter<SidenavComponentMessage>();
  @Input() public docUrl!: string | null;
}
