import {ParticipationStatus} from "../interface/employees.interface";
import {SidenavComponentMenuItem} from "sidenav-stack";

export const yesOrNo = [{value: true, key: 'Yes'}, {value: false, key: 'No'}];

export function mapNumberArrToModel(queryParams: any, field: string): any {
  return Array.isArray(queryParams[field]) ? queryParams[field].map(Number) : queryParams[field] ? [Number(queryParams[field])] : undefined;
}

export function mapStringArrToModel(queryParams: any, field: string): any {
  return Array.isArray(queryParams[field]) ? queryParams[field] : queryParams[field] ? [queryParams[field]] : undefined;
}

export function rowIcon<T>(field: Extract<keyof T, string>): (row: T) => string {
  return (row: T) => String(row[field]) === 'Uncertain' ? 'question_mark' : row[field] ? 'check' : 'close';
}

export function rowColor<T>(field: Extract<keyof T, string>): (row: T) => string {
  return (row: T) => String(row[field]) === 'Uncertain' ? 'orange' : row[field] ? 'green' : 'red';
}

export function participationRowPainter(participationStatus: ParticipationStatus): string {
  switch (participationStatus) {
    case ParticipationStatus.NoContact:
      return 'rgb(209,42,17)';
    case ParticipationStatus.InProgress:
      return 'rgb(217,186,28)';
    case ParticipationStatus.Completed:
      return 'rgb(29,163,11)';
    default:
      return '';
  }
}

export const saveMenuButton = (callback: () => void, disabled?: boolean): SidenavComponentMenuItem => ({
  name: 'Save',
  color: 'primary',
  callback,
  disabled,
});

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
