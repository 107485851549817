<div class="flex-row flex-align-spacebetween-center">
  <button mat-icon-button (click)="copyDetailsToClipboard()">
    <mat-icon aria-label="Copy to clipboard">content_copy</mat-icon>
  </button>
  <button mat-icon-button (click)="dialogRef.close()" cdkFocusInitial>
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
</div>
<h2 mat-dialog-title>{{data.message}}</h2>
<mat-dialog-content>
  <pre>{{data.details | json}}</pre>
</mat-dialog-content>
