<mat-grid-list
  *ngIf="payments?.length; else notPaid"
  [cols]="props.length + 5"
  rowHeight="40px"
  [style.min-width.px]="(props.length + 5) * 100 + 50">
  <mat-grid-tile *ngFor="let prop of props"><b>{{prop.value}}</b></mat-grid-tile>
  <mat-grid-tile><b>Paid Total</b></mat-grid-tile>
  <mat-grid-tile><b>+/-</b></mat-grid-tile>
  <mat-grid-tile><b>Status</b></mat-grid-tile>
  <mat-grid-tile><b>Date</b></mat-grid-tile>
  <mat-grid-tile style="min-width: 150px"><b>Actions</b></mat-grid-tile>
  <div *ngFor="let payment of payments; let i = index;">
    <mat-grid-tile *ngFor="let prop of props">{{payment[prop.key] | currency:'USD':'$'}}</mat-grid-tile>
    <mat-grid-tile><i>{{payment.paidTotal | currency:'USD':'$'}}</i></mat-grid-tile>
    <mat-grid-tile><i>{{payment.diff | currency:'USD':'$'}}</i></mat-grid-tile>
    <mat-grid-tile><div [ngClass]="payment.status | lowercase">{{payment.status | uppercase}}</div></mat-grid-tile>
    <mat-grid-tile>{{payment.date | date:'shortDate'}}</mat-grid-tile>
    <mat-grid-tile style="min-width: 150px">
      <button mat-icon-button color="primary" matTooltip="View PDF" (click)="viewPDF(payment)">
        <mat-icon>picture_as_pdf</mat-icon>
      </button>
      <div class="flex justify-start items-center" [matTooltip]="editBtn.disabled ? 'Processed or amended payments can only be modified by a super admin' : ''">
        <button #editBtn [disabled]="payment.status !== 'Pending' && !(isSuperAdmin$ | async)" matTooltip="Edit payment" mat-icon-button color="primary" (click)="edit(payment)">
          <mat-icon>edit</mat-icon>
        </button>
        <button [disabled]="editBtn.disabled" mat-icon-button color="warn" matTooltip="Delete payment" (click)="remove(payment)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
  </div>
  <div>
    <mat-grid-tile *ngFor="let prop of props"><b>{{summary[prop.key] | currency:'USD':'$'}}</b></mat-grid-tile>
    <mat-grid-tile *ngFor="let prop of addlProps"><b>{{payments[payments.length - 1][prop.key] | currency:'USD':'$'}}</b></mat-grid-tile>
    <mat-grid-tile><b></b></mat-grid-tile>
    <mat-grid-tile><b></b></mat-grid-tile>
    <mat-grid-tile style="min-width: 150px"><b></b></mat-grid-tile>
  </div>
</mat-grid-list>
<ng-template #notPaid>
  <h1>No payments</h1>
</ng-template>
