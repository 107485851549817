import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldType} from "../types";

export class TextareaField extends BaseField<string> {
  type = FieldType.Textarea;
  placeholder: string;

  constructor(config: TextareaFieldConfig) {
    super(config);
    this.placeholder = config.placeholder || '';
  }
}

export interface TextareaFieldConfig extends BaseFieldConfig<string> {
  placeholder?: string;
}
