<div class="flex-fill flex-column flex-align-start-center">
  <h1>ERROR 404</h1>
  <h2>PAGE NOT FOUND</h2>
  <mat-card [ngStyle]="{width: (isHandset$ | async) ? '80%' : '50%'}" class="flex-1 flex-column flex-align-center-center mat-elevation-z10">
    <h3>Oops! This is a Bit Awkward.</h3>
    <p>Hello, Adventurer! It looks like you've ventured off the beaten path. Unfortunately, the page you're looking for seems to be lost in the digital wilderness. But don't worry, we've got a couple of ways to get you back on track:</p>
    <ol>
      <li><b>Check Your URL:</b> It's possible that there's a typo in the URL, so make sure everything is spelled correctly.</li>
      <li><b>Contact Support:</b> If you believe there should be a specific page at this address or you need further assistance, feel free to <a mat-button color="accent" href="mailto:support@caig.co?subject=404 Error&body=I encountered a 404 error when visiting the following url: {{url}}">contact our friendly support team.</a></li>
      <li><b>Home, Sweet Home:</b> If all else fails, you can always <a mat-button color="accent" routerLink="/">return to our homepage</a>.</li>
    </ol>
    <p>Remember, it's not about the destination, but the journey. And it's clear you're not afraid to explore. Good luck out there!</p>
    <p>Your journey in our digital wilderness is important to us! If you can, please let us know how you ended up here so we can improve our map.</p>
    <p>Thanks for your understanding, and safe travels on your next adventure!</p>
    <h4>The CAIG Web Team</h4>
  </mat-card>
</div>
