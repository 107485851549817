import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy
} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {SimpleChangesTyped} from "caig-utils";
import {DynamicFormBuilderService} from "../service/dynamic-form-builder.service";
import {DynamicFormField} from "../types";
import {ReplaySubject} from "rxjs";
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnChanges, OnDestroy {
  @Input({required: true}) fields: DynamicFormField[][] = [];
  @Input() form!: FormGroup;
  @Input() model: any;
  @Input({transform: coerceBooleanProperty}) mergeFields = false;

  private formBuilder = inject(DynamicFormBuilderService);
  private onDestroy$ = new ReplaySubject<void>();

  ngOnChanges(changes: SimpleChangesTyped<this>) {
    if (changes.fields?.currentValue || changes.form?.currentValue) {
      this.form = this.formBuilder.createForm(this.onDestroy$, this.fields, this.mergeFields, this.form);
    }
    if (changes.model?.currentValue) {
      this.form.reset({}, {emitEvent: false});
      this.form.patchValue(this.model, { emitEvent: false });
    }
  }
  ngOnDestroy() {
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }

  onSubmit(event: any) {
    console.log(event);
  }
}
