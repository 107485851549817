import {Component, inject, ChangeDetectionStrategy} from '@angular/core';
import {MatSnackBarRef, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {DetailDialogComponent} from '../detail-dialog/detail-dialog.component';

@Component({
  selector: 'lib-detailed-snackbar',
  templateUrl: './detailed-snackbar.component.html',
  styleUrls: ['./detailed-snackbar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedSnackbarComponent {
  private dialog = inject(MatDialog);
  public snackRef = inject(MatSnackBarRef<DetailedSnackbarComponent>);
  public data: { message: string, details: any } = inject(MAT_SNACK_BAR_DATA);
  public showDetails(): void {
    this.snackRef.dismiss();
    this.dialog.open(DetailDialogComponent, {
      data: this.data,
      width: '600px',
      position: {
        top: 'top',
        right: 'right',
      },
    });
  }
}
