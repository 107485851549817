<div class="flex h-full flex-col gap-3">
  <mat-toolbar class="mat-elevation-z6 flex justify-center items-center">
    Bulk Email
  </mat-toolbar>
  <div class="flex flex-col flex-grow gap-3">
    <mat-card class="flex flex-row gap-3">
      <div class="flex justify-between items-center mx-4">
        <div class="flex flex-col justify-start items-center">
          <div class="text-lg opacity-75 my-2">Recipients</div>
          <cdk-virtual-scroll-viewport #viewport itemSize="32">
            <div *cdkVirtualFor="let emp of employees; let i = index;" class="address flex items-center" [ngClass]="{'selected': invalidEmployee === emp}">
              <div *ngIf="emp.email || emp.emailAlt as email; else noEmail"><b>{{i + 1}}.</b> {{email}}</div>
              <ng-template #noEmail>
                <div class="flex flex-row items-center gap-2">
                  <mat-icon (click)="getEmailForEmployee(emp)" class="pointer text-accent" matTooltip="Add email address for {{emp.name}}">add</mat-icon>
                  <b>{{emp.name}} has no valid email</b>
                </div>
              </ng-template>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
        <mat-chip-listbox>
          <mat-chip>{{employees?.length}} addresses</mat-chip>
          <mat-chip *ngIf="invalidEmployees"
                    (click)="scrollToNextInvalid(viewport)"
                    [ngClass]="{'pointer': !!invalidEmployees.length}">
            {{invalidEmployees.length ? invalidEmployees.length + ' invalid' : 'All valid'}}
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <dynamic-form [form]="templateForm" [fields]="templateFields" [model]="templateFormModel$ | async"></dynamic-form>
    </mat-card>
    <div class="flex flex-grow">
      <lib-email-preview
        *ngIf="employees"
        (send)="sendEmail($event, viewport)"
        [employees]="employees"
        [preview]="preview$ | async"
        [defaultEventCode]="(templateFormModel$ | async)?.defaultEventCode"
        [settlement]="settlement$ | async"></lib-email-preview>
    </div>
  </div>
</div>

