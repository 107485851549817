import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {EventsListComponent} from "./events-list/events-list.component";

const eventsRoutes: Routes = [
  {
    path: '',
    component: EventsListComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(eventsRoutes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }
