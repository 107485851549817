import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EnumsActions} from "./action-types";
import {catchError, filter, map, mergeMap, Observable, of, withLatestFrom} from "rxjs";
import {EnumDataService} from "../../service/enum-data.service";
import {Store} from "@ngrx/store";
import {selectEnumsState} from "./enums.selectors";

@Injectable()
export class EnumsEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private dataService = inject(EnumDataService);

  loadEnum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumsActions.loadEnum),
      withLatestFrom(this.store.select(selectEnumsState)),
      filter(([{enumType}, state]) => !state[enumType].loaded),
      mergeMap(([{enumType}]) =>
        (this.dataService[enumType]() as Observable<any[]>).pipe(
          map((values) => EnumsActions.enumLoaded({enumType, values})),
          catchError(() => of(EnumsActions.enumError({enumType})))
        )
      ),
    )
  );
}
