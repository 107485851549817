import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {CustomOverlayComponent, OVERLAY_DATA} from "../../core/service/overlay.service";
import {TwilioVoiceService} from "../../core/service/twilio-voice.service";
import {CdkDragEnd, DragDropModule} from "@angular/cdk/drag-drop";
import {MatCardModule} from "@angular/material/card";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {TimePipe} from "caig-utils";
import {NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'lib-phone-call-overlay',
  standalone: true,
  imports: [
    NgIf,
    TimePipe,
    MatButtonModule,
    MatCardModule,
    DragDropModule,
  ],
  templateUrl: './phone-call-overlay.component.html',
  styleUrls: ['./phone-call-overlay.component.css'],
  animations: [
    trigger('enterExit', [
      state('void', style({ transform: 'scale(0) translateY(-100%)' })),
      state('*', style({ transform: 'scale(1) translateY(0)' })),
      transition(':enter', animate('500ms ease-out', keyframes([
        style({ transform: 'scale(0) translateY(-100%)', offset: 0 }),
        style({ transform: 'scale(1) translateY(0)', offset: 1 })
      ]))),
      state('exit', style({
        transform: 'scale(0.68)',
        opacity: 0,
      })),
      transition('* => exit', animate('300ms cubic-bezier(0.35, 0, 0.25, 1)')),
    ])
  ]
})
export class PhoneCallOverlayComponent implements CustomOverlayComponent<any>, OnInit {
  twilioService = inject(TwilioVoiceService);
  data: any = inject(OVERLAY_DATA);

  @Output() animationDone = new EventEmitter<void>();
  @Output() dragEnded = new EventEmitter<{ x: number; y: number }>();

  animationState: 'enter' | 'exit' = 'enter';
  elapsedTime: number | undefined;

  private timerCallback = () => {
    if (this.twilioService.activeCall) {
      this.elapsedTime = (new Date().getTime() - this.twilioService.activeCall.startTime.getTime()) / 1000 / 60 / 60;
    }
  };
  private callTimer = setInterval(() => this.timerCallback(), 1000);

  ngOnInit() {
    this.timerCallback();
    if (this.twilioService.activeCall) {
      this.twilioService.replaceListener(this.twilioService.activeCall.call, 'disconnect', () => this.startExitAnimation());
    }
  }

  startExitAnimation(): void {
    if (this.twilioService.activeCall) {
      this.twilioService.activeCall.call.disconnect();
    }
    clearInterval(this.callTimer);
    this.animationState = 'exit';
    this.twilioService.destroyDevice();
  }

  onAnimationDone(): void {
    if (this.animationState === 'exit') {
      this.animationDone.emit();
    }
  }

  dragEnd(event: CdkDragEnd): void {
    // @TODO - update overlayRef position so that exit animation doesn't snap back to original position.
    // this.dragEnded.emit(event.source.getFreeDragPosition());
    // console.log(event.source.getFreeDragPosition());
    // const element = event.source.getRootElement(); // Get the root element of the draggable
    // const rect = element.getBoundingClientRect(); // Get the position and dimensions of the element
    // const x = rect.left + window.scrollX; // Calculate the absolute x position
    // const y = rect.top + window.scrollY; // Calculate the absolute y position
    // console.log(x, y);
    // this.dragEnded.emit({ x, y });
  }
}
