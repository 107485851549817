import {NgModule} from '@angular/core';
import {PageNotFoundComponent} from './page-not-found.component';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {ThemedBackgroundComponent} from 'caig-utils';

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    RouterModule.forChild([
      {
        path: '',
        component: ThemedBackgroundComponent,
        children: [
          {
            path: '',
            component: PageNotFoundComponent,
          }
        ]
      },
    ])
  ],
})
export class PageNotFoundModule { }
