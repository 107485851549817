import {BaseFieldComponent} from "./base-field.component";
import {Directive, OnInit} from "@angular/core";
import {RadioField} from "../class/radio-field";
import {isObservable, map, Observable, of, shareReplay} from "rxjs";
import {KeyValue} from "@angular/common";
import {SelectField} from "../class/select-field";
import {FieldOptions} from "../types";

type FieldWithOptions = RadioField | SelectField;

@Directive()
export abstract class BaseFieldOptionsComponent<T extends FieldWithOptions> extends BaseFieldComponent<T> implements OnInit {
  options$!: Observable<KeyValue<string | number | null, any>[]>;
  ngOnInit() {
    this.options$ = this.transformOptions(this.config.options);
  }
  private transformOptions(options: FieldOptions = []): Observable<KeyValue<string | number | null, any>[]> {
    if (isObservable(options)) {
      return (options as Observable<any>).pipe(
        map((value) => {
          if (Array.isArray(value)) {
            // The observable emits an array (can be of string | number | null or KeyValue)
            return value.map((item) => this.isKeyValue(item) ? item : this.convertToKeyValue(item));
          } else {
            // The observable emits a single value (should be KeyValue)
            return [this.isKeyValue(value) ? value : this.convertToKeyValue(value)];
          }
        }),
        shareReplay(),
      );
    } else if (Array.isArray(options)) {
      // Direct array of string | number | null or KeyValue
      return of(options.map((item) => this.isKeyValue(item) ? item : this.convertToKeyValue(item)));
    } else {
      // Single KeyValue
      return of(options as KeyValue<string | number | null, any>[]);
    }
  }
  private isKeyValue(item: any): item is KeyValue<string | number | null, any> {
    return item && typeof item === 'object' && 'key' in item && 'value' in item;
  }
  private convertToKeyValue(item: string | number | null): KeyValue<string | number | null, any> {
    return { key: item, value: item };
  }
}
