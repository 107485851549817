<mat-card class="max-w-md mx-auto mt-6">
  <mat-card-content>
    <!-- Phone Number Input -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Phone Number</mat-label>
      <input matInput [(ngModel)]="phoneNumber">
    </mat-form-field>

    <!-- Dialer Buttons -->
    <div class="flex flex-col mt-4 space-y-2">
      <div class="flex justify-center space-x-2" *ngFor="let row of dialerKeys">
        <button
          *ngFor="let key of row"
          mat-raised-button
          color="primary"
          class="w-16 h-16"
          (click)="appendDigit(key)">
          {{ key }}
        </button>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="flex justify-between items-center mt-6">
      <button mat-icon-button color="warn" (click)="deleteDigit()">
        <mat-icon>backspace</mat-icon>
      </button>
      <button
        mat-fab
        color="accent"
        (click)="call()"
        [disabled]="!isPhoneNumberValid"
        class="disabled:opacity-50">
        <mat-icon>call</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
