import {isEmpty} from "caig-utils";
import {ThemePalette} from "@angular/material/core";

export interface FormFieldHint {
  message: string;
  align?: 'start' | 'end';
}

export class NumberRange {
  public start: number | null;
  public end: number | null;
  constructor(start?: string | number | null, end?: string | number | null) {
    this.start = isEmpty(start) ? null : Number(start);
    this.end = isEmpty(end) ? null : Number(end);
  }
}

export interface FieldMenu {
  icon: string;
  items: FieldMenuItem[]
  disabled?: (value: string) => boolean;
}

export interface FieldMenuItem {
  name: string;
  callback: () => void;
  icon?: string;
  iconColor?: ThemePalette;
}
