import {Component, ChangeDetectionStrategy} from '@angular/core';
import {NgStyle} from '@angular/common';
import {RouterModule} from '@angular/router';

@Component({
  selector: 'themed-background',
  templateUrl: './themed-background.component.html',
  styleUrls: ['./themed-background.component.css'],
  standalone: true,
  imports: [
    RouterModule,
    NgStyle
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemedBackgroundComponent {
  public backgroundImages = {
    '--bg-dark': toCSSUrl('https://lfmxstagestore.blob.core.windows.net/content/bg-dark.webp'),
    '--bg-light': toCSSUrl('https://lfmxstagestore.blob.core.windows.net/content/bg-light.webp'),
  };
}

const toCSSUrl = (url: string = '') => `url(${url})`;
