<date-range
  [form]="form"
  [controlName]="config.name"
  [dateFilter]="config.dateFilter"
  [startView]="config.startView"
  [hint]="config.hint?.message"
  [hintAlign]="config.hint?.align"
  [label]="config.label"
  [appearance]="config.appearance"
  [color]="config.color"
  [touchUi]="config.touchUi"
  [minDate]="config.minDate"
  [maxDate]="config.maxDate"
  [startDate]="config.startDate"
  [disableStartDate]="config.disableStartDate"
  [disableStartDateToggle]="config.disableStartDateToggle"
  [subscriptSizing]="config.subscriptSizing"
  [dateClass]="config.dateClass"></date-range>
