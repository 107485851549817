<mat-list-item *ngFor="let item of menu"
               [routerLink]="item.route"
               routerLinkActive
               #link="routerLinkActive"
               [routerLinkActiveOptions]="{exact: item.route === '/'}"
               [activated]="link.isActive">
  <mat-icon *ngIf="item.icon" matListItemIcon>{{item.icon}}</mat-icon>
  <a matListItemTitle>{{ item.label }}</a>
  <button *ngIf="item.description as descr"
          mat-icon-button
          matListItemMeta
          (click)="$event.stopPropagation(); showNavItemDescription(item.label, descr)">
    <mat-icon>info</mat-icon>
  </button>
</mat-list-item>
