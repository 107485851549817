import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  adminGuard,
  EmployeeEntityService,
  entityCollectionResolver,
  initializeGuard,
  LayoutComponent,
  sessionGuard, SessionRole
} from "shared-modules";
import {authGuard, LoginComponent} from "auth";
import {ThemedBackgroundComponent} from "caig-utils";
import {redirectGuard} from "./call-list/redirect-guard";
import {authConfig} from "./auth.config";

const routes: Routes = [
  {
    path: '',
    canActivate: [initializeGuard],
    children: [
      {
        path: '',
        canActivate: [authGuard(true), sessionGuard],
        children: [
          {
            path: '',
            component: LayoutComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/call-list',
              },
              {
                path: 'employees',
                children: [
                  {
                    path: '**',
                    children: [],
                    canActivate: [redirectGuard]
                  }
                ],
              },
              {
                path: 'call-list',
                resolve: {
                  entities: entityCollectionResolver(EmployeeEntityService),
                },
                loadChildren: () => import('./call-list/call-list.module').then(m => m.CallListModule),
              },
              {
                path: 'events',
                loadChildren: () => import('shared-modules').then(m => m.EventsModule),
              },
              {
                path: 'users',
                loadChildren: () => import('shared-modules').then(m => m.UsersModule),
                canActivate: [adminGuard(SessionRole.Administrator)],
              },
              {
                path: 'profile',
                loadChildren: () => import('shared-modules').then(m => m.ProfileModule),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: authConfig.loginRoute,
    component: ThemedBackgroundComponent,
    canActivate: [authGuard(false)],
    children: [
      {
        path: '',
        component: LoginComponent,
      }
    ]
  },
  { path: '**', loadChildren: () => import('shared-modules').then(m => m.PageNotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
