import {NgModule, ModuleWithProviders, Optional, SkipSelf, isDevMode, inject} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthActions, AuthModule} from 'auth';
import {authConfig} from './auth.config';
import {ErrorHandlerInterceptor} from './service/interceptor/error-handler.interceptor';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NotificationModule} from 'notification';
import {CORE_CONFIG, CoreConfig} from "./consts/injection-tokens";
import {Store, StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {StoreRouterConnectingModule} from "@ngrx/router-store";
import {reducers, metaReducers, effects} from "./store/state";
import {SettlementIdInterceptor} from "./service/interceptor/settlement-id.interceptor";
import {ThemeService} from "./service/theme.service";
import {PrefixerInterceptor} from "./service/interceptor/prefixer.interceptor";

const OMITTED_TEXT = '<omitted for performance>';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule.forRoot(authConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately'
    }),
    NotificationModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      stateSanitizer: (state) => {
        if (state.entityCache) {
          return {...state, entityCache: OMITTED_TEXT};
        }
        return state;
      },
      actionSanitizer: (action) => {
        if (action.type.endsWith('query-all/success')) {
          return { ...action, payload: OMITTED_TEXT };
        }
        return action;
      }
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
})
export class CoreModule {
  private store = inject(Store);
  private themeService = inject(ThemeService);
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }

    this.themeService.applyStoredTheme();
    this.store.dispatch(AuthActions.login({}));
  }
  static forRoot(config?: CoreConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: PrefixerInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorHandlerInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SettlementIdInterceptor,
          multi: true,
        },
        {
          provide: CORE_CONFIG,
          useValue: config || {},
        },
      ],
    };
  }
}
