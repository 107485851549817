import {Component, Input, ViewChild} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {
  DateFilterFn,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendarCellClassFunction,
  MatDatepickerModule, MatDateRangePicker
} from "@angular/material/datepicker";
import {NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {DateRangeSelectionStrategy} from "./date-range-selection-strategy";
import {MatTooltipModule} from "@angular/material/tooltip";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {convertToMoment, convertToString} from "../../util-functions";
import {CustomFormControl} from "caig-utils";
import moment from "moment";
import {CalendarStartView} from "../../types";
import {DateRangeInputComponent} from "../date-range-input/date-range-input.component";
import {DatepickerInputComponent} from "../datepicker-input/datepicker-input.component";

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
    DateRangeInputComponent,
    DatepickerInputComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useFactory: (component: DateRangeComponent) => new DateRangeSelectionStrategy(component),
      deps: [DateRangeComponent],
    },
  ],
})
export class DateRangeComponent extends CustomFormControl {
  private _minDate: moment.Moment | null = null;
  private _maxDate: moment.Moment | null = null;
  private _touchUi: boolean = false;
  private _disableStartDateToggle: boolean = false;
  private _disableStartDate: boolean = false;

  @ViewChild(MatDateRangePicker) daterangePicker!: MatDateRangePicker<moment.Moment>;

  @Input() dateFilter!: DateFilterFn<moment.Moment | null>;
  @Input() startView: CalendarStartView = 'month';
  @Input() startDate: moment.Moment | null = null;
  @Input() dateClass!: MatCalendarCellClassFunction<moment.Moment>;
  @Input() set disableStartDateToggle(value: any) { this._disableStartDateToggle = coerceBooleanProperty(value) }
  get disableStartDateToggle(): boolean { return this._disableStartDateToggle }
  @Input() set disableStartDate(value: any) { this._disableStartDate = coerceBooleanProperty(value) }
  get disableStartDate(): boolean { return this._disableStartDate }
  @Input() set touchUi(value: any) { this._touchUi = coerceBooleanProperty(value) }
  get touchUi() { return this._touchUi }
  @Input() set minDate(value: any) { this._minDate = convertToMoment(value) }
  get minDate() { return convertToString(this._minDate) }
  @Input() set maxDate(value: any) { this._maxDate = convertToMoment(value) }
  get maxDate() { return convertToString(this._maxDate) }

  toggleStartDate(input: DateRangeInputComponent) {
    this.disableStartDate = !this.disableStartDate;
    const emitEvent = !!input.range.controls.start.value;
    if (this.disableStartDate) {
      input.range.controls.start.disable({emitEvent});
    } else {
      input.range.controls.start.enable({emitEvent});
    }
  }
}
