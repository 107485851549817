import {NgModule} from "@angular/core";
import {ProfileRoutingModule} from "./profile-routing.module";
import {UserProfileComponent} from "./user-profile/user-profile.component";

@NgModule({
  imports: [
    ProfileRoutingModule,
    UserProfileComponent,
  ]
})
export class ProfileModule { }
