import {VsTableColumnFilter} from "./column-filter";
import {VsTableColumn, VsTableColumnDataType} from "./types";
import moment from "moment";
import {isEmpty} from "caig-utils";
import {ChangesColumn} from "./column-types/changes-column";
import {TableVirtualScrollDataSource} from "./table-virtual-scroll/table-data-source";

export class VsTableDataSource<T = any> extends TableVirtualScrollDataSource<T> {
  private readonly _columnFilterFns = {
    allToNumber: (column: VsTableColumn<T>, end: number) => (item: any) => item[column.field] <= end,
    allToNumberInverse: (column: VsTableColumn<T>, end: number) => (item: any) => item[column.field] > end,
    allToDate: (column: VsTableColumn<T>, end: string) => (item: any) => {
      const m = moment(item[column.field]);
      return m.isValid() && m.isSameOrBefore(end, 'date');
    },
    allToDateInverse: (column: VsTableColumn<T>, end: string) => (item: any) => {
      const m = moment(item[column.field]);
      return !m.isValid() || m.isSameOrBefore(end, 'date');
    },
    number: (column: VsTableColumn<T>, value: number) => (item: T) => item[column.field] == value,
    numberInverse: (column: VsTableColumn<T>, value: number) => (item: T) => item[column.field] != value,
    numberRange: (column: VsTableColumn<T>, start: number, end: number) => (item: any) => item[column.field] >= start && item[column.field] <= end,
    numberRangeInverse: (column: VsTableColumn<T>, start: number, end: number) => (item: any) => item[column.field] < start || item[column.field] > end,
    dateRange: (column: VsTableColumn<T>, start: string, end: string) => (item: any) => {
      const m = moment(item[column.field]);
      return m.isValid() && m.isBetween(start, end, 'date', '[]');
    },
    dateRangeInverse: (column: VsTableColumn<T>, start: string, end: string) => (item: any) => {
      const m = moment(item[column.field]);
      return !m.isValid() || !m.isBetween(start, end, 'date', '[]');
    },
    selected: (column: VsTableColumn<T>, selected: string[]) => (item: T) => !!selected.find((s) => s == item[column.field]),
    selectedInverse: (column: VsTableColumn<T>, selected: string[]) => (item: T) => !selected.find((s) => s == item[column.field]),
    empty: (column: VsTableColumn<T>) => (item: T) => isEmpty(item[column.field]),
    emptyInverse: (column: VsTableColumn<T>) => (item: T) => !isEmpty(item[column.field]),
    selectedChanges: (column: VsTableColumn<T>, selected: string[]) => (item: T) =>
      !!selected.find((s) => (item[column.field] as any[])?.find((c) => c[(column as ChangesColumn<T>).changedField] == s)),
    selectedChangesInverse: (column: VsTableColumn<T>, selected: string[]) => (item: T) =>
      !selected.find((s) => (item[column.field] as any[])?.find((c) => c[(column as ChangesColumn<T>).changedField] == s)),
    changes: (column: VsTableColumn<T>, filter: string) => (item: T) =>
      !!(item[column.field] as any[])?.find((change: any) =>
        `${change[(column as ChangesColumn<T>).oldValueField]}${change[(column as ChangesColumn<T>).newValueField]}${change[(column as ChangesColumn<T>).changedField]}`.toLowerCase().includes(filter)),
    changesInverse: (column: VsTableColumn<T>, filter: string) => (item: T) =>
      !(item[column.field] as any[])?.find((change: any) =>
        `${change[(column as ChangesColumn<T>).oldValueField]}${change[(column as ChangesColumn<T>).newValueField]}${change[(column as ChangesColumn<T>).changedField]}`.toLowerCase().includes(filter)),
    default: (column: VsTableColumn<T>, filter: string) => (item: T) => `${item[column.field]}`.toLowerCase().includes(filter),
    defaultInverse: (column: VsTableColumn<T>, filter: string) => (item: T) => !`${item[column.field]}`.toLowerCase().includes(filter),
  };
  private _columnFilters: {[key: string]: VsTableColumnFilter<T>} = {};

  get columnFilters() {
    return this._columnFilters;
  }

  set columnFilters(filters: {[key: string]: VsTableColumnFilter<T>}) {
    this._columnFilters = filters;
    this.filter = this.filter;
  }

  setColumnFilter(columnFilter: VsTableColumnFilter<T>) {
    if (columnFilter.isActive) {
      this._columnFilters[columnFilter.column.field] = columnFilter;
    } else {
      delete this._columnFilters[columnFilter.column.field];
    }
    this.filter = this.filter;
  }

  override _filterData(data: T[]): T[] {
    let filteredData = super._filterData(data);

    if (this._columnFilters) {
      Object.keys(this._columnFilters).forEach((field) => {
        const colFilter = {
          ...this._columnFilters[field],
          column: {
            ...this._columnFilters[field].column,
            field: this._columnFilters[field].column.calculate ?
              '_' + this._columnFilters[field].column.field as Extract<keyof T, string> :
              this._columnFilters[field].column.field,
          }
        };
        if (colFilter.selected.length && !colFilter.empty) {
          if (colFilter.column.dataType === VsTableColumnDataType.Changes) {
            filteredData = filteredData.filter(colFilter.invert ?
              this._columnFilterFns.selectedChangesInverse(colFilter.column, colFilter.selected) :
              this._columnFilterFns.selectedChanges(colFilter.column, colFilter.selected));
          } else {
            filteredData = filteredData.filter(colFilter.invert ?
              this._columnFilterFns.selectedInverse(colFilter.column, colFilter.selected) :
              this._columnFilterFns.selected(colFilter.column, colFilter.selected));
          }
        }
        if (colFilter.empty) {
          filteredData = filteredData.filter(colFilter.invert ?
            this._columnFilterFns.emptyInverse(colFilter.column) :
            this._columnFilterFns.empty(colFilter.column));
        } else if (colFilter.value && colFilter.toValue) {
          if (colFilter.column.dataType === VsTableColumnDataType.Date) {
            filteredData = filteredData.filter(colFilter.invert ?
              this._columnFilterFns.dateRangeInverse(colFilter.column, colFilter.value as string, colFilter.toValue as string) :
              this._columnFilterFns.dateRange(colFilter.column, colFilter.value as string, colFilter.toValue as string));
          } else {
            filteredData = filteredData.filter(colFilter.invert ?
              this._columnFilterFns.numberRangeInverse(colFilter.column, colFilter.value as number, colFilter.toValue as number) :
              this._columnFilterFns.numberRange(colFilter.column, colFilter.value as number, colFilter.toValue as number));
          }
        } else if (colFilter.toValue) {
          if (colFilter.column.dataType === VsTableColumnDataType.Date) {
            filteredData = filteredData.filter(colFilter.invert ?
              this._columnFilterFns.allToDateInverse(colFilter.column, colFilter.toValue as string) :
              this._columnFilterFns.allToDate(colFilter.column, colFilter.toValue as string));
          } else {
            filteredData = filteredData.filter(colFilter.invert ?
              this._columnFilterFns.allToNumberInverse(colFilter.column, colFilter.toValue as number) :
              this._columnFilterFns.allToNumber(colFilter.column, colFilter.toValue as number));
          }
        } else if (colFilter.value) {
          const strVal = colFilter.value.toString().toLowerCase();
          const isChanges = colFilter.column.dataType === VsTableColumnDataType.Changes;
          const isNumber = colFilter.column.dataType === VsTableColumnDataType.Number || colFilter.column.dataType === VsTableColumnDataType.Currency;
          const filterFn = isChanges ? this._columnFilterFns.changes(colFilter.column, strVal) :
            isNumber ? this._columnFilterFns.number(colFilter.column, colFilter.value as number) :
              this._columnFilterFns.default(colFilter.column, strVal);
          const inverseFilterFn = isChanges ? this._columnFilterFns.changesInverse(colFilter.column, strVal) :
            isNumber ? this._columnFilterFns.numberInverse(colFilter.column, colFilter.value as number) :
              this._columnFilterFns.defaultInverse(colFilter.column, strVal);
          filteredData = filteredData.filter(colFilter.invert ? inverseFilterFn : filterFn);
        }
      });
    }

    this.filteredData = filteredData;

    return filteredData;
  }

  clearFilters() {
    this._columnFilters = {};
    this.filter = '';
  }

  isFiltered() {
    return this.filteredData.length < this.data.length;
  }
}
