import {ChangeDetectionStrategy, Component, inject, Injector, Input} from '@angular/core';
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {
  ChangesColumn,
  DateColumn,
  NumberColumn,
  TextColumn,
  VsTableColumn,
  VsTableMenuItem,
  VsTableModule
} from "vs-table";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {Employee} from "../../core/interface/employees.interface";
import {EmployeeEvent} from "../../core/interface/events.interface";
import {AddEventComponent, AddEventData} from "../add-event/add-event.component";
import {MatDialog} from "@angular/material/dialog";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SidenavStackService} from "sidenav-stack";
import {ViewEmailComponent} from "../email/components/view-email/view-email.component";

@Component({
  selector: 'lib-employee-events',
  standalone: true,
  imports: [
    DatePipe,
    NgIf,
    NgForOf,
    VsTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './employee-events.component.html',
  styleUrls: ['./employee-events.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeEventsComponent {
  private dialog = inject(MatDialog);
  private injector = inject(Injector);
  private sidenavService = inject(SidenavStackService);
  @Input({required: true}) employee!: Employee;
  columns: VsTableColumn<EmployeeEvent>[] = [
    new NumberColumn({
      title: 'Code',
      field: 'code',
      format: '1.0-0',
    }),
    new TextColumn({
      title: 'Event',
      field: 'description',
    }),
    new TextColumn({
      title: 'Message',
      field: 'message',
      expandable: true,
    }),
    new DateColumn({
      title: 'Date',
      field: 'whenCreated',
      format: 'medium',
    }),
    new ChangesColumn({
      title: 'Changes',
      field: 'changes',
    }),
  ];
  shortcuts: AddEventShortcut[] = [
    {
      name: 'Call Made',
      code: 322,
      eventTypes: 'portal',
    },
    {
      name: 'Email Sent',
      code: 302,
      eventTypes: 'email',
    },
  ];
  rowMenuItems: VsTableMenuItem<EmployeeEvent>[] = [
    {
      name: () => 'View Details',
      callback: (row) => this.sidenavService.open('Archived Email', ViewEmailComponent, {id: row.relatedId}),
      disable: (row) => !row.relatedId,
    }
  ];

  addEvent(shortcut?: AddEventShortcut): void {
    const data: AddEventData = { employee: this.employee, eventTypes: shortcut ? shortcut.eventTypes : 'portal' };
    if (shortcut) {
      data.model = { code: shortcut.code };
    }
    this.dialog.open(AddEventComponent, { data, injector: this.injector });
  }
}

interface AddEventShortcut {
  name: string;
  code: number;
  eventTypes: 'portal' | 'email';
}
