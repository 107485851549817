import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable()
export class PrefixerInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.startsWith('/') ? req.url.slice(1) : req.url;
    const api = 'api';
    if (url.startsWith(api)) {
      const prefixed = url.replace(api, environment.apiUrl);
      return next.handle(req.clone({url: prefixed}));
    }
    return next.handle(req);
  }
}
