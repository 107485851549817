import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldOptions, FieldType} from "../types";
import {FormGroup} from "@angular/forms";
import {KeyValue} from "@angular/common";

// Control value for a SelectField will be an array if multiple = true
type SelectValue = (string | number) | (string[] | number[]);

export class SelectField extends BaseField<SelectValue> {
  type = FieldType.Select;
  options: FieldOptions;
  multiple: boolean;
  disableSelectAll: boolean;
  deselect: boolean;
  optionFilter: SelectOptionsFilter | undefined;
  optionColor: ((option: any, form: FormGroup) => string) | undefined;
  constructor(config: SelectFieldConfig) {
    super(config);
    this.options = config.options;
    this.multiple = !!config.multiple;
    this.disableSelectAll = !!config.disableSelectAll;
    this.deselect = !!config.deselect;
    this.optionFilter = config.optionFilter;
    this.optionColor = config.optionColor;
  }
}

export interface SelectFieldConfig extends BaseFieldConfig<SelectValue> {
  options: FieldOptions;
  multiple?: boolean;
  disableSelectAll?: boolean;
  deselect?: boolean;
  optionFilter?: SelectOptionsFilter;
  optionColor?: (option: KeyValue<string | number | null, any>, form: FormGroup) => string;
}

export interface SelectOptionsFilter {
  label: string;
  onChange: (checked: boolean) => void;
}
