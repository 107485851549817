import {
  ComponentRef,
  Directive,
  inject,
  Input,
  OnInit,
  Type,
  ViewContainerRef
} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {BaseFieldComponent} from "../component/base-field.component";
import {InputFieldComponent} from "../component/input-field/input-field.component";
import {DateFieldComponent} from "../component/date-field/date-field.component";
import {DynamicFormField, FieldType} from "../types";
import {DateRangeFieldComponent} from "../component/date-range-field/date-range-field.component";
import {CurrencyFieldComponent} from "../component/currency-field/currency-field.component";
import {AutocompleteFieldComponent} from "../component/autocomplete-field/autocomplete-field.component";
import {CheckboxFieldComponent} from "../component/checkbox-field/checkbox-field.component";
import {RadioFieldComponent} from "../component/radio-field/radio-field.component";
import {SelectFieldComponent} from "../component/select-field/select-field.component";
import {NumberRangeFieldComponent} from "../component/number-range-field/number-range-field.component";
import {TextareaFieldComponent} from "../component/textarea-field/textarea-field.component";
import {PhoneNumberFieldComponent} from "../component/phone-number-field/phone-number-field.component";

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements OnInit {
  @Input({required: true}) config!: DynamicFormField;
  @Input({required: true}) form!: FormGroup;

  private static readonly COMPONENT_MAP: {[key in FieldType]: Type<BaseFieldComponent<any>>} = {
    [FieldType.Input]: InputFieldComponent,
    [FieldType.Date]: DateFieldComponent,
    [FieldType.DateRange]: DateRangeFieldComponent,
    [FieldType.Currency]: CurrencyFieldComponent,
    [FieldType.Autocomplete]: AutocompleteFieldComponent,
    [FieldType.Checkbox]: CheckboxFieldComponent,
    [FieldType.Radio]: RadioFieldComponent,
    [FieldType.Select]: SelectFieldComponent,
    [FieldType.NumberRange]: NumberRangeFieldComponent,
    [FieldType.Textarea]: TextareaFieldComponent,
    [FieldType.PhoneNumber]: PhoneNumberFieldComponent,
  };
  private container = inject(ViewContainerRef);
  private componentRef!: ComponentRef<BaseFieldComponent<any>>;

  ngOnInit(): void {
    this.componentRef = this.container.createComponent(DynamicFieldDirective.COMPONENT_MAP[this.config.type]);
    this.componentRef.instance.config = this.config;
    this.componentRef.instance.form = this.form;
  }
}
