import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Store} from "@ngrx/store";
import {AsyncPipe, KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {RecordPipe, StartCasePipe} from "caig-utils";
import {MatButtonModule} from "@angular/material/button";
import {selectUser} from "../../core/store/core/core.selectors";
import {SessionUser} from "../../core/interface/session.interface";
import {
  ChangePasswordComponent,
  ChangePasswordData
} from "../../core/component/change-password/change-password.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    StartCasePipe,
    RecordPipe,
    AsyncPipe,
    KeyValuePipe,
  ],
})
export class UserProfileComponent {
  private store = inject(Store);
  private dialog = inject(MatDialog);
  user$ = this.store.select(selectUser);
  compare(): number { return 0 }
  changePassword(user: SessionUser): void {
    const data: ChangePasswordData = { userId: user.id, self: true };
    this.dialog.open(ChangePasswordComponent, { data });
  }
}
