<mat-card *ngIf="user$ | async as user">
  <mat-card-header>
    <mat-card-title>My Profile</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let prop of user | record | keyvalue:compare; last as last">
        <div class="flex-column gap-5">
          <div class="flex-row flex-align-spacebetween-center">
            <b>{{prop.key | startCase}}</b>
            <i>{{prop.value}}</i>
          </div>
          <mat-divider *ngIf="!last"></mat-divider>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="changePassword(user)">Change Password</button>
  </mat-card-actions>
</mat-card>
