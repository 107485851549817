import {
  Component, ElementRef,
  HostBinding,
  Input, OnChanges,
  OnDestroy,
  Optional,
  Self, SimpleChanges, ViewChild
} from '@angular/core';
import {formatCurrency} from '@angular/common';
import {MatFormFieldControl} from "@angular/material/form-field";
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {Subject} from "rxjs";
import {MatInput, MatInputModule} from "@angular/material/input";

@Component({
  selector: 'currency-input',
  standalone: true,
  imports: [MatInputModule],
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.css'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CurrencyInputComponent,
    },
  ],
})
export class CurrencyInputComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnDestroy, OnChanges {
  static nextId = 0;

  private _value: string = '';
  private _placeholder: string = '';
  private _required: boolean = false;
  private _disabled: boolean = false;
  private _disableNegative: boolean = false;
  private _focused: boolean = false;
  private _touched: boolean = false;

  stateChanges = new Subject<void>();
  autofilled: boolean = false;
  controlType: string = 'currency-input';
  userAriaDescribedBy: string = '';
  prevValue: string = '';
  onChange = (v: any) => {};
  onTouched = () => {};

  @HostBinding() id = `${this.controlType}-${CurrencyInputComponent.nextId++}`;
  @HostBinding('class.floating') get shouldLabelFloat() { return this.focused || !this.empty }

  @ViewChild(MatInput, {read: ElementRef, static: true}) inputEl!: ElementRef;

  @Input()
  get value(): string { return this._value }
  set value(val: any) {
    let numValue = val === '' || val === null ? NaN : Number(val);
    this._value = isNaN(numValue) ? '' : formatCurrency(this.disableNegative ? Math.max(0, numValue) : numValue, 'en-US', '');
    this.stateChanges.next();
  }
  @Input()
  get placeholder(): string { return this._placeholder }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  @Input()
  get required(): boolean { return this._required }
  set required(req: boolean) {
    this._required = req;
    this.stateChanges.next();
  }
  @Input()
  get disabled(): boolean { return this._disabled }
  set disabled(dis: boolean) {
    this._disabled = dis;
    this.stateChanges.next();
  }
  @Input()
  get disableNegative() { return this._disableNegative }
  set disableNegative(value: boolean) { this._disableNegative = value }
  get focused(): boolean { return this._focused }
  set focused(foc: boolean) {
    this._focused = foc;
    this.stateChanges.next();
  }
  get touched(): boolean { return this._touched }
  set touched(touch: boolean) {
    this._touched = touch;
    this.stateChanges.next();
  }

  get empty(): boolean { return !this.value }
  get errorState(): boolean {
    return this.touched && (this.ngControl ? this.ngControl.errors !== null : (this.required && this.empty));
  }

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  onContainerClick(event: MouseEvent): void {
    this.inputEl.nativeElement.focus();
  }
  setDescribedByIds(ids: string[]): void {
    this.inputEl.nativeElement.setAttribute('aria-describedby', ids.join(' '));
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  writeValue(obj: any): void {
    this.value = obj;
  }
  ngOnDestroy() {
    this.stateChanges.complete();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['disableNegative']?.currentValue) {
      const value = this.value;
      const parsedValue = value ? parseValue(value) : 0;
      if (Number(parsedValue) < 0) {
        this.value = parsedValue;
      }
    }
  }
  onKeyup(event: KeyboardEvent): void {
    if (event.key === 'Control' || event.ctrlKey) {
      return;
    }

    const input = this.inputEl.nativeElement;
    if (input.value === this.prevValue) {
      return;
    }

    let selectionStart = input.selectionStart || 0;
    if (event.key === 'Delete' && this.value && this.value[selectionStart] === ',') {
      selectionStart++;
    }

    const parsedValue = parseValue(input.value);
    if (parsedValue !== '-') {
      this.value = parsedValue;
      input.value = this.value;
      if (input.value) {
        const caretPosition = Math.max(0, selectionStart + getCommas(input.value).length - (this.prevValue ? getCommas(this.prevValue).length : 0));
        input.setSelectionRange(caretPosition, caretPosition);
      }
      const emittedValue = input.value ? Number(parsedValue) : null;
      this.onChange(emittedValue);
      this.prevValue = input.value;
    }
  }
}

function parseValue(value: string): string {
  const numValue = value.replace(/,/g, '');
  const split = numValue.split('.');
  return split[1] ? `${split[0]}.${split[1].substring(0, 2)}` : split[0];
}

function getCommas(value: string): string {
  return value.replace(/[^,]+/g, '');
}
