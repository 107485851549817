import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {Employee} from "../../core/interface/employees.interface";
import {EmployeeEvent} from "../../core/interface/events.interface";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {NgIf} from "@angular/common";
import {
  BaseField,
  CheckboxField,
  DateField,
  DynamicFormModule,
  InputField,
  SelectField,
  TextareaField
} from "dynamic-form";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {EnumsActions} from "../../core/store/enums/action-types";
import {collectionToKeyValue$, zeroPad} from "caig-utils";
import moment from "moment";
import {EventsDataService} from "../../events/service/events-data.service";
import {filter, interval, map, Subject, takeUntil} from "rxjs";
import {selectEventTypesEmail, selectEventTypesPortal} from "../../core/store/enums/enums.selectors";

@Component({
  selector: 'lib-add-event',
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatProgressBarModule,
    DynamicFormModule,
  ],
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEventComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private eventService = inject(EventsDataService);
  private dialogRef = inject(MatDialogRef);
  data: AddEventData = inject(MAT_DIALOG_DATA);

  private onDestroy$ = new Subject<void>();

  form: FormGroup = new FormGroup({});
  fields!: BaseField<any>[][];
  model: AddEventModel | undefined;

  ngOnInit() {
    const today = moment();

    const enumType = this.data.eventTypes === 'portal' ? 'eventTypesPortal' : 'eventTypesEmail';
    const selector = this.data.eventTypes === 'portal' ? selectEventTypesPortal : selectEventTypesEmail;

    this.store.dispatch(EnumsActions.loadEnum({enumType}));

    this.fields = [
      [
        new SelectField({
          name: 'code',
          label: 'Event',
          options: collectionToKeyValue$(this.store.select(selector), 'description', 'code'),
          required: true,
          disabled: this.data.model?.code === 322,
        })
      ],
      [
        new TextareaField({
          name: 'message',
          label: 'Message',
          required: true,
          validators: [Validators.maxLength(255)],
          hint: { message: '255 chars max', align: 'start' },
        })
      ],
      [
        new CheckboxField({
          name: 'now',
          label: 'Now',
          defaultValue: true,
          onChange: (value, form) => {
            if (value) {
              form.controls['date'].disable();
              form.controls['time'].disable();
            } else {
              form.controls['date'].enable();
              form.controls['time'].enable();
            }
          }
        }),
        new DateField({
          name: 'date',
          label: 'Date',
          required: true,
          defaultValue: today.format('YYYY-MM-DD'),
          disabled: true,
        }),
        new InputField({
          inputType: 'time',
          label: 'Time',
          name: 'time',
          defaultValue: today.format('HH:mm'),
          required: true,
          disabled: true,
        })
      ]
    ];

    if (this.data.model) {
      const modelDate = this.data.model.whenCreated ? moment(this.data.model.whenCreated) : today;
      this.model = {
        code: this.data.model.code,
        message: this.data.model.message,
        date: modelDate,
        time: modelDate.format('HH:mm'),
        now: true,
      };
    }

    interval(1000).pipe(
      filter(() => !!this.form.value.now),
      map(() => moment()),
      takeUntil(this.onDestroy$)
    )
      .subscribe((now) => this.form.patchValue({date: now, time: now.format('HH:mm')}));
  }

  ngOnDestroy() {
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }

  save() {
    const now = moment();
    const utcOffset = now.utcOffset();
    const formValue: any = this.form.getRawValue();
    const payload: Partial<EmployeeEvent> = {
      code: formValue.code,
      message: formValue.message,
      whenCreated: formValue.now ? undefined : `${moment(formValue.date).format('YYYY-MM-DD')}T${formValue.time}:00${utcOffset < 0 ? '' : '+'}${zeroPad(Math.floor(utcOffset / 60), 2) + ':' + zeroPad(utcOffset % 60, 2)}`,
    };
    this.form.disable();
    this.eventService.addForEmployee(this.data.employee, payload).subscribe(
      () => this.dialogRef.close(true),
      () => this.form.enable()
    );
  }
}

export interface AddEventData {
  employee: Employee;
  eventTypes: 'portal' | 'email';
  model?: Partial<EmployeeEvent>;
}

export interface AddEventModel {
  code?: number;
  message?: string;
  date?: moment.Moment;
  time?: string;
  now?: boolean;
}
