import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass, NgForOf} from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {FormGroup} from "@angular/forms";
import {PasswordService} from "../../service/password.service";

@Component({
  selector: 'lib-password-requirements',
  standalone: true,
  imports: [
      NgForOf,
      NgClass,
      MatIconModule,
  ],
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.css']
})
export class PasswordRequirementsComponent {
  @Input() public form!: FormGroup;
  @Output() public isValid = new EventEmitter<boolean>(true);
  public checks = this.passwordService.passwordRequirements;
  constructor(private passwordService: PasswordService) { }
  public ngOnInit() {
    this.form.valueChanges.subscribe((value) =>
        this.isValid.emit(this.passwordService.isValidPassword(value.password, value.confirmPassword, this.checks)));
  }
}
