import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Employee} from "../../core/interface/employees.interface";
import {MatGridListModule} from "@angular/material/grid-list";
import {CurrencyPipe, KeyValue, NgForOf, NgIf} from "@angular/common";
import {isNumber} from "lodash";

@Component({
  selector: 'lib-employee-amounts',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    CurrencyPipe,
    MatGridListModule,
  ],
  templateUrl: './employee-amounts.component.html',
  styleUrls: ['./employee-amounts.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeAmountsComponent {
  @Input({required: true}) employee!: Employee;

  props: KeyValue<keyof Employee, string>[] = [
    {
      key: 'spotBp',
      value: 'SPOT BP',
    },
    {
      key: 'ctotBp',
      value: 'CTOT BP',
    },
    {
      key: 'spotLd',
      value: 'SPOT LD',
    },
    {
      key: 'ctotLd',
      value: 'CTOT LD',
    },
    {
      key: 'estCostShare',
      value: 'Cost Share',
    },
    {
      key: 'estFedWh',
      value: 'Federal Wh'
    },
    {
      key: 'fedAddlamt',
      value: 'Federal+',
    },
    {
      key: 'estEmployerSs',
      value: 'Employer Ss',
    },
    {
      key: 'estEmployeeSs',
      value: 'Employee Ss',
    },
    {
      key: 'estEmployerMc',
      value: 'Employer Mc',
    },
    {
      key: 'estEmployeeMc',
      value: 'Employee Mc',
    },
    {
      key: 'estStateWh',
      value: 'State Wh',
    },
    {
      key: 'stateAddlamt',
      value: 'State+',
    },
    {
      key: 'addlamt',
      value: 'Other+'
    },
    {
      key: 'donation',
      value: 'Donation',
    },
    {
      key: 'estTotal',
      value: 'Total',
    }
  ];

  toNumber(value: any): number {
    return isNumber(value) ? value : 0;
  }
}
