import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldType} from "../types";
import {NumberRange} from "../interfaces";

export class NumberRangeField extends BaseField<NumberRange> {
  type = FieldType.NumberRange;

  constructor(config: NumberRangeFieldConfig) {
    super(config);
  }
}

export interface NumberRangeFieldConfig extends BaseFieldConfig<NumberRange> {

}
