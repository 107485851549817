import {BadgeConfig, ColumnAlign, VsTableColumn, VsTableColumnConfig, VsTableColumnDataType} from "../types";
import {ThemePalette} from "@angular/material/core";

export abstract class BaseColumn<T> {
  public field: Extract<keyof T, string>;
  public title: string;
  public hide: boolean;
  public sort: boolean;
  public align: ColumnAlign;
  public sticky: 'start' | 'end' | undefined;
  public color: ((row: T) => string) | undefined;
  public backgroundColor: ((row: T) => string) | undefined;
  public tooltip: ((row: T) => string) | undefined;
  public badge: BadgeConfig<T> | undefined;
  public styles: {[key: string]: string | number} | undefined;
  public expandable: boolean;
  public draggable: boolean;
  public filter: boolean;
  public onClick: ((value: any, row: T, column: VsTableColumn<T>) => void) | undefined;
  public displayTitle: boolean;
  public theme: ThemePalette;

  public abstract dataType: VsTableColumnDataType;

  constructor(config: VsTableColumnConfig<T>) {
    this.field = config.field;
    this.title = config.title;
    this.hide = !!config.hide;
    this.sort = config.sort !== false;
    this.align = config.align || 'start';
    this.sticky = config.sticky;
    this.color = config.color;
    this.tooltip = config.tooltip;
    this.badge = config.badge;
    this.styles = config.styles;
    this.backgroundColor = config.backgroundColor;
    this.expandable = !!config.expandable;
    this.draggable = config.draggable !== false && !config.sticky;
    this.filter = config.filter !== false;
    this.onClick = config.onClick;
    this.displayTitle = config.displayTitle !== false;
    this.theme = config.theme;
  }
}
