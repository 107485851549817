import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {combineLatest, first, firstValueFrom} from "rxjs";
import {Params} from "@angular/router";
import {inject} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "notification";

export abstract class EntityStoreBaseService<T> extends EntityCollectionServiceBase<T> {
  protected http = inject(HttpClient);
  protected notification = inject(NotificationService);
  constructor(entityName: string, serviceFactory: EntityCollectionServiceElementsFactory) {
    super(entityName, serviceFactory);
  }

  loadIfNotLoadedOrLoading(queryParams?: Params) {
    combineLatest([this.loaded$, this.loading$])
      .pipe(first())
      .subscribe(([loaded, loading]) => {
        if (!loaded && !loading && (!queryParams || !Object.keys(queryParams).length)) {
          this.clearCache();
          this.getAll();
        }
      });
  }

  async patch(entityId: string | number, payload: Partial<T>, self?: boolean): Promise<void> {
      this.setLoading(true);
      const url = `/api/${this.entityName}/${self ? 'self' : entityId}`;
      try {
          const patched = await firstValueFrom(this.http.patch<T>(url, payload));
          this.upsertOneInCache({...patched, id: entityId});
      }
      finally {
          this.setLoading(false);
      }
  }

  bulkPatch(payload: {ids: (number | string)[], value: Partial<T>}) {
    return this.http.patch<void>('/api/employee/bulk', payload);
  }
}
