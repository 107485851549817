import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DatePipe, NgIf} from "@angular/common";
import {Employee} from "../../core/interface/employees.interface";
import {DateColumn, TextColumn, VsTableColumn, VsTableModule} from "vs-table";
import {EmployeeEvent} from "../../core/interface/events.interface";

@Component({
  selector: 'lib-employee-contact',
  standalone: true,
  imports: [
    NgIf,
    DatePipe,
    VsTableModule,
  ],
  templateUrl: './employee-contact.component.html',
  styleUrls: ['./employee-contact.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeContactComponent {
  @Input({required: true}) employee!: Employee;
  columns: VsTableColumn<EmployeeEvent>[] = [
    new DateColumn({
      field: 'whenCreated',
      title: 'Date',
      format: 'medium',
    }),
    new TextColumn({
      field: 'description',
      title: 'Description',
    }),
    new TextColumn({
      field: 'user',
      title: 'User',
    }),
    new TextColumn({
      field: 'message',
      title: 'Message',
      expandable: true,
    })
  ];
}
