import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {VsTableColumn, VsTableColumnDataType} from "../types";
import {VsTableColumnFilter} from "../column-filter";
import {FormGroup} from "@angular/forms";
import {BaseField, DateRangeField, DynamicFormComponent, InputField, NumberRangeField} from "dynamic-form";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {ThemePalette} from "@angular/material/core";
import {SelectionModel} from "@angular/cdk/collections";
import {calcProp, focusFirstMatFormField} from "../util";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {isEqual, pick} from "lodash";
import {SimpleChangesTyped} from "caig-utils";
import {formatCurrency, formatDate, formatNumber} from "@angular/common";
import {MAT_MENU_SCROLL_STRATEGY} from "@angular/material/menu";
import {Overlay} from "@angular/cdk/overlay";

@Component({
  selector: 'vs-table-column-filter',
  templateUrl: './vs-table-column-filter.component.html',
  styleUrls: ['./vs-table-column-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      deps: [Overlay],
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.noop();
      }
    }
  ],
})
export class VsTableColumnFilterComponent<T> implements OnInit, OnChanges {
  @Input({required: true}) column!: VsTableColumn<T>;
  @Input({required: true}) filteredData!: T[];
  @Input({required: true}) data!: T[];
  @Input({required: true}) hide: boolean = true;
  @Input({required: true}) activeFilter: VsTableColumnFilter<T> | undefined;

  @Output() public filterChange = new EventEmitter<VsTableColumnFilter<T>>();

  @ViewChild(DynamicFormComponent, {read: ElementRef}) public dynamicFormRef!: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) public viewport!: CdkVirtualScrollViewport;

  readonly columnTypes = VsTableColumnDataType;
  readonly filterForm = new FormGroup({});
  readonly rowHeight = 28;

  filter!: VsTableColumnFilter<T>;
  selection!: SelectionModel<string>;

  dateRangeFields!: BaseField<any>[][];
  textFields!: BaseField<any>[][];
  numberFields!: BaseField<any>[][];

  emptyChecked: boolean = false;
  invertChecked: boolean = false;
  filterOptionsChecked: boolean = false;

  uniqueColumnValues: string[] = [];
  columnValueIndex: number = 0;

  get showViewport(): boolean {
    return [VsTableColumnDataType.Text, VsTableColumnDataType.Calculate, VsTableColumnDataType.Changes]
      .indexOf(this.column.dataType as VsTableColumnDataType) > -1;
  }
  get showOptions(): boolean {
    return this.column.dataType === VsTableColumnDataType.Icon || this.showViewport;
  }

  ngOnInit() {
    const baseConfig = {
      name: 'colFilter',
      appearance: 'outline' as MatFormFieldAppearance,
      color: 'accent' as ThemePalette,
      label: this.column.title,
      defaultValue: this.activeFilter?.value as any,
      disabled: !!this.activeFilter?.empty,
    };
    this.dateRangeFields = [
      [
        new DateRangeField({
          ...baseConfig,
          flex: 100,
          onChange: (range) => this.emitChange({value: range.start, toValue: range.end}),
        })
      ]
    ];
    this.textFields = [
      [
        new InputField({
          ...baseConfig,
          placeholder: `Filter by ${this.column.title}`,
          onChange: (value) => this.emitChange({value})
        }),
      ]
    ];
    this.numberFields = [
      [
        new NumberRangeField({
          ...baseConfig,
          onChange: (range) => this.emitChange({value: range.start, toValue: range.end}),
        })
      ]
    ];
  }

  ngOnChanges(changes: SimpleChangesTyped<this>) {
    if (changes.activeFilter) {
      if (!changes.activeFilter.firstChange && !changes.activeFilter.currentValue) {
        this.resetFilter(false);
      } else {
        this.filter = this.activeFilter || new VsTableColumnFilter<T>(this.column, false, false, false, []);
        this.emptyChecked = this.filter.empty;
        this.invertChecked = this.filter.invert;
        this.filterOptionsChecked = this.filter.filterSelected;
        this.selection = new SelectionModel(true, this.filter.selected);
      }
    }
    if (this.showOptions && changes.filteredData) {
      this.filterOptions();
    }
  }

  filterOpened() {
    if (this.dynamicFormRef) {
      focusFirstMatFormField(this.dynamicFormRef.nativeElement);
    }
    if (this.viewport) {
      this.viewport.checkViewportSize();
      this.viewport.scrollToIndex(this.columnValueIndex, 'smooth');
    }
  }

  resetFilter(emitEvent: boolean): void {
    this.invertChecked = false;
    this.emptyChecked = false;
    this.filterOptionsChecked = false;
    this.selection.clear();
    this.filterForm.reset({}, {emitEvent: false});
    this.filterForm.enable({emitEvent: false});
    this.filter = new VsTableColumnFilter<T>(this.column, false, false, false, []);
    if (emitEvent) {
      this.filterChange.emit(this.filter);
    }
  }

  toggleEmpty(checked: boolean) {
    if (checked) {
      this.filterForm.disable();
    } else {
      this.filterForm.enable();
    }
    this.emptyChecked = checked;
    this.emitChange();
  }

  toggleFilterOptions(checked: boolean) {
    this.filterOptionsChecked = checked;
    this.filterOptions();
  }

  emitChange(updateValue?: { value?: string | number | null, toValue?: string | number | null}) {
    const columnFilter = {...this.filter};
    const compareFields = ['empty', 'selected', 'value', 'toValue'];
    this.filter = new VsTableColumnFilter<T>(
      this.column,
      this.invertChecked,
      this.emptyChecked,
      this.filterOptionsChecked,
      this.selection.selected,
      updateValue ? updateValue.value : columnFilter?.value,
      updateValue ? updateValue.toValue : columnFilter?.toValue,
    );
    if (this.filter.isActive) {
      compareFields.push('invert');
    }
    const prev = pick(columnFilter, compareFields);
    const curr = pick(this.filter, compareFields);
    if (!isEqual(prev, curr)) {
      this.filterChange.emit(this.filter);
    }
  }

  private filterOptions() {
    const data = this.filterOptionsChecked ? this.filteredData : this.data;
    const set = new Set<string>();
    data.forEach((item) => {
      const val = item[this.column.field] as any;
      if (this.column.dataType === VsTableColumnDataType.Changes && val instanceof Array) {
        val.forEach((change) => set.add(change.field));
      } else if (this.column.calculate) {
        set.add((item as any)[calcProp(this.column)])
      } else if (val && val.toString) {
        set.add(this.formatValue(val.toString()));
      }
    });
    this.uniqueColumnValues = Array.from(set);
  }

  private formatValue(value: string): string {
    switch (this.column.dataType) {
      case VsTableColumnDataType.Date:
        return formatDate(value, this.column.format as string, 'en-us');
      case VsTableColumnDataType.Number:
        return formatNumber(Number(value), 'en-us', this.column.format as string);
      case VsTableColumnDataType.Currency:
        return formatCurrency(Number(value), 'en-us', '$');
      default:
        return value;
    }
  }
}
