import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {
  CalculateColumn,
  DateColumn,
  IconColumn,
  TextColumn,
  VsTableColumn,
  VsTableMenuItem,
  VsTableModule
} from "vs-table";
import {Employee, SurveyResponse} from "../../core/interface/employees.interface";
import {MatDialog} from "@angular/material/dialog";
import {filter, switchMap} from "rxjs";
import {isTruthy} from "caig-utils";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {ViewAttachmentsComponent} from "../view-attachments/view-attachments.component";
import {AttachFilesComponent} from "../attach-files/attach-files.component";

@Component({
  selector: 'lib-employee-surveys',
  standalone: true,
  imports: [
    VsTableModule,
  ],
  templateUrl: './employee-surveys.component.html',
  styleUrls: ['./employee-surveys.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeSurveysComponent {
  private dialog = inject(MatDialog);
  private dataService = inject(EmployeeEntityService);
  @Input({required: true}) employee!: Employee;
  columns: VsTableColumn<SurveyResponse>[] = [
    new TextColumn({
      field: 'surveyName',
      title: 'Survey',
    }),
    new CalculateColumn({
      field: 'progressMeter',
      title: 'Progress',
      calculate: (row) => `${row.progressMeter.toFixed(2)}%`
    }),
    new DateColumn({
      field: 'latestProgress',
      title: 'Last Updated',
    }),
    new TextColumn({
      field: 'notes',
      title: 'Notes',
    }),
    new IconColumn({
      field: 'attachedFiles',
      title: 'Files',
      calculate: (row) => row.attachedFiles && row.attachedFiles.length > 0 ? 'file_present' : '',
      badge: { value: (row) => row.attachedFiles?.length },
    }),
  ];
  options: VsTableMenuItem<SurveyResponse>[] = [
    {
      name: () => 'Attach File(s)',
      callback: (data) => this.dialog.open(AttachFilesComponent, {data})
        .afterClosed()
        .pipe(
          filter(isTruthy),
          switchMap(() => this.dataService.getByKey(this.employee.id))
        )
        .subscribe(),
    },
    {
      name: (row) => `View ${row.attachedFiles?.length} Attached File${row.attachedFiles?.length === 1 ? '' : 's'}`,
      callback: (data) => this.dialog.open(ViewAttachmentsComponent, {data: {...data, name: data.surveyName}}),
      hide: (row) => !row.attachedFiles?.length
    }
  ];
}
