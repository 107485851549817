import {BaseColumn} from './base-column';
import {VsTableColumnConfig, VsTableColumnDataType} from "../types";

export class ChangesColumn<T> extends BaseColumn<T> {
  public dataType = VsTableColumnDataType.Changes;
  public oldValueField: string;
  public newValueField: string;
  public changedField: string;
  constructor(config: VsTableColumnConfig<T>) {
    super(config);
    this.expandable = config.expandable !== false;
    this.changedField = config.changedField || 'field';
    this.oldValueField = config.oldValueField || 'oldValue';
    this.newValueField = config.newValueField || 'newValue';
  }
}
