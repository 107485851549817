import { NgModule } from '@angular/core';
import {EventsRoutingModule} from "./events-routing.module";
import {EventsListComponent} from "./events-list/events-list.component";

@NgModule({
  imports: [
    EventsRoutingModule,
    EventsListComponent,
  ],
})
export class EventsModule { }
