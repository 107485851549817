<div class="flex h-full flex-col">
  <div class="flex gap-5 text-lg justify-end items-center">
    <button mat-raised-button *ngFor="let shortcut of shortcuts" (click)="addEvent(shortcut)">
      {{shortcut.name}}
    </button>
    <button mat-icon-button color="primary" (click)="addEvent()" matTooltip="Add event"><mat-icon>add_circle</mat-icon></button>
  </div>
  <div class="flex flex-grow">
    <vs-table
      cacheKey="employee-events"
      [data]="employee.events"
      [columns]="columns"
      [rowMenuItems]="rowMenuItems"
      disableToolbar>
    </vs-table>
  </div>
</div>
