import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {VsTableColumn} from "../types";

@Component({
  selector: 'vs-table-currency-cell',
  templateUrl: './currency-cell.component.html',
  styleUrls: ['./currency-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyCellComponent<T> {
  @Input({required: true}) column!: VsTableColumn<T>;
  @Input({required: true}) value!: any;
  @Input({required: true}) compareValue: any | undefined;
}
