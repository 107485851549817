import {Injectable} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DynamicFormField} from "../types";
import {Subject, takeUntil} from "rxjs";
import {flatten} from "lodash";

@Injectable()
export class DynamicFormBuilderService {
  public createForm(onDestroy$: Subject<void>, fields: DynamicFormField[][], mergeFields: boolean, form?: FormGroup): FormGroup {
    const group = form || new FormGroup({});
    const currentControls = Object.keys(group.controls);
    const flattenedFields = flatten(fields);
    const controlNames = flattenedFields.map((config) => config.name);
    const controlsToAdd = flattenedFields.filter((config) => !currentControls.includes(config.name));
    controlsToAdd.forEach((config) => {
      const control = this.createControl(onDestroy$, config, group);
      group.addControl(config.name, control, {emitEvent: false});
    })
    if (!mergeFields) {
      const controlsToRemove = currentControls.filter((name) => !controlNames.includes(name));
      controlsToRemove.forEach((name) => group.removeControl(name));
    }
    return group;
  }

  private createControl(onDestroy$: Subject<void>, config: DynamicFormField, form: FormGroup): FormControl {
    const validators = config.validators || [];
    if (config.required) {
      validators.push(Validators.required);
    }
    const ctrl = new FormControl<any>({
      value: config.defaultValue,
      disabled: config.disabled
    }, validators);
    const onChange = config.onChange as ((v: any, f: FormGroup) => void);
    if (onChange) {
      ctrl.valueChanges
        .pipe(takeUntil(onDestroy$))
        .subscribe((value) => onChange(value, form));
    }
    return ctrl;
  }
}
