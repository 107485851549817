<h2 mat-dialog-title>Add Event</h2>
<mat-dialog-content class="mat-typography">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Phones for reference</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-list *ngIf="data.employee.phone || data.employee.phoneWork || data.employee.phoneCell; else noPhones">
        <mat-list-item *ngIf="data.employee.phone as p"> Home: {{p}} </mat-list-item>
        <mat-list-item *ngIf="data.employee.phoneWork as pw"> Work: {{pw}} <span *ngIf="data.employee.phoneWorkExt as x">x {{x}}</span> </mat-list-item>
        <mat-list-item *ngIf="data.employee.phoneCell as pc"> Cell: {{pc}} </mat-list-item>
      </mat-list>
      <ng-template #noPhones>
        <h3>No phone numbers found.</h3>
      </ng-template>
      <dynamic-form
        [form]="form"
        [fields]="fields"
        [model]="model"></dynamic-form>
      <mat-progress-bar *ngIf="form.disabled" mode="indeterminate" color="primary"></mat-progress-bar>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" (click)="save()" [disabled]="form.invalid">Submit</button>
</mat-dialog-actions>
