import {Injectable, signal, WritableSignal} from "@angular/core";
import {EntityActionOptions, EntityCollectionServiceElementsFactory, QueryParams} from "@ngrx/data";
import {EntityStoreBaseService} from "./entity-store-base.service";
import {Employee} from "../../interface/employees.interface";
import {map, Observable, tap} from "rxjs";

@Injectable({providedIn: 'root'})
export class EmployeeEntityService extends EntityStoreBaseService<Employee> {
  queriedEntities: WritableSignal<Employee[]> = signal([]);

  constructor(serviceFactory: EntityCollectionServiceElementsFactory) {
    super('Employee', serviceFactory);
  }

  override getWithQuery(queryParams: QueryParams | string, options?: EntityActionOptions): Observable<Employee[]> {
    return super.getWithQuery(queryParams, options).pipe(
      tap((queried) => this.queriedEntities.set(queried)),
    );
  }

  decrypt(employeeId: number, property: string): Observable<{[property: string]: string}> {
    return this.http.get<{[property: string]: string}>(`/api/employee/${employeeId}/${property}`);
  }

  createBatch(employeeIds: number[]): Observable<{ batchId: string }> {
    return this.http.post<{batchId: string}>('api/employee/batch', employeeIds);
  }

  getBatch(batchId: string): Observable<Employee[]> {
    return this.http.get<Employee[]>(`api/employee/batch/${batchId}`);
  }

  getSignedReleaseUrl(signedReleaseId: string) {
    return this.http.get(`/api/employee/signedRelease/${signedReleaseId}`, { responseType: 'blob' })
      .pipe(map((blob) => URL.createObjectURL(blob)));
  }
}
