<div class="flex h-full flex-col">
  <div class="flex justify-between items-center p-4">
    <a mat-raised-button color="primary" routerLink="create">Create User</a>
    <mat-slide-toggle (change)="inactive$.next($event.checked)">Show Inactive</mat-slide-toggle>
  </div>
  <div class="flex flex-grow">
    <vs-table [data]="users$ | async"
              [columns]="tableColumns"
              enableRowClick
              (rowClick)="editUser($event.row)"
              [rowMenuItems]="rowMenu"
              cacheKey="users-list"
              [isLoading]="dataService.loading$ | async"></vs-table>
  </div>
</div>
