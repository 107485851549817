import {BaseColumn} from './base-column';
import {VsTableColumnConfig, VsTableColumnDataType} from "../types";

export class NumberColumn<T> extends BaseColumn<T> {
  public sum: boolean;
  public format: string;
  public dataType = VsTableColumnDataType.Number;
  public negateValue: boolean;
  constructor(config: VsTableColumnConfig<T>) {
    super(config);
    this.sum = !!config.sum;
    this.format = config.format || '1.2-2';
    this.align = config.align || 'end';
    this.negateValue = !!config.negateValue;
  }
}
