import {ResolveFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {catchError, throwError} from "rxjs";
import {Employee} from "../../../core/interface/employees.interface";
import {EmployeeEntityService} from "../../../core/service/entity-data/employee-entity.service";

export const batchResolver: ResolveFn<Employee[]> = (route, state) => {
  const dataService = inject(EmployeeEntityService);
  const router = inject(Router);
  return dataService.getBatch(route.params['batchId']).pipe(
    catchError((err) => {
      router.navigateByUrl('/employees');
      return throwError(err);
    })
  )
}
