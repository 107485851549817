<div [ngSwitch]="column.dataType"
     (click)="onCellClick($event)"
     [matTooltip]="column.tooltip ? column.tooltip(row) : ''"
     [class.expandable]="!isExpanded && column.expandable"
     [style.text-decoration]="column.onClick ? 'underline' : null"
     [style.background-color]="column.backgroundColor ? column.backgroundColor(row) : null"
     [style.color]="column.color ? column.color(row) : null"
     [style.cursor]="!isExpanded && column.expandable && value ? 'zoom-in' : column.onClick && column.dataType !== columnTypes.Button ? 'pointer' : null"
     class="flex justify-{{column.align}} items-center">
  <div [style.max-height.px]="isExpanded ? null : rowHeight">
    <vs-table-currency-cell *ngSwitchCase="columnTypes.Currency"
                            [column]="column"
                            [value]="value"
                            [compareValue]="column.compareField && row[column.compareField]"
    ></vs-table-currency-cell>
    <div *ngSwitchCase="columnTypes.Date">
      {{value | date:column.format}}
    </div>
    <mat-icon *ngSwitchCase="columnTypes.Icon"
              class="stretch-badge"
              aria-hidden="false"
              [matBadge]="column.badge ? column.badge.value(row) : undefined"
              [matBadgeColor]="column.badge ? column.badge.color : undefined">{{value}}</mat-icon>
    <div *ngSwitchCase="columnTypes.Number">
      {{(column.negateValue ? -value : +value) | number:column.format}}
    </div>
    <vs-table-changes-list *ngSwitchCase="columnTypes.Changes" [changes]="value"></vs-table-changes-list>
    <button mat-raised-button [color]="column.theme" *ngSwitchCase="columnTypes.Button" type="button" (click)="emitOnClick($event)">{{value}}</button>
    <div *ngSwitchDefault>{{value}}</div>
  </div>
</div>
