import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {BaseField, DynamicFormModule, InputField, SelectField} from "dynamic-form";
import {FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {AsyncPipe} from "@angular/common";
import {Store} from "@ngrx/store";
import {EnumsActions} from "../../core/store/enums/action-types";
import {Overlay, OverlayModule} from "@angular/cdk/overlay";
import {MAT_SELECT_SCROLL_STRATEGY} from "@angular/material/select";
import {collectionToKeyValue$, isTruthy} from "caig-utils";
import {
  selectBueLocals, selectBueLocations,
  selectBueRegions,
  selectEmployeeStatusesFlat,
  selectSettlementStates, selectTags
} from "../../core/store/enums/enums.selectors";
import {BehaviorSubject, filter, combineLatest, map} from "rxjs";
import {yesOrNo} from "../../core/consts/util";
import {selectSessionSettlements} from "../../core/store/core/core.selectors";
import {SettlementUserEntityService} from "../../core/service/entity-data/settlement-user-entity.service";
import {coerceBooleanProperty, coerceStringArray} from "@angular/cdk/coercion";

@Component({
  selector: 'lib-employees-filter',
  standalone: true,
  imports: [
    AsyncPipe,
    OverlayModule,
    MatDialogModule,
    MatButtonModule,
    DynamicFormModule,
  ],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.noop(),
      deps: [Overlay]
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './employees-filter.component.html',
  styleUrls: ['./employees-filter.component.css']
})
export class EmployeesFilterComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private settlementUserService = inject(SettlementUserEntityService);
  private openOnlySettlements$ = new BehaviorSubject<boolean>(false);

  form = new FormGroup({});
  model$ = this.route.queryParams.pipe(
    map((queryParams) => ({
      ...queryParams,
      tag: coerceStringArray(queryParams['tag']),
      bueRegion: coerceStringArray(queryParams['bueRegion']),
      bueLocal: coerceStringArray(queryParams['bueLocal']),
      bueLocation: coerceStringArray(queryParams['bueLocation']),
      allSettlementIds: coerceStringArray(queryParams['allSettlementIds']).map(Number),
      userId: coerceStringArray(queryParams['userId']).map(Number),
      deceased: queryParams['deceased'] === undefined ? undefined : coerceBooleanProperty(queryParams['deceased']),
      bueCurrent: queryParams['bueCurrent'] === undefined ? undefined : coerceBooleanProperty(queryParams['bueCurrent']),
      bueUnionMember: queryParams['bueUnionMember'] === undefined ? undefined : coerceBooleanProperty(queryParams['bueUnionMember']),
    }))
  );
  fields: BaseField<any>[][] = [
    [
      new InputField({
        name: 'search',
        label: 'Name',
      }),
      new SelectField({
        name: 'status',
        label: 'Status',
        options: collectionToKeyValue$(this.store.select(selectEmployeeStatusesFlat), 'displayName', 'name'),
        deselect: true,
      }),
      new SelectField({
        name: 'state',
        label: 'State',
        options: this.store.select(selectSettlementStates).pipe(filter(isTruthy)),
        deselect: true,
      }),
      new SelectField({
        name: 'contactQuality',
        label: 'Contact?',
        options: [],
        disabled: true, // @TODO
        deselect: true,
      }),
      new SelectField({
        name: 'tag',
        label: 'Tags',
        options: this.store.select(selectTags).pipe(filter(isTruthy)),
        deselect: true,
        multiple: true,
      })
    ],
    [
      new SelectField({
        name: 'bueRegion',
        label: 'BUE Region',
        multiple: true,
        options: this.store.select(selectBueRegions).pipe(filter(isTruthy)),
      }),
      new SelectField({
        name: 'bueLocal',
        label: 'BUE Local',
        multiple: true,
        options: this.store.select(selectBueLocals).pipe(filter(isTruthy)),
      }),
      new SelectField({
        name: 'bueLocation',
        label: 'BUE Location',
        multiple: true,
        options: this.store.select(selectBueLocations).pipe(filter(isTruthy)),
      }),
      new SelectField({
        name: 'bueCurrent',
        label: 'Current BUE',
        options: yesOrNo,
        deselect: true,
      }),
      new SelectField({
        name: 'bueUnionMember',
        label: 'Union Member',
        options: yesOrNo,
        deselect: true,
      }),
    ],
    [
      new SelectField({
        name: 'allSettlementIds',
        label: 'Cases',
        options: collectionToKeyValue$(
          combineLatest([
            this.store.select(selectSessionSettlements),
            this.openOnlySettlements$,
          ]).pipe(
            map(([settlements, openOnly]) => {
              if (!settlements || !openOnly) {
                return settlements;
              }
              return settlements.filter((s) => s.isOpen);
            }),
          ), 'code', 'id',
        ),
        multiple: true,
        optionFilter: {
          label: 'Open Only',
          onChange: (checked) => this.openOnlySettlements$.next(checked),
        }
      }),
      new SelectField({
        name: 'userId',
        label: 'Assigned User(s)',
        options: collectionToKeyValue$(this.settlementUserService.entities$, 'username', 'id'),
        multiple: true,
      }),
      new SelectField({
        name: 'needsCall',
        label: 'Assignment & Contact Status',
        options: [],
        deselect: true,
      }),
      new SelectField({
        name: 'deceased',
        label: 'Deceased',
        options: yesOrNo,
        deselect: true,
      }),
    ]
  ];

  ngOnInit() {
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'employeeStatus'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'settlementStates'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'bueLocations'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'bueLocals'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'bueRegions'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'tags'}));
    this.settlementUserService.loadIfNotLoadedOrLoading();
  }
}
