import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {KeyValue, NgForOf} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {Employee} from "../../core/interface/employees.interface";

@Component({
  selector: 'lib-employee-tax',
  standalone: true,
  imports: [
    NgForOf,
    MatIconModule,
  ],
  templateUrl: './employee-tax.component.html',
  styleUrls: ['./employee-tax.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeTaxComponent {
  @Input({required: true}) employee!: Employee;
  props: KeyValue<keyof Employee, string>[] = [
    { key: 'stateExempt', value: 'State Exempt' },
    { key: 'fedExempt', value: 'Fed Exempt' },
    { key: 'fedSsExempt', value: 'SS Exempt' },
    { key: 'fedMcExempt', value: 'MC Exempt' },
  ];
}
