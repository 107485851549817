import {HttpErrorResponse} from '@angular/common/http';
import {getSettlementIdFromCache} from "./core.effects";
import {Session} from "../../interface/session.interface";

export interface CoreState {
  session: Session | null;
  loading: boolean;
  error: HttpErrorResponse | null;
  settlementId: number | null;
}

export const initialCoreState: CoreState = {
  session: null,
  loading: false,
  error: null,
  settlementId: getSettlementIdFromCache(),
};
