<div class="flex-column flex-align-center-center">
  <div class="label">
    <mat-icon aria-label="Color theme icon">
      {{(themeService.isDarkTheme$ | async) ? 'dark' : 'light'}}_mode
    </mat-icon>
  </div>
  <mat-slide-toggle
    matTooltip="Switch to {{toggle.checked ? 'light' : 'dark'}} theme"
    #toggle
    hideIcon
    [checked]="!!(themeService.isDarkTheme$ | async)"
    (change)="themeService.toggleTheme($event.checked)">
  </mat-slide-toggle>
</div>
