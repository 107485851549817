import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {CalculateColumn, TextColumn, VsTableColumn, VsTableMenuItem, VsTableModule} from "vs-table";
import {User, UserEntityService} from "../../core/service/entity-data/user-entity.service";
import {AsyncPipe} from "@angular/common";
import {SessionRole} from "../../core/enum/session.enums";
import {MatButtonModule} from "@angular/material/button";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {BehaviorSubject, combineLatest, filter, map, switchMap} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent, isTruthy} from "caig-utils";

@Component({
  selector: 'lib-users-list',
  standalone: true,
  imports: [
    VsTableModule,
    AsyncPipe,
    RouterLink,
    MatButtonModule,
    MatSlideToggleModule,
  ],
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  dataService = inject(UserEntityService);
  inactive$ = new BehaviorSubject<boolean>(false);
  users$ = combineLatest([this.inactive$, this.dataService.entities$])
    .pipe(
      map(([inactive, users]) => inactive ? users : users.filter((u) => u.roleId !== SessionRole.INACTIVE))
    );
  tableColumns: VsTableColumn<User>[] = [
    new TextColumn({
      title: 'Name',
      field: 'name',
    }),
    new TextColumn({
      title: 'Username',
      field: 'username',
    }),
    new TextColumn({
      title: 'Email',
      field: 'email',
    }),
    new TextColumn({
      title: 'Role',
      field: 'roleName',
    }),
    new CalculateColumn({
      title: 'Portals',
      field: 'portals',
      calculate: (user) => {
        switch (user.roleId) {
          case SessionRole.Superadmin:
            return 'ALL';
          case SessionRole.INACTIVE:
            return 'NONE';
          default:
            return user.portals || 'N/A';
        }
      }
    })
  ];
  rowMenu: VsTableMenuItem<User>[] = [
    {
      name: () => 'Delete',
      callback: (user) => this.deleteUser(user)
    }
  ];
  ngOnInit() {
    this.dataService.loadIfNotLoadedOrLoading();
  }

  editUser(user: User): void {
    this.router.navigate([user.id], {relativeTo: this.route});
  }

  private deleteUser(user: User): void {
    const title = 'Confirm Delete';
    const text = `Are you sure want to delete ${user.name} (${user.username})?`;
    this.dialog.open(ConfirmDialogComponent, { data: { title, text }})
      .afterClosed()
      .pipe(
        filter(isTruthy),
        switchMap(() => this.dataService.delete(user))
      )
      .subscribe();
  }
}
