import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy, OnInit,
  Optional, Self,
  ViewChild
} from '@angular/core';
import {getDateProviders} from "../../custom-moment-date-adapter";
import {
  DateFilterFn,
  MatDatepickerModule,
  MatDateRangeInput,
  MatDateRangePicker
} from "@angular/material/datepicker";
import {ControlValueAccessor, FormControl, FormGroup, NgControl, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import moment from "moment";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {MatInputModule} from "@angular/material/input";
import {convertToMoment, convertToString} from "../../util-functions";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {DateRangeValue} from "../../types";

@Component({
  selector: 'date-range-input',
  standalone: true,
  imports: [
    MatMomentDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  templateUrl: './date-range-input.component.html',
  styleUrls: ['./date-range-input.component.css'],
  providers: getDateProviders(DateRangeInputComponent),
})
export class DateRangeInputComponent implements MatFormFieldControl<DateRangeValue>, ControlValueAccessor, OnInit, OnDestroy {
  static nextId = 0;

  private _placeholder: string = '';
  private _required: boolean = false;
  private _disabled: boolean = false;
  private _focused: boolean = false;
  private _touched: boolean = false;
  private _disableStartDate: boolean = false;
  private _minDate: moment.Moment | null = null;
  private _maxDate: moment.Moment | null = null;
  private _onDestroy$ = new Subject<void>();

  stateChanges = new Subject<void>();
  autofilled: boolean = false;
  controlType: string = 'date-range-input';
  userAriaDescribedBy: string = '';

  range = new FormGroup({
    start: new FormControl<moment.Moment | null>(null),
    end: new FormControl<moment.Moment | null>(null),
  });

  @HostBinding() id = `${this.controlType}-${DateRangeInputComponent.nextId++}`;
  @HostBinding('class.floating') get shouldLabelFloat() { return this.focused || !this.empty }

  @ViewChild(MatDateRangeInput, {read: ElementRef, static: true}) inputEl!: ElementRef;

  @Input({required: true}) rangepicker!: MatDateRangePicker<moment.Moment>;
  @Input() dateFilter!: DateFilterFn<moment.Moment | null>;
  @Input()
  set value(value: any) {
    if (value && typeof value === 'object') {
      this.range.patchValue({
        start: convertToMoment(value.start),
        end: convertToMoment(value.end)
      });
    } else {
      this.range.reset();
    }
  }
  get value() {
    const { start, end } = this.range.value;
    return {
      start: convertToString(start),
      end: convertToString(end),
    };
  }
  @Input()
  get placeholder(): string { return this._placeholder }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  @Input()
  get required(): boolean { return this._required }
  set required(req: any) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  @Input()
  get disabled(): boolean { return this._disabled }
  set disabled(dis: any) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  @Input() set minDate(value: any) { this._minDate = convertToMoment(value) }
  get minDate() { return convertToString(this._minDate) }
  @Input() set maxDate(value: any) { this._maxDate = convertToMoment(value) }
  get maxDate() { return convertToString(this._maxDate) }
  @Input() set disableStartDate(value: any) { this._disableStartDate = coerceBooleanProperty(value) }
  get disableStartDate(): boolean { return this._disableStartDate }
  get focused(): boolean { return this._focused }
  set focused(foc: boolean) {
    this._focused = foc;
    this.stateChanges.next();
  }
  get touched(): boolean { return this._touched }
  set touched(touch: boolean) {
    this._touched = touch;
    this.stateChanges.next();
  }
  get empty(): boolean { return !this.value }
  get errorState(): boolean {
    return !!this.ngControl && this.ngControl.errors !== null && this.touched;
  }
  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.inputEl.nativeElement.focus();
    }
  }
  setDescribedByIds(ids: string[]): void {
    const controlElement = this.inputEl.nativeElement;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  ngOnInit() {
    this.range.valueChanges
      .pipe(
        debounceTime(10),
        takeUntil(this._onDestroy$)
      )
      .subscribe((range) => {
        const validRange = this.range.status === 'VALID';
        const noRange = !range.start && !range.end;
        const requiredDates = this.disableStartDate ? !!range.end : !!(range.start || range.end);
        if (validRange && (noRange || requiredDates)) {
          this.onChange(this.value);
        }
      });
  }
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.stateChanges.complete();
  }
  onTouched = () => {};
  onChange = (v: any) => {};

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
