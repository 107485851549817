import {createAction, props} from '@ngrx/store';
import {AuthToken} from '../interfaces';

export const login = createAction(
  '[Auth] Login',
  props<{ withCredentials?: { username: string; password: string } }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ token: AuthToken, password?: string, redirect?: boolean }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);

export const logout = createAction(
  '[Auth] Logout',
  props<{ redirect?: boolean, redirectUrl?: string, expiredSession?: boolean }>()
);

export const refreshToken = createAction('[Auth] Refresh Token');

export const refreshTokenCompleted = createAction(
  '[Auth] Refresh Token Completed',
  props<{ token?: AuthToken }>()
);
