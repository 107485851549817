<mat-list role="list">
  <mat-list-item role="listitem">
    <div class="flex">
      <div class="w-28">ID</div>
      <b class="flex flex-grow">{{employee.id}}</b>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem">
    <div class="flex">
      <div class="w-28 flex justify-start items-center">Case</div>
      <div class="flex flex-grow gap-5 justify-start items-center">
        <a mat-button routerLink="/cases/{{employee.settlementId}}/view"><b>{{employee.settlementCode}}</b></a>
        <button *ngIf="isNotCurrentSettlement$ | async" mat-icon-button color="accent" matTooltip="Switch to this case" (click)="changeSettlement(employee.settlementId)">
          <mat-icon>loop</mat-icon>
        </button>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem">
    <div class="flex">
      <div class="w-28">Name</div>
      <b class="flex flex-grow">{{employee.name}}</b>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem" style="height: auto">
    <div class="flex h-44">
      <div class="w-28 flex justify-start items-center">BUE Details</div>
      <div class="flex-1 grid grid-cols-2">
        <div class="flex">ID: <b class="ml-2">{{employee.bueId}}</b></div>
        <div class="flex">City: <b class="ml-2">{{employee.bueLocation | location:'city'}}</b></div>
        <div class="flex">Region: <b class="ml-2">{{employee.bueRegion}}</b></div>
        <div class="flex">State: <b class="ml-2">{{employee.bueLocation | location:'state'}}</b></div>
        <div class="flex">Local: <b class="ml-2">{{employee.bueLocal}}</b></div>
        <div class="flex">Earliest Date: <b class="ml-2"><span *ngIf="employee.bueStartQuarter as sq">Q{{sq}} of</span> {{employee.bueStartYear}}</b></div>
        <div class="flex">Current BUE: <b class="ml-2">{{employee.bueCurrent ? 'YES' : 'NO'}}</b></div>
        <div class="flex">Latest Date: <b class="ml-2"><span *ngIf="employee.bueEndQuarter as eq">Q{{eq}} of</span> {{employee.bueEndYear}}</b></div>
        <div class="flex">Union Member: <b class="ml-2">{{employee.bueUnionMember ? 'YES' : 'NO'}}</b></div>
        <div class="flex">BUS Code: <b class="ml-2">{{employee.busCode | zeroPad:4}}</b></div>
        <div class="flex">Grade: <b class="ml-2">{{employee.grade | zeroPad:2}}</b></div>
        <div class="flex">Step: <b class="ml-2">{{employee.step | zeroPad:2}}</b></div>
        <div class="flex">Series: <b class="ml-2">{{employee.series | zeroPad:4}}</b></div>
        <div class="flex">Annual Rate: <b class="ml-2">{{employee.annualRate | currency:'USD':'$'}}</b></div>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem" style="height: auto">
    <div class="flex">
      <div class="w-28 flex justify-start items-center">Address</div>
      <div class="flex justify-center items-center mr-4">
        <mat-icon [color]="employee.addressIsInvalid ? 'warn' : 'primary'">{{employee.addressIsInvalid ? 'highlight_off' : 'check_circle'}}</mat-icon>
      </div>
      <div class="flex-1 flex flex-col justify-center">
        <b>{{employee.address1}}</b>
        <b>{{employee.address2}}</b>
        <b>{{employee.city}} {{employee.state}} {{employee.zip}}</b>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem" style="height: auto">
    <div class="flex">
      <div class="w-28 flex justify-start items-center">Phone</div>
      <div class="flex justify-center items-center mr-4">
        <mat-icon [color]="employee.phoneIsInvalid ? 'warn' : 'primary'">{{employee.phoneIsInvalid ? 'highlight_off' : 'check_circle'}}</mat-icon>
      </div>
      <div class="flex-1 flex flex-col justify-center">
        <div *ngIf="employee.phone"><b>{{employee.phone}}</b> (primary)</div>
        <div *ngIf="employee.phoneWork"><b>{{employee.phoneWork}}</b> (work)</div>
        <div *ngIf="employee.phoneCell"><b>{{employee.phoneCell}}</b> (cell)</div>
      </div>
      <div class="flex justify-center items-center ml-4">
        <lib-phone-menu [employee]="employee"></lib-phone-menu>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem" style="height: auto">
    <div class="flex">
      <div class="w-28 flex justify-start items-center">Email</div>
      <div class="flex flex-col justify-center items-center mr-4">
        <mat-icon [color]="employee.emailIsInvalid ? 'warn' : 'primary'">{{employee.emailIsInvalid ? 'highlight_off' : 'check_circle'}}</mat-icon>
        <mat-icon *ngIf="employee.emailAlt" [color]="employee.emailAltIsInvalid ? 'warn' : 'primary'">{{employee.emailAltIsInvalid ? 'highlight_off' : 'check_circle'}}</mat-icon>
      </div>
      <div class="flex-1 flex flex-col justify-center">
        <div *ngIf="employee.email"><b>{{employee.email}}</b> (primary)</div>
        <div *ngIf="employee.emailAlt"><b>{{employee.emailAlt}}</b> (alt)</div>
      </div>
      <div class="flex justify-center items-center ml-4">
        <a matTooltip="Compose Email" mat-icon-button color="primary" routerLink="email">
          <mat-icon>send</mat-icon>
        </a>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem">
    <div class="flex items-center">
      <div class="w-28">Password</div>
      <lib-encrypted-value [employee]="employee" prop="password" disableDelete></lib-encrypted-value>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem">
    <div class="flex items-center">
      <div class="w-28">SSN</div>
      <lib-encrypted-value #btn [employee]="employee" prop="ssn" (valueSet)="btn.encrypt()"></lib-encrypted-value>
    </div>
  </mat-list-item>
</mat-list>
