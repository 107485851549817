<div role="group"
     class="lib-phone-number-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input class="lib-phone-number-input-element"
         formControlName="area"
         size="3"
         maxlength="3"
         aria-label="Area code"
         (keyup)="_handleInput($event, parts.controls.area, exchange)"
         #area>
  <span class="lib-phone-number-input-spacer">&ndash;</span>
  <input class="lib-phone-number-input-element"
         formControlName="exchange"
         maxlength="3"
         size="3"
         aria-label="Exchange code"
         (keyup)="_handleInput($event, parts.controls.exchange, subscriber)"
         (keyup.backspace)="autoFocusPrev(exchange, area)"
         #exchange>
  <span class="lib-phone-number-input-spacer">&ndash;</span>
  <input class="lib-phone-number-input-element"
         formControlName="subscriber"
         maxlength="4"
         size="4"
         aria-label="Subscriber number"
         (keyup)="_handleInput($event, parts.controls.subscriber, showExt ? ext : undefined)"
         (keyup.backspace)="autoFocusPrev(subscriber, exchange)"
         #subscriber>
  <span *ngIf="showExt" class="lib-phone-number-input-spacer">Ext.</span>
  <input class="lib-phone-number-input-element"
         [style.display]="showExt ? 'initial' : 'none'"
         formControlName="ext"
         maxlength="5"
         size="5"
         aria-label="Extension number"
         (keyup)="_handleInput($event, parts.controls.subscriber)"
         (keyup.backspace)="autoFocusPrev(ext, subscriber)"
         #ext>
</div>
