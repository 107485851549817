import {NgModule} from "@angular/core";
import {DynamicFormBuilderService} from "./service/dynamic-form-builder.service";
import {DynamicFieldDirective} from "./directive/dynamic-field.directive";
import { InputFieldComponent } from './component/input-field/input-field.component';
import { DateFieldComponent } from './component/date-field/date-field.component';
import {DynamicFormComponent} from "./dynamic-form/dynamic-form.component";
import {ReactiveFormsModule} from "@angular/forms";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {DynamicFormMaterialModule} from "./dynamic-form-material.module";
import {DatepickerComponent, DateRangeComponent} from "date";
import {DateRangeFieldComponent} from "./component/date-range-field/date-range-field.component";
import { CurrencyFieldComponent } from './component/currency-field/currency-field.component';
import {CurrencyComponent} from "currency";
import {AutocompleteFieldComponent} from "./component/autocomplete-field/autocomplete-field.component";
import {CheckboxFieldComponent} from "./component/checkbox-field/checkbox-field.component";
import { RadioFieldComponent } from './component/radio-field/radio-field.component';
import { SelectFieldComponent } from './component/select-field/select-field.component';
import {NumberRangeFieldComponent} from "./component/number-range-field/number-range-field.component";
import {
  NumberRangeInputComponent
} from "./component/number-range-field/number-range-input/number-range-input.component";
import {TextareaFieldComponent} from "./component/textarea-field/textarea-field.component";
import {PhoneNumberFieldComponent} from "./component/phone-number-field/phone-number-field.component";
import {
  PhoneNumberInputComponent
} from "./component/phone-number-field/phone-number-input/phone-number-input.component";

@NgModule({
  imports: [
    NgForOf,
    NgIf,
    AsyncPipe,
    ReactiveFormsModule,
    DynamicFormMaterialModule,
    CurrencyComponent,
    DatepickerComponent,
    DateRangeComponent,
  ],
  declarations: [
    DynamicFormComponent,
    DynamicFieldDirective,
    InputFieldComponent,
    DateFieldComponent,
    DateRangeFieldComponent,
    CurrencyFieldComponent,
    AutocompleteFieldComponent,
    CheckboxFieldComponent,
    RadioFieldComponent,
    SelectFieldComponent,
    NumberRangeInputComponent,
    NumberRangeFieldComponent,
    TextareaFieldComponent,
    PhoneNumberFieldComponent,
    PhoneNumberInputComponent,
  ],
  exports: [
    DynamicFormComponent,
  ],
  providers: [
    DynamicFormBuilderService,
  ],
})
export class DynamicFormModule {

}
