import {Component} from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {NumberRangeField} from "../../class/number-range-field";

@Component({
  selector: 'lib-number-range-field',
  templateUrl: './number-range-field.component.html',
  styleUrls: ['./number-range-field.component.css'],
})
export class NumberRangeFieldComponent extends BaseFieldComponent<NumberRangeField>{

}
