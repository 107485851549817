import {Component, inject, ChangeDetectionStrategy} from '@angular/core';
import {ThemeService} from '../../service/theme.service';
import {MatIconModule} from "@angular/material/icon";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {AsyncPipe} from "@angular/common";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    MatIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
  ],
})
export class ThemeToggleComponent {
  public themeService = inject(ThemeService);
}
