import {Injectable, inject, NgZone} from '@angular/core';
import {BehaviorSubject, shareReplay, map} from 'rxjs';
import {DOCUMENT} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ThemeService {
  private static readonly THEME_STORAGE_KEY = 'appTheme';
  private document = inject(DOCUMENT);
  private zone = inject(NgZone);
  private themeChange$ = new BehaviorSubject<AppTheme>(AppTheme.Light);
  private readonly preferredTheme: AppTheme;

  public currentTheme$ = this.themeChange$.asObservable().pipe(shareReplay());
  public isDarkTheme$ = this.currentTheme$.pipe(
    map((theme) => theme === AppTheme.Dark)
  );

  constructor() {
    const mediaSelector = '(prefers-color-scheme: dark)';
    this.preferredTheme = window.matchMedia(mediaSelector).matches ? AppTheme.Dark : AppTheme.Light;
    window.matchMedia(mediaSelector).onchange = (event) =>
      this.zone.run(() => this.toggleTheme(event.matches));
  }

  public applyStoredTheme(): void {
    try {
      const storedTheme = localStorage.getItem(ThemeService.THEME_STORAGE_KEY);
      const theme = storedTheme || this.preferredTheme;
      const convertedTheme = Number(theme);
      this.toggleTheme(convertedTheme === AppTheme.Dark);
    } catch { }
  }

  public toggleTheme(darkTheme: boolean): void {
    try {
      const theme = darkTheme ? AppTheme.Dark : AppTheme.Light;
      this.themeChange$.next(theme);
      if (darkTheme) {
        this.setStyle('dark.css');
      } else {
        this.removeStyle();
      }
      localStorage.setItem(ThemeService.THEME_STORAGE_KEY, theme.toString());
    } catch { }
  }

  private setStyle(href: string): void {
    this.getLinkElementForKey().setAttribute('href', href);
  }

  private removeStyle(): void {
    const existingLinkElement = this.getExistingLinkElementByKey();
    if (existingLinkElement) {
      this.document.head.removeChild(existingLinkElement);
    }
  }

  private getLinkElementForKey() {
    return this.getExistingLinkElementByKey() || this.createLinkElementWithKey();
  }

  private getExistingLinkElementByKey() {
    return this.document.head.querySelector(`link[rel="stylesheet"].${ThemeService.THEME_STORAGE_KEY}`);
  }

  private createLinkElementWithKey() {
    const linkEl = this.document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.classList.add(ThemeService.THEME_STORAGE_KEY);
    this.document.head.appendChild(linkEl);
    return linkEl;
  }
}

export enum AppTheme {
  Light,
  Dark,
}
