import {BaseColumn} from "./base-column";
import {VsTableColumnConfigButton, VsTableColumnDataType} from "../types";

export class ButtonColumn<T> extends BaseColumn<T> {
  public calculate: (row: T) => string;
  public dataType = VsTableColumnDataType.Button;
  constructor(config: VsTableColumnConfigButton<T>) {
    super(config);
    this.calculate = config.calculate;
    this.filter = false;
  }
}
