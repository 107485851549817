import {NgModule} from "@angular/core";
import { VsTableComponent } from './vs-table/vs-table.component';
import {DynamicFormModule} from "dynamic-form";
import {AccountingPipe} from "caig-utils";
import {CommonModule} from "@angular/common";
import {VsTableMaterialModule} from "./vs-table-material.module";
import {VsTableCellComponent} from "./vs-table-cell/vs-table-cell.component";
import {VsTableColumnFilterComponent} from "./vs-table-column-filter/vs-table-column-filter.component";
import {VsTableChangesListComponent} from "./vs-table-changes-list/vs-table-changes-list.component";
import {TableVirtualScrollModule} from "./table-virtual-scroll/table-virtual-scroll.module";
import {ExpandedDialogComponent} from "./expanded-dialog/expanded-dialog.component";
import {CurrencyCellComponent} from "./currency-cell/currency-cell.component";

@NgModule({
  imports: [
    CommonModule,
    TableVirtualScrollModule,
    DynamicFormModule,
    AccountingPipe,
    VsTableMaterialModule,
  ],
  declarations: [
    VsTableComponent,
    VsTableCellComponent,
    VsTableColumnFilterComponent,
    VsTableChangesListComponent,
    ExpandedDialogComponent,
    CurrencyCellComponent,
  ],
  exports: [
    VsTableComponent
  ],
})
export class VsTableModule { }
