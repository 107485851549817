import {SessionRole} from '../enum/session.enums';

export interface Session {
  mustChangePassword?: boolean;
  user: SessionUser;
  settlements: SessionCase[];
}

export interface SessionCase {
  id: number;
  code: string;
  title: string;
  isOpen: boolean;
  adminEmail: string;
  adminCc: string;
}

export interface SessionUser {
  name: string;
  email: string;
  username: string;
  avatar?: string;
  roleId: SessionRole;
  roleName: string;
  id: number;
  settlements?: SessionCase[];
  portals?: string; // Comma separated Portal, undefined for superadmins and inactive
}

export interface UserRole {
  id: number;
  name: string;
  icon?: string;
}

export interface UserSettlement {
  id: number;
  code: string;
  title: string;
  isOpen: boolean;
  adminEmail: string;
  adminCc: string;
  type: SettlementType;
}

export enum SettlementType {
  Distribution = 1,
  Claim,
  Registration,
  AdvancedClaim,
}
