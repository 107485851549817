import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {NgFor, NgIf} from "@angular/common";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import {ConfirmDialogComponent, ConfirmDialogData} from "caig-utils";
import {MatDialog} from "@angular/material/dialog";
import {NavMenuItem} from "../../interface/navigation.interface";

@Component({
  selector: 'app-navigation-menu-items',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    RouterModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './navigation-menu-items.component.html',
  styleUrls: ['./navigation-menu-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuItemsComponent {
  @Input({required: true}) menu!: NavMenuItem[];
  private dialog = inject(MatDialog);

  showNavItemDescription(label: string, description: string): void {
    const data: ConfirmDialogData = {
      title: label,
      subtitle: 'More Info',
      text: description,
      hideCancel: true,
    };
    this.dialog.open(ConfirmDialogComponent, { data });
  }
}

