<mat-form-field [subscriptSizing]="subscriptSizing" [formGroup]="form" [appearance]="appearance" [color]="color">
  <mat-label>{{label}}</mat-label>
  <datepicker-input
    [datepicker]="picker"
    [dateFilter]="dateFilter"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [placeholder]="placeholder"
    [formControlName]="controlName"
    [required]="required"></datepicker-input>
  <mat-hint [align]="hintAlign || 'start'">{{hint}}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">{{label}} is required.</mat-error>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <button *ngIf="control.value"
          matSuffix
          mat-icon-button
          [disabled]="control.disabled"
          (click)="control.patchValue(null)">
    <mat-icon>close</mat-icon>
  </button>
  <mat-datepicker [startView]="startView"
                  [startAt]="startDate"
                  [touchUi]="touchUi"
                  [dateClass]="dateClass"
                  #picker></mat-datepicker>
</mat-form-field>
