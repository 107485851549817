import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {VsTableCellChange} from "../types";

@Component({
  selector: 'vs-table-changes-list',
  templateUrl: './vs-table-changes-list.component.html',
  styleUrls: ['./vs-table-changes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VsTableChangesListComponent {
  @Input({required: true}) changes!: VsTableCellChange[];
}
