import {EventEmitter, inject, Injectable, InjectionToken, Injector} from "@angular/core";
import {ComponentType, Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {first} from "rxjs/operators";
import {ReplaySubject} from "rxjs";

export const OVERLAY_DATA = new InjectionToken<unknown>('OVERLAY_DATA');

export interface CustomOverlayComponent<T> {
  data: T;
  animationDone?: EventEmitter<void>;
  dragEnded?: EventEmitter<{ x: number, y: number }>;
  startExitAnimation?: () => void;
}

@Injectable({providedIn: 'root'})
export class OverlayService {
  private overlay = inject(Overlay);
  private injector = inject(Injector);
  private overlayPositionBuilder = inject(OverlayPositionBuilder);

  private _isOpened: boolean = false;
  private _overlayRef: OverlayRef | undefined;

  get isOpened(): boolean {
    return this._isOpened;
  }

  open<T>(
    componentType: ComponentType<CustomOverlayComponent<T>>,
    data?: T,
    backdrop?: { closeOnClick: boolean },
    position?: { top?: string, bottom?: string, left?: string, right?: string }
  ) {
    const positions = this.overlayPositionBuilder
      .global()
      .centerHorizontally()
      .bottom('0');

    if (position) {
      positions.left(position.left)
        .right(position.right)
        .top(position.top)
        .bottom(position.bottom);
    }

    const overlayConfig: OverlayConfig = {
      hasBackdrop: !!backdrop,
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: positions,
    };

    this._overlayRef = this.overlay.create(overlayConfig);
    const result = new ReplaySubject<boolean>();

    const customInjector = Injector.create({
      providers: [{ provide: OVERLAY_DATA, useValue: data }],
      parent: this.injector,
    });

    const overlayComponent = this._overlayRef.attach(new ComponentPortal(componentType, null, customInjector));
    const instance = overlayComponent.instance;

    if (instance.animationDone) {
      if (backdrop?.closeOnClick) {
        this._overlayRef.backdropClick().pipe(first()).subscribe({
          next: () => {
            result.next(false);
            if (instance.startExitAnimation) {
              instance.startExitAnimation();
            }
          },
        });
      }
      instance.animationDone.pipe(first()).subscribe({
        next: () => this.close(),
      });
    }

    this._overlayRef.detachments().pipe(first()).subscribe({
      next: () => result.next(true),
    });

    this._isOpened = true;

    return result.asObservable().pipe(first());
  }
  close(): void {
    this._overlayRef?.detach();
    this._isOpened = false;
  }
}
