import {inject, Injectable} from "@angular/core";
import {PhoneService, VoiceCallToken} from "./phone.service";
import {NotificationService} from "notification";
import {Observable, of, tap} from "rxjs";
import {Device, Call} from '@twilio/voice-sdk';

@Injectable({providedIn: 'root'})
export class TwilioVoiceService {
  private phoneService = inject(PhoneService);
  private notifications = inject(NotificationService);

  private _device: Device | undefined;
  private _identity: string | undefined;
  private _activeCall: ActiveCall | undefined;

  get device(): Device | undefined {
    return this._device;
  }
  get identity(): string | undefined {
    return this._identity;
  }
  get activeCall(): ActiveCall | undefined {
    return this._activeCall;
  }

  initializeDevice(): Observable<VoiceCallToken | null> {
    if (this._device) {
      return of(null);
    }
    return this.phoneService.voiceCallToken()
      .pipe(
        tap((res) => {
          this._identity = res.id;
          this._device = new Device(res.token, {
            logLevel: 1,
            codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
          });
          this._device.on('error', (err) => this.notifications.showDetailedMessage('A device error has occurred', err));
        }),
      );
  }

  destroyDevice(): void {
    this._device?.destroy();
    this._device = undefined;
    this._identity = undefined;
  }

  async call(phoneNumber: string, onAccept?: () => void, onDisconnect?: () => void, onCancel?: () => void): Promise<Call | undefined> {
    try {
      const device = this._device as Device;
      const params = {
        To: phoneNumber,
        callingDeviceIdentity: this._identity as string,
      };
      const call = await device.connect({ params });
      if (onAccept) {
        call.on('accept', onAccept);
      }
      if (onDisconnect) {
        call.on('disconnect', () => {
          this._activeCall = undefined;
          onDisconnect();
        });
      }
      if (onCancel) {
        call.on('cancel', () => {
          this._activeCall = undefined;
          onCancel();
        });
      }
      this._activeCall = {
        startTime: new Date(),
        call: call,
        phoneNumber,
      };
      return call;
    } catch {
      this.notifications.showSimpleMessage('Device failed to make the call');
      return undefined;
    }
  }

  replaceListener(call: Call, event: 'accept' | 'disconnect' | 'cancel', callback: () => void): void {
    call.removeAllListeners(event);
    call.on(event, () => {
      if (event === 'disconnect' || event === 'cancel') {
        this._activeCall = undefined;
      }
      callback();
    });
  }
}

export interface ActiveCall {
  startTime: Date;
  call: Call;
  phoneNumber: string;
}
