import {createReducer, on} from '@ngrx/store';
import {initialAuthState} from './auth.state';
import {AuthActions} from './action-types';
import {TokenStorageError} from '../enums';

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.login, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.refreshToken, (state) => ({
    ...state,
    tokenRefreshing: true,
  })),
  on(AuthActions.refreshTokenCompleted, (state, { token }) => ({
    ...state,
    token: token || state.token,
    tokenRefreshing: false,
  })),
  on(AuthActions.loginSuccess, (state, { token, password }) => ({
    ...state,
    loading: false,
    error: null,
    token,
    loginPassword: password ? password : state.loginPassword,
  })),
  on(AuthActions.loginFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(AuthActions.logout, (state, { expiredSession }) => ({
    ...initialAuthState,
    error: expiredSession ? TokenStorageError.Expired : null,
  })),
);
