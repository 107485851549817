import {Injectable, inject, ApplicationRef} from '@angular/core';
import {SwUpdate, VersionEvent, VersionReadyEvent} from '@angular/service-worker';
import {
  Observable,
  from,
  tap,
  first,
  withLatestFrom,
  of,
  filter,
  map,
  interval,
  concat,
  skip,
  switchMap,
  catchError, throwError
} from 'rxjs';
import {NotificationService} from 'notification';
import {shareReplay} from "rxjs/operators";
import {AppData, AppDataChangePortal, AppDataChanges, AppDataPortal} from "../interface/app-data.interface";
import {pick, some} from "lodash";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {selectIsSuperAdmin} from "../store/core/core.selectors";
import {ConfirmDialogComponent, ConfirmDialogData, isTruthy} from "caig-utils";
import {CORE_CONFIG} from "../consts/injection-tokens";
import {WhatsNewComponent} from "../component/whats-new/whats-new.component";

@Injectable({providedIn: 'root'})
export class ServiceWorkerService {
  private static readonly APP_DATA_KEY = 'SW_UPDATE_APP_DATA';
  private static readonly NO_SW_CONTROLLER = 'NO_SW_CONTROLLER';
  private static readonly NOTIFICATION_KEY = 'UPDATE_INSTALLED';
  private static isVersionReady(event: VersionEvent): event is VersionReadyEvent {
    return event.type === 'VERSION_READY';
  }

  private updates = inject(SwUpdate);
  private notifications = inject(NotificationService);
  private store = inject(Store);
  private dialog = inject(MatDialog);
  private config = inject(CORE_CONFIG);
  private appRef = inject(ApplicationRef);

  isUpdating = false;
  isUpdateAvailable$ = this.updates.versionUpdates
    .pipe(
      filter(ServiceWorkerService.isVersionReady),
      tap((event) => this.storeAppData(event)),
      shareReplay(1),
    );
  appData: AppData | undefined;

  constructor() {
    if (this.hasServiceWorkerController()) {
      this.checkIfUpdated();
      this.pollForUpdates();
      this.handleUnrecoverableState();
      this.handleUpdateError();
    }
  }

  private hasServiceWorkerController(): boolean {
    if (!this.updates.isEnabled) {
      return false;
    }
    if (!navigator.serviceWorker.controller) {
      const alreadyReloaded = localStorage.getItem(ServiceWorkerService.NO_SW_CONTROLLER);
      if (!alreadyReloaded) {
        localStorage.setItem(ServiceWorkerService.NO_SW_CONTROLLER, 'true');
        location.reload();
        return false;
      }
    }
    localStorage.removeItem(ServiceWorkerService.NO_SW_CONTROLLER);
    return true;
  }

  private checkIfUpdated(): void {
    const notify = localStorage.getItem(ServiceWorkerService.NOTIFICATION_KEY);
    const cachedData = localStorage.getItem(ServiceWorkerService.APP_DATA_KEY);
    if (notify) {
      localStorage.removeItem(ServiceWorkerService.NOTIFICATION_KEY);
      this.notifications.showSimpleMessage('The update has been installed successfully!');
    }
    if (cachedData) {
      localStorage.removeItem(ServiceWorkerService.APP_DATA_KEY);
      this.appData = JSON.parse(cachedData);
      this.getScopedChanges$().subscribe((data) => {
        if (data) {
          this.dialog.open(WhatsNewComponent, {data, disableClose: true});
        }
      });
    }
  }

  private pollForUpdates(): void {
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable));
    const everyHalfHour$ = interval(30 * 60 * 1000);
    const everyHalfHourOnceAppIsStable$ = concat(appIsStable$, everyHalfHour$);
    everyHalfHourOnceAppIsStable$
      .pipe(skip(1))
      .subscribe(() => this.updates.checkForUpdate());
  }

  private handleUnrecoverableState(): void {
    this.updates.unrecoverable
      .pipe(
        switchMap((event) => {
          const data: ConfirmDialogData = {
            title: 'Something went wrong...',
            subtitle: 'An error occurred that we cannot recover from:',
            text: event.reason,
            confirmText: 'Reload Page',
            hideCancel: true,
          };
          return this.dialog.open(ConfirmDialogComponent, {data, disableClose: true}).afterClosed();
        })
      )
      .subscribe(() => location.reload());
  }

  private handleUpdateError(): void {
    this.updates.versionUpdates
      .pipe(filter((event) => event.type === 'VERSION_INSTALLATION_FAILED'))
      .subscribe((event) => {
        console.log('An error occurred while updating', event);
        location.reload();
      });
  }

  private storeAppData(event: VersionReadyEvent): void {
    this.appData = event.latestVersion.appData as AppData | undefined;
    if (this.appData) {
      localStorage.setItem(ServiceWorkerService.APP_DATA_KEY, JSON.stringify(this.appData));
    }
  }

  getScopedChanges$(): Observable<AppDataChanges | null> {
    if (this.appData?.changes) {
      const changes = this.appData.changes;
      const portals = Object.keys(changes) as AppDataPortal[];
      if (portals.length && some(portals, (p) => changes[p] && Object.keys(changes[p] as AppDataChangePortal).length > 0)) {
        const isSuperAdmin$ = this.store.select(selectIsSuperAdmin).pipe(filter(isTruthy));
        return isSuperAdmin$
          .pipe(
            map((isSuperAdmin) => {
              const nonAdminKeys: AppDataPortal[] = ['General', this.config.portal];
              const portalChanges = isSuperAdmin ? changes : pick(changes, nonAdminKeys);
              return Object.keys(portalChanges).length ? portalChanges : null;
            }),
            first(),
          );
      }
    }
    return of(null);
  }

  installUpdate(notify = true): void {
    if (this.updates.isEnabled) {
      this.isUpdating = true;
      this.updates.activateUpdate().finally(() => {
        if (this.appData?.clearLocalStorage) {
          localStorage.clear();
          localStorage.setItem(ServiceWorkerService.APP_DATA_KEY, JSON.stringify(this.appData));
        }
        this.isUpdating = false;
        if (notify) {
          localStorage.setItem(ServiceWorkerService.NOTIFICATION_KEY, 'true');
        }
        location.reload();
      });
    }
  }

  checkForUpdate(): Observable<any> {
    if (!this.updates.isEnabled) {
      return of(null);
    }
    return from(this.updates.checkForUpdate())
      .pipe(
        withLatestFrom(this.updates.versionUpdates),
        tap(([updateFound, versionUpdates]) => {
          if (updateFound && ServiceWorkerService.isVersionReady(versionUpdates)) {
            this.storeAppData(versionUpdates);
            this.installUpdate(false);
          }
        }),
        first(),
        catchError((err) => {
          location.reload();
          return throwError(err);
        }),
      );
  }
}
