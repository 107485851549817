import {Component, inject} from '@angular/core';
import {FileUploadModule} from "file-upload";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'lib-attach-files',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    FileUploadModule,
  ],
  templateUrl: './attach-files.component.html',
  styleUrls: ['./attach-files.component.css']
})
export class AttachFilesComponent {
  dialogRef = inject(MatDialogRef);
  data: AttachFilesData = inject(MAT_DIALOG_DATA);
}

export interface AttachFilesData {
  id: string;
  whenSubmitted?: string;
}
