import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldType} from "../types";
import {FieldMenu} from "../interfaces";

export class PhoneNumberField extends BaseField<string> {
  type = FieldType.PhoneNumber;
  placeholder: string;
  menu: FieldMenu | undefined;
  ext: boolean;

  constructor(config: PhoneNumberFieldConfig) {
    super(config);
    this.placeholder = config.placeholder || '';
    this.menu = config.menu;
    this.ext = !!config.ext;
  }
}

export interface PhoneNumberFieldConfig extends BaseFieldConfig<string> {
  placeholder?: string;
  menu?: FieldMenu;
  ext?: boolean;
}
