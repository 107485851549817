import {InjectionToken} from '@angular/core';
import {AuthConfig} from './interfaces';

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('AUTH_CONFIG');

/**
 * Returns the number of remaining hours to the given datetime.
 * Returns 0 if difference is negative or null if datetime cannot be parsed.
 * @param datetime - Datetime in UTC
 */
export const getRemainingHours = (datetime: string) => {
  const inputDate = new Date(datetime);
  if (isNaN(inputDate.getTime())) {
    console.error('Invalid datetime string');
    return null;
  }
  const currentDate = new Date();
  const diffInMilliseconds = inputDate.getTime() - currentDate.getTime();
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return Math.max(0, diffInHours);
};
