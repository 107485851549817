import {Component, ChangeDetectionStrategy, inject} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  private breakpointObserver = inject(BreakpointObserver);
  public isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(({matches}) => matches));
  public url = location.href;
}
