import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CoreModule} from "shared-modules";
import {coreConfig} from "./core-config";
import {EntityStoreModule} from "./store/entity-store.module";
import {AuthModule} from "auth";
import {authConfig} from "./auth.config";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot(authConfig),
    CoreModule.forRoot(coreConfig),
    EntityStoreModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
