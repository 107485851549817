import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {MatDialog} from "@angular/material/dialog";
import {Employee} from "../../core/interface/employees.interface";
import {filter, finalize, Observable, switchMap, tap} from "rxjs";
import {FormDialogComponent, FormDialogData} from "../../core/component/form-dialog/form-dialog.component";
import {InputField} from "dynamic-form";
import {ChangePasswordComponent} from "../../core/component/change-password/change-password.component";
import {ConfirmDialogComponent, ConfirmDialogData} from "caig-utils";
import {coerceBooleanProperty} from "@angular/cdk/coercion";

@Component({
  selector: 'lib-encrypted-value',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './encrypted-value.component.html',
  styleUrls: ['./encrypted-value.component.css'],
})
export class EncryptedValueComponent implements OnChanges {
  private static readonly ENCRYPTED = '[Encrypted]';

  private dataService = inject(EmployeeEntityService);
  private dialog = inject(MatDialog);
  private cd = inject(ChangeDetectorRef);

  @Input({required: true}) employee!: Employee;
  @Input({required: true}) prop!: keyof Employee;
  @Input({transform: coerceBooleanProperty}) disableDelete = false;
  @Output() valueSet = new EventEmitter<void>();

  isProcessing = false;
  employeeValue = '';
  decryptedValue = '';

  ngOnChanges(changes: SimpleChanges) {
    if (this.employee && this.prop) {
      this.employeeValue = this.employee[this.prop] as string || '';
      this.decryptedValue = '';
    }
  }

  encrypt(): void {
    this.decryptedValue = '';
    this.employeeValue = EncryptedValueComponent.ENCRYPTED;
  }

  decrypt(): void {
    this.isProcessing = true;
    this.dataService.decrypt(this.employee.id, this.prop)
      .pipe(finalize(() => {
        this.isProcessing = false;
        this.cd.detectChanges();
      }))
      .subscribe((res) => this.decryptedValue = res[this.prop]);
  }

  edit(): void {
    const employeeValue = this.employeeValue;
    const decryptedValue = this.decryptedValue;
    this.collectResponse()?.pipe(
      filter((data) => !!data),
      tap((data) => {
        this.isProcessing = true;
        if (!employeeValue) {
          this.valueSet.emit(void 0);
        }
        this.employeeValue = EncryptedValueComponent.ENCRYPTED;
        this.decryptedValue = data[this.prop];
      }),
      switchMap((data) => this.dataService.patch(this.employee.id, {[this.prop]: data[this.prop]}))
    )
      .subscribe(() => this.isProcessing = false, () => {
        this.employeeValue = employeeValue;
        this.decryptedValue = decryptedValue;
        this.isProcessing = false;
      });
  }

  remove(): void {
    const employeeValue = this.employeeValue;
    const decryptedValue = this.decryptedValue;
    const data: ConfirmDialogData = { title: 'Confirm Delete', text: `Are you sure you want to delete ${this.employee.name}'s ${this.prop}?` };
    this.dialog.open(ConfirmDialogComponent, { data })
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        tap(() => {
          this.employeeValue = '';
          this.decryptedValue = '';
        }),
        switchMap(() => this.dataService.patch(this.employee.id, {[this.prop]: ''})),
      )
      .subscribe(() => this.isProcessing = false, () => {
        this.employeeValue = employeeValue;
        this.decryptedValue = decryptedValue;
        this.isProcessing = false;
      });
  }

  collectResponse(): Observable<any> | null {
    switch (this.prop) {
      case 'ssn':
        const data: FormDialogData = {
          title: 'Edit Employee SSN',
          confirmText: 'Save',
          fields: [
            [
              // @TODO - change to SsnField
              new InputField({
                name: 'ssn',
                label: 'SSN',
                required: true,
              })
            ]
          ],
        };
        return this.dialog.open(FormDialogComponent, { data }).afterClosed();
      case 'password':
        return this.dialog.open(ChangePasswordComponent).afterClosed()
      default:
        return null;
    }
  }
}
