import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, of, switchMap, tap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CoreActions} from './action-types';
import {Session} from "../../interface/session.interface";
import {CORE_CONFIG} from "../../consts/injection-tokens";
import {ChangePasswordComponent, ChangePasswordData} from "../../component/change-password/change-password.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class CoreEffects {
  private actions$ = inject(Actions);
  private http = inject(HttpClient);
  private config = inject(CORE_CONFIG);
  private dialog = inject(MatDialog);

  initialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.initializeSession),
      switchMap(() => {
        return this.http.get<Session>('/api' + SESSION_INITIALIZE_ENDPOINT, { params: { portal: this.config.portal }}).pipe(
          map((session) => CoreActions.sessionInitialized({ session })),
          catchError((error) => of(CoreActions.sessionInitializeError({ error })))
        );
      }),
    )
  );

  initializeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.sessionInitialized),
      tap(({session}) => {
        if (session.mustChangePassword && session.user) {
          const data: ChangePasswordData = { userId: session.user.id, self: true, copyAuthPassword: true };
          this.dialog.open(ChangePasswordComponent, { data , disableClose: true});
        }
      }),
    ), { dispatch: false }
  )

  settlementChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.changeSettlementContext),
      tap(({settlementId}) => localStorage.setItem(SETTLEMENT_ID_CACHE_KEY, settlementId.toString())),
      map(({redirect}) => CoreActions.resetEntities({redirect}))
    )
  );
}

export const SETTLEMENT_ID_CACHE_KEY = 'settlementId';

export function getSettlementIdFromCache(): number | null {
  const storedSettlementId = localStorage.getItem(SETTLEMENT_ID_CACHE_KEY);
  return storedSettlementId ? Number(storedSettlementId) : null;
}

export const SESSION_INITIALIZE_ENDPOINT = '/session/initialize';
