import {Component} from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {CheckboxField} from "../../class/checkbox-field";

@Component({
  selector: 'lib-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  styleUrls: ['./checkbox-field.component.css'],
})
export class CheckboxFieldComponent extends BaseFieldComponent<CheckboxField> {

}
