<div *ngIf="email$ | async as email">
  <div class="grid grid-cols-[auto,1fr] gap-4 my-4">
    <div class="opacity-75">TO:</div>
    <div>{{email.toAddress}}</div>
    <div class="opacity-75">FROM:</div>
    <div>{{email.fromAddress}}</div>
    <div class="opacity-75">CC:</div>
    <div>{{email.ccAddress}}</div>
    <div class="opacity-75">SUBJECT:</div>
    <div class="font-semibold" [innerHTML]="email.subject | safe:'html'"></div>
  </div>
  <mat-divider></mat-divider>
  <div class="mt-4">
    <div class="opacity-75 mb-2">BODY:</div>
    <div [innerHTML]="email.body | safe:'html'"></div>
  </div>
</div>
