import {Pipe, PipeTransform} from "@angular/core";
import {zeroPad} from "../functions";

@Pipe({
  name: 'zeroPad',
  standalone: true,
})
export class ZeroPadPipe implements PipeTransform {
  transform(value: number | undefined, padding: number): string {
    return zeroPad(value, padding);
  }
}
