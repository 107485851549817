import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatListModule} from "@angular/material/list";
import {AsyncPipe, NgFor, NgIf} from "@angular/common";
import {Store} from "@ngrx/store";
import {NavigationMenuItemsComponent} from "../navigation-menu-items/navigation-menu-items.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {StartCasePipe} from "caig-utils";
import {NavSection} from "../../interface/navigation.interface";
import {selectSession} from "../../store/core/core.selectors";
import {CORE_CONFIG} from "../../consts/injection-tokens";

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    MatListModule,
    MatProgressBarModule,
    NavigationMenuItemsComponent,
    StartCasePipe,
  ],
})
export class NavigationMenuComponent {
  private store = inject(Store);
  private config = inject(CORE_CONFIG);

  session$ = this.store.select(selectSession);
  sections: NavSection[] = this.config.navigationMenu || [];
}
