import {Component} from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {CurrencyField} from "../../class/currency-field";

@Component({
  selector: 'lib-currency-field',
  templateUrl: './currency-field.component.html',
  styleUrls: ['./currency-field.component.css'],
})
export class CurrencyFieldComponent extends BaseFieldComponent<CurrencyField> {

}
