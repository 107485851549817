import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldType} from "../types";
import {Observable, of} from "rxjs";

export class AutocompleteField<T> extends BaseField<string | number> {
  type = FieldType.Autocomplete;
  placeholder: string;
  itemKey: Extract<keyof T, string>;
  displayField: Extract<keyof T, string>;
  options: Observable<T[]>;
  onAddItem: (() => void) | undefined;
  constructor(config: AutocompleteConfig<T>) {
    super(config);
    this.placeholder = config.placeholder || '';
    this.itemKey = config.itemKey;
    this.displayField = config.displayField;
    this.options = config.options instanceof Array ? of(config.options) : config.options;
    this.onAddItem = config.onAddItem;
    this.flex = config.flex === undefined ? 0 : config.flex;
  }
}

export interface AutocompleteConfig<T> extends BaseFieldConfig<string | number> {
  itemKey: Extract<keyof T, string>;
  displayField: Extract<keyof T, string>;
  options: T[] | Observable<T[]>;
  placeholder?: string;
  onAddItem?: () => void;
}
