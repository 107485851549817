import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  ChangesColumn,
  DateColumn,
  TextColumn,
  VsTableColumn,
  VsTableModule
} from "vs-table";
import {AsyncPipe, NgIf} from "@angular/common";
import {EventsDataService} from "../service/events-data.service";
import {filter, map, Observable, of, shareReplay, startWith, switchMap} from "rxjs";
import {MatCardModule} from "@angular/material/card";
import {BaseField, DateRangeField, DynamicFormModule, SelectField} from "dynamic-form";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {selectIsAdmin} from "../../core/store/core/core.selectors";
import {isBoolean, omit} from "lodash";
import {collectionToKeyValue$, isTruthy} from "caig-utils";
import {SettlementUserEntityService} from "../../core/service/entity-data/settlement-user-entity.service";
import {EnumsActions} from "../../core/store/enums/action-types";
import {AppEvent} from "../../core/interface/events.interface";
import {FormGroup} from "@angular/forms";
import {mapNumberArrToModel} from "../../core/consts/util";
import {MatButtonModule} from "@angular/material/button";
import {HttpParams} from "@angular/common/http";
import {DateService} from "date";
import {CORE_CONFIG} from "../../core/consts/injection-tokens";
import {AppPortal} from "../../core/enum/session.enums";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {selectEventTypesPortal} from "../../core/store/enums/enums.selectors";

@Component({
  selector: 'lib-events-list',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    DynamicFormModule,
    VsTableModule,
    AsyncPipe,
    NgIf,
  ],
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsListComponent implements OnInit {
  private eventsService = inject(EventsDataService);
  private settlementUserService = inject(SettlementUserEntityService);
  private employeeService = inject(EmployeeEntityService);
  private store = inject(Store);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private dateService = inject(DateService);
  private config = inject(CORE_CONFIG);
  filterActive$ = this.route.queryParams.pipe(
    map((queryParams) => !!Object.keys(queryParams).length),
  );
  events$ = this.route.queryParams.pipe(
    switchMap((queryParams, index) => {
      if (index === 0 && !Object.keys(queryParams).length) {
        return of(null);
      }
      const fromObject: any = { ...queryParams };
      if (this.config.portal === AppPortal.CAIG) {
        fromObject.scopeToUser = false; // @TODO - temp workaround, remove when new settlement type is added to handle this
      }
      return this.eventsService.getAll(new HttpParams({fromObject}))
        .pipe(startWith(null));
    }),
    shareReplay(),
  );
  columns: VsTableColumn<AppEvent>[] = [
    new TextColumn({
      title: 'Employee',
      field: 'employeeName',
    }),
    new DateColumn({
      title: 'Date',
      field: 'whenCreated',
      format: 'short',
    }),
    new TextColumn({
      title: 'Message',
      field: 'message',
      expandable: true,
    }),
    new TextColumn({
      title: 'Type',
      field: 'description',
    }),
    new TextColumn({
      title: 'User',
      field: 'user',
    }),
    new ChangesColumn({
      title: 'Changes',
      field: 'changes',
    }),
    new TextColumn({
      title: 'Employee Payment',
      field: 'employeePaymentId',
    }),
    new TextColumn({
      title: 'Payroll',
      field: 'payrollId',
    })
  ];
  form = new FormGroup({});
  formModel$ = this.route.queryParams.pipe(
    map((queryParams) => ({
      dates: {
        start: queryParams['fromDate'],
        end: queryParams['toDate'],
      },
      code: mapNumberArrToModel(queryParams, 'code'),
      userId: mapNumberArrToModel(queryParams, 'userId'),
      employeeId: mapNumberArrToModel(queryParams, 'employeeId'),
    }))
  );
  formFields$: Observable<BaseField<any>[][]> = this.store.select(selectIsAdmin)
    .pipe(
      filter(isBoolean),
      map((isAdmin) => [
        [
          new DateRangeField({
            name: 'dates',
            label: 'Date Range',

          }),
          new SelectField({
            name: 'code',
            label: 'Type(s)',
            options: collectionToKeyValue$(
              this.store.select(selectEventTypesPortal).pipe(filter(isTruthy)),
              'description',
              'code',
            ),
            multiple: true,
          }),
          new SelectField({
            name: 'employeeId',
            label: 'Employee(s)',
            options: collectionToKeyValue$(this.employeeService.entities$, 'name', 'id'),
            multiple: true,
          }),
          ...isAdmin ? [
            new SelectField({
              name: 'userId',
              label: 'User(s)',
              options: collectionToKeyValue$(
                this.settlementUserService.entities$,
                'username',
                'id',
              ),
              multiple: true,
            })
          ] : [],
        ],
      ]),
    );

  ngOnInit() {
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'eventTypesPortal'}));
    this.settlementUserService.loadIfNotLoadedOrLoading();
    this.employeeService.loadIfNotLoadedOrLoading();

    if (!Object.keys(this.route.snapshot.queryParams).length) {
      const fromDate = this.dateService.get().current().month().start();
      this.router.navigate([], {queryParams: {fromDate}});
    }
  }

  applyFilters() {
    const value: any = this.form.value;
    const fromDate = value.dates?.start;
    const toDate = value.dates?.end;
    this.router.navigate([], {
      queryParams: {
        fromDate,
        toDate,
        ...omit(value, 'dates'),
      },
      replaceUrl: true,
    });
  }

  clearFilters() {
    this.router.navigate([], {replaceUrl: true});
    this.form.reset();
  }

  viewEmployee(event: AppEvent): void {
    this.router.navigate(['/employees', event.employeeId]);
  }
}
