import {ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatListModule} from "@angular/material/list";
import {Employee} from "../../core/interface/employees.interface";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {AsyncPipe, CurrencyPipe, NgIf} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Store} from "@ngrx/store";
import {CoreActions} from "../../core/store/core/action-types";
import {combineLatest, filter, map, Observable, ReplaySubject} from "rxjs";
import {selectSettlementContext} from "../../core/store/core/core.selectors";
import {isTruthy, LocationPipe, ZeroPadPipe} from "caig-utils";
import {PhoneMenuComponent} from "../phone-menu/phone-menu.component";
import {EncryptedValueComponent} from "../encrypted-value/encrypted-value.component";

@Component({
  selector: 'lib-employee-contact-info',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    RouterLink,
    LocationPipe,
    ZeroPadPipe,
    CurrencyPipe,
    PhoneMenuComponent,
    EncryptedValueComponent,
  ],
  templateUrl: './employee-contact-info.component.html',
  styleUrls: ['./employee-contact-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeContactInfoComponent implements OnInit, OnChanges {
  private store = inject(Store);
  private employeeSettlementId$ = new ReplaySubject<number>(1);

  @Input({required: true}) employee!: Employee;

  isNotCurrentSettlement$!: Observable<boolean>;

  ngOnInit() {
    const settlementId$ = this.store.select(selectSettlementContext).pipe(filter(isTruthy));
    this.isNotCurrentSettlement$ = combineLatest([settlementId$, this.employeeSettlementId$])
      .pipe(map(([settlementId, employeeSettlementId]) => settlementId !== employeeSettlementId));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.employee) {
      this.employeeSettlementId$.next(this.employee.settlementId);
    }
  }

  changeSettlement(settlementId: number): void {
    this.store.dispatch(CoreActions.changeSettlementContext({settlementId}));
  }
}
