import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldOptions, FieldType} from "../types";

export class RadioField extends BaseField<string | number | null> {
  type = FieldType.Radio;
  options: FieldOptions;
  layout: 'row' | 'column';
  labelPosition: 'before' | 'after';
  constructor(config: RadioFieldConfig) {
    super(config);
    this.options = config.options;
    this.layout = config.layout || 'row';
    this.labelPosition = config.labelPosition || 'after';
    this.flex = config.flex === undefined ? 0 : config.flex;
  }
}

export interface RadioFieldConfig extends BaseFieldConfig<string | number | null> {
  options: FieldOptions;
  layout?: 'row' | 'column';
  labelPosition?: 'before' | 'after';
}
