import {VsTableColumn} from "./types";

export class VsTableColumnFilter<T> {
  constructor(
    public column: VsTableColumn<T>,
    public invert: boolean,
    public empty: boolean,
    public filterSelected: boolean,
    public selected: string[],
    public value?: string | number | null,
    public toValue?: string | number | null,
  ) {
  }
  get isActive(): boolean {
    return !!(this.selected.length || this.value || this.toValue || this.empty);
  }
}
