<mat-date-range-input
  (focus)="focused = true"
  (blur)="focused = false; touched = true; onTouched()"
  [formGroup]="range"
  [dateFilter]="dateFilter"
  [min]="minDate"
  [max]="maxDate"
  [required]="required"
  [disabled]="disabled"
  [rangePicker]="rangepicker">
  <input matStartDate formControlName="start" placeholder="Start date">
  <input matEndDate formControlName="end" placeholder="End date">
</mat-date-range-input>
