import {NgModule, ModuleWithProviders} from '@angular/core';
import { LoginComponent } from './component/login/login.component';
import {AUTH_CONFIG} from './consts';
import {AuthConfig} from './interfaces';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './interceptor/auth.interceptor';
import {AuthService} from './service/auth.service';
import {CommonModule} from '@angular/common';
import {AuthMaterialModule} from './auth-material.module';
import {ReactiveFormsModule} from '@angular/forms';
import { LoginErrorComponent } from './component/login-error/login-error.component';

@NgModule({
  imports: [
    AuthMaterialModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LoginComponent,
    LoginErrorComponent
  ],
  exports: [
    LoginComponent,
  ]
})
export class AuthModule {
  static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: AUTH_CONFIG,
          useValue: config,
        }
      ],
    }
  }
}
