<mat-icon
  class="absolute top-6 cursor-pointer {{column.align === 'end' ? 'left' : 'right'}}-0 pointer"
  [style.display]="hide && !filter.isActive ? 'none' : 'inline-block'"
  [color]="filter.isActive ? 'accent' : undefined"
  aria-label="Filter by column"
  matTooltip="Filter by {{column.title}}"
  [matMenuTriggerFor]="colFilter"
  (click)="$event.stopPropagation()"
  (menuOpened)="filterOpened()">filter_alt</mat-icon>
<mat-menu #colFilter="matMenu" class="vs-table-header-menu">
  <div [ngSwitch]="column.dataType"
       class="col-filter"
       (keydown.tab)="$event.stopPropagation()"
       (click)="$event.stopPropagation()">
    <div class="flex justify-between items-center">
      <div class="title">{{column.title}} Column Filter</div>
      <button mat-button
              aria-label="Reset column filter"
              [disabled]="!filter.isActive"
              (click)="resetFilter(true)">RESET</button>
    </div>
    <dynamic-form *ngSwitchCase="columnTypes.Date" [form]="filterForm" [fields]="dateRangeFields"></dynamic-form>
    <dynamic-form *ngSwitchCase="columnTypes.Currency" [form]="filterForm" [fields]="numberFields"></dynamic-form>
    <dynamic-form *ngSwitchCase="columnTypes.Number" [form]="filterForm" [fields]="numberFields"></dynamic-form>
    <div *ngSwitchCase="columnTypes.Icon"></div>
    <dynamic-form *ngSwitchDefault [form]="filterForm" [fields]="textFields"></dynamic-form>
    <div class="flex justify-between items-center">
      <mat-checkbox #noValueToggle [checked]="emptyChecked" (change)="toggleEmpty($event.checked)">N/A</mat-checkbox>
      <div class="flex-row flex-align-start-center gap-5">
        <mat-slide-toggle *ngIf="showOptions"
                          [checked]="filterOptionsChecked"
                          (change)="toggleFilterOptions($event.checked)"
                          matTooltip="Filter options below based on active table filters">
          <mat-icon>filter_list</mat-icon>
        </mat-slide-toggle>
        <mat-slide-toggle (change)="invertChecked = $event.checked; emitChange()"
                          [checked]="invertChecked"
                          matTooltip="Inverse filter (exclude data matching the given parameters)">
          <mat-icon style="transform: rotate(180deg)">filter_alt</mat-icon>
        </mat-slide-toggle>
      </div>
    </div>
    <mat-divider *ngIf="showOptions"></mat-divider>
    <mat-grid-list *ngIf="columnTypes.Icon === column.dataType" cols="4" rowHeight="64">
      <mat-grid-tile *ngFor="let value of uniqueColumnValues">
        <button mat-icon-button
                class="mat-elevation-z{{selection.isSelected(value) ? 10 : 2}}"
                [color]="selection.isSelected(value) ? 'accent' : undefined"
                [disabled]="noValueToggle.checked"
                [ngClass]="{'selected': selection.isSelected(value)}"
                (click)="selection.toggle(value); emitChange()">
          <mat-icon>{{value}}</mat-icon>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
    <div *ngIf="showViewport" cdkVirtualScrollingElement [style.height.px]="rowHeight * uniqueColumnValues.length">
      <cdk-virtual-scroll-viewport (scrolledIndexChange)="columnValueIndex = $event"
                                   [itemSize]="rowHeight"
                                   class="flex-1">
        <div *cdkVirtualFor="let value of uniqueColumnValues"
             [style.height.px]="rowHeight"
             class="flex-row flex-align-start-center gap-5">
          <mat-checkbox [checked]="selection.isSelected(value)"
                        (change)="selection.toggle(value); emitChange()"
                        [disabled]="noValueToggle.checked"></mat-checkbox>
          <div class="value">{{value}}</div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</mat-menu>
