import {AfterViewInit, Component, inject, OnInit, ViewChild} from '@angular/core';
import {NgForOf} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {PhoneCallOverlayComponent} from "../employees/phone-call-overlay/phone-call-overlay.component";
import {TwilioVoiceService} from "../core/service/twilio-voice.service";
import {OverlayService} from "../core/service/overlay.service";
import {first} from "rxjs/operators";
import {noop} from "rxjs";

@Component({
  selector: 'lib-phone-dialer',
  standalone: true,
  imports: [
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    NgForOf,
  ],
  templateUrl: './phone-dialer.component.html',
  styleUrls: ['./phone-dialer.component.css'],
})
export class PhoneDialerComponent implements OnInit, AfterViewInit {
  private twilioService = inject(TwilioVoiceService);
  private overlayService = inject(OverlayService);
  private bottomSheet = inject(MatBottomSheet);
  private bottomSheetRef = inject(MatBottomSheetRef<PhoneDialerComponent>);
  private data = inject(MAT_BOTTOM_SHEET_DATA);
  phoneNumber: string = '';
  dialerKeys: string[][] = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#']
  ];
  @ViewChild(MatInput) input!: MatInput;

  get isPhoneNumberValid(): boolean {
    const digitsOnly = this.phoneNumber.replace(/\D/g, '');
    return !!this.twilioService.device && digitsOnly.length >= 7;
  }

  ngOnInit() {
    console.log(this.twilioService);
    this.twilioService.initializeDevice().pipe(first()).subscribe();
    if (this.data?.phoneNumber) {
      this.phoneNumber = this.data.phoneNumber;
    }
  }

  ngAfterViewInit() {
    this.input.focus();
  }

  appendDigit(digit: string): void {
    this.phoneNumber += digit;
    this.input.focus();
  }

  deleteDigit(): void {
    this.phoneNumber = this.phoneNumber.slice(0, -1);
    this.input.focus();
  }

  call(): void {
    const onDisconnect = () => {
      this.overlayService.close();
      this.bottomSheet.open(PhoneDialerComponent, { data: {phoneNumber: this.phoneNumber} });
    };
    this.twilioService.call(this.phoneNumber, noop, onDisconnect);
    this.bottomSheetRef.dismiss();
    this.overlayService.open(PhoneCallOverlayComponent);
  }
}
