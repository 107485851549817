import {DefaultDataServiceConfig} from "@ngrx/data";

const userSettlementUrl = 'api/user/forSettlement/';

export const baseDataServiceConfig: DefaultDataServiceConfig = {
  root: 'api',
  entityHttpResourceUrls: {
    SettlementUser: {
      entityResourceUrl: userSettlementUrl,
      collectionResourceUrl: userSettlementUrl,
    },
  }
};
