import {Injectable, inject, ComponentRef, Type} from '@angular/core';
import {Overlay} from '@angular/cdk/overlay';
import {SidenavLocals, SidenavComponent} from './interfaces';
import {Observable, noop} from 'rxjs';
import {ComponentPortal} from '@angular/cdk/portal';
import {SidenavStackItemComponent} from './sidenav-stack-item/sidenav-stack-item.component';

@Injectable({providedIn: 'root'})
export class SidenavStackService {
  private overlay = inject(Overlay);
  private stack: ComponentRef<SidenavStackItemComponent<any>>[] = [];

  public open<T = any>(
    title: string,
    component: Type<SidenavComponent<T>>,
    locals?: SidenavLocals,
  ): Observable<T | null> {
    const overlayRef = this.overlay.create({width: '100%', height: '100%'});
    const portal = new ComponentPortal(SidenavStackItemComponent<T>);
    const sidenavRef = overlayRef.attach(portal);

    sidenavRef.instance.onClosing = () => this.popSidenav();
    sidenavRef.instance.onClosed = () => {
      sidenavRef.destroy();
      overlayRef.detach();
    };
    sidenavRef.instance.onSaved = noop;
    sidenavRef.instance.title = title;
    sidenavRef.instance.content = { component, locals };

    this.pushSidenav(sidenavRef);

    return new Observable<T | null>((subscriber) => {
      sidenavRef.instance.onClosing = () => {
        this.popSidenav();
        subscriber.error();
      };
      sidenavRef.instance.onSaved = (data) => {
        subscriber.next(data);
        subscriber.complete();
      }
    });
  }

  public closeLast(): void {
    if (this.stack.length) {
      this.stack[this.stack.length - 1].instance.close();
    }
  }

  public closeAll(): void {
    const stack = this.stack.reverse();
    stack.forEach((sidenavRef) => sidenavRef.instance.close());
  }

  private popSidenav(): void {
    this.stack.pop();
    if (this.stack.length) {
      this.stack[this.stack.length - 1].instance.open();
    }
  }

  private pushSidenav(sidenavRef: ComponentRef<SidenavStackItemComponent<any>>): void {
    if (this.stack.length) {
      this.stack[this.stack.length - 1].instance.defer();
    }
    this.stack.push(sidenavRef);
  }
}
