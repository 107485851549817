import {Injectable, inject} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {of, Observable} from 'rxjs';
import {AuthService} from '../service/auth.service';
import {AuthActions} from './action-types';
import {Router, ActivatedRoute} from '@angular/router';
import {AUTH_CONFIG, getRemainingHours} from '../consts';
import {Action} from '@ngrx/store';
import {AuthToken} from '../interfaces';
import {TokenStorageError} from '../enums';

const AUTH_TOKEN_STORAGE_KEY = 'authToken';

/**
 * Returns an unexpired AuthToken from local storage or a string error message.
 */
const canAutoLogin = (): AuthToken | string => {
  try {
    const storedToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    const parsedToken: AuthToken | null = storedToken ? JSON.parse(storedToken) : null;
    if (parsedToken) {
      const remainingHours = getRemainingHours(parsedToken.expires);
      if (remainingHours === 0) {
        return TokenStorageError.Expired;
      }
    }
    return parsedToken || TokenStorageError.Empty;
  } catch (error) {
    return TokenStorageError.ParseFailure;
  }
}

const loginFromCache = (): Observable<Action> => {
  return of(canAutoLogin()).pipe(
    map((res) => typeof res === 'string' ?
      AuthActions.loginFailure({ error: res }) :
      AuthActions.loginSuccess({ token: res })
    )
  );
}

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private authService = inject(AuthService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private config = inject(AUTH_CONFIG);

  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(({ withCredentials }) =>
        withCredentials ?
          this.loginWithCredentials(withCredentials) :
          loginFromCache()
      ),
    )
  );

  public loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      tap((success) => {
        if (success.redirect) {
          this.router.navigateByUrl(this.route.snapshot.queryParams['redirectUrl'] || '/');
        }
        localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify(success.token));
      })
    ), { dispatch: false });

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap((action) => {
        const redirectUrl = action.redirectUrl || (action.redirect ? this.router.url : undefined);
        const queryParams = redirectUrl !== '/' ? { redirectUrl } : { };
        this.router.navigate([this.config.loginRoute], { queryParams });
        localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
      }),
    ), { dispatch: false });

  public refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      exhaustMap(() =>
        this.authService.refreshToken().pipe(
          tap((token) => localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify(token))),
          map((token) => AuthActions.refreshTokenCompleted({ token })),
          catchError(() => of(AuthActions.refreshTokenCompleted({}))),
        )
      ),
    ),
  );

  private loginWithCredentials(credentials: { username: string, password: string }): Observable<Action> {
    return this.authService.login(credentials.username, credentials.password).pipe(
      map((token) => AuthActions.loginSuccess({ token, password: credentials.password, redirect: true })),
      catchError((error) => of(AuthActions.loginFailure({ error: error.error?.message || error.message })))
    );
  }
}
