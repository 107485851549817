<div class="flex items-center">
  <div *ngIf="decryptedValue; else isEncrypted" class="flex items-center">
    <button mat-icon-button color="primary" (click)="encrypt()" matTooltip="Hide">
      <mat-icon>lock_open</mat-icon>
    </button>
    <b>{{decryptedValue}}</b>
  </div>
  <ng-template #isEncrypted>
    <button *ngIf="employeeValue; else noValue" mat-flat-button color="primary" (click)="decrypt()" [disabled]="isProcessing">
      <mat-icon class="mr-6">{{isProcessing ? 'key' : 'lock'}}</mat-icon>{{isProcessing ? 'Decrypting...' : employeeValue}}
    </button>
    <ng-template #noValue>
      <div>Missing</div>
    </ng-template>
  </ng-template>
  <div *ngIf="!employeeValue || decryptedValue" class="flex items-center ml-6">
    <button mat-icon-button color="accent" (click)="edit()" matTooltip="Edit" [disabled]="isProcessing">
      <mat-icon>edit</mat-icon>
    </button>
    <button *ngIf="!disableDelete && employeeValue" mat-icon-button color="warn" (click)="remove()" matTooltip="Delete" [disabled]="isProcessing">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
