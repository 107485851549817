import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  CloseMessage,
  MenuMessage,
  ProcessingMessage,
  SidenavComponent,
  SidenavComponentMenuItem,
  SidenavComponentMessage
} from "sidenav-stack";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {MatCardModule} from "@angular/material/card";
import {DynamicFormModule, InputField} from "dynamic-form";
import {FormGroup} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {Employee} from "../../core/interface/employees.interface";

@Component({
  selector: 'lib-add-employee',
  standalone: true,
  imports: [
    MatCardModule,
    DynamicFormModule,
  ],
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEmployeeComponent implements SidenavComponent<Employee>, OnInit, OnDestroy {
  private dataService = inject(EmployeeEntityService);
  private onDestroy$ = new Subject<void>();

  @Output() controlMsg = new EventEmitter<SidenavComponentMessage<Employee>>(true);
  @Input() model: Partial<Employee> | undefined;

  fields = [
    [
      new InputField({
        name: 'firstName',
        label: 'First Name',
        required: true,
      }),
    ],
    [
      new InputField({
        name: 'middleName',
        label: 'Middle Name/Initial',
      }),
    ],
    [
      new InputField({
        name: 'lastName',
        label: 'Last Name',
        required: true,
      }),
    ],
    [
      new InputField({
        name: 'ssn',
        label: 'SSN',
      })
    ]
  ];
  form = new FormGroup({});

  ngOnInit() {
    const saveBtn: SidenavComponentMenuItem = {
      name: 'Save',
      color: 'primary',
      disabled: true,
      callback: () => {
        this.controlMsg.emit(new ProcessingMessage(true));
        const payload = {...this.model, ...this.form.value} as any;
        this.dataService.add(payload).subscribe(
          (employee) => this.controlMsg.emit(new CloseMessage(employee)),
          () => this.controlMsg.emit(new ProcessingMessage(false)),
        );
      },
    };

    this.controlMsg.emit(new MenuMessage([saveBtn]));

    this.form.statusChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((status) => {
        saveBtn.disabled = status !== 'VALID';
        this.controlMsg.emit(new MenuMessage([saveBtn]));
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }
}
