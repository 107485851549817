import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  OnDestroy,
  ComponentRef,
  ViewChild,
  ViewContainerRef,
  inject,
  ChangeDetectorRef,
  ElementRef, HostListener,
} from '@angular/core';
import {SidenavStackContent, SidenavComponent, SidenavComponentMenuItem} from '../interfaces';
import {ReplaySubject, map, Observable, filter, startWith, takeUntil} from 'rxjs';
import {SidenavComponentMessage} from '../types';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MenuMessage, ProcessingMessage, TitleMessage, CloseMessage} from '../classes';
import {MatSidenav, MatSidenavModule} from '@angular/material/sidenav';
import {OverlayModule} from "@angular/cdk/overlay";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'lib-sidenav-stack-item',
  templateUrl: './sidenav-stack-item.component.html',
  styleUrls: ['./sidenav-stack-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    AsyncPipe,
    OverlayModule,
    MatSidenavModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
})
export class SidenavStackItemComponent<T> implements OnInit, OnDestroy {
  @Input({required: true}) public onClosed!: () => void;
  @Input({required: true}) public onClosing!: () => void;
  @Input({required: true}) public onSaved!: (res: any) => void;
  @Input({required: true}) public title!: string;
  @Input({required: true}) public content!: SidenavStackContent<T>;

  @ViewChild('container', { read: ElementRef }) public containerEl!: ElementRef;
  @ViewChild('sidenav', { read: ElementRef }) public sidenavEl!: ElementRef;
  @ViewChild('content', { read: ViewContainerRef }) public contentView!: ViewContainerRef;
  @ViewChild(MatSidenav, { static: true }) public sidenav!: MatSidenav;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  private controller$ = new ReplaySubject<SidenavComponentMessage>();
  private onDestroy$ = new ReplaySubject<void>();
  private bp = inject(BreakpointObserver);
  private cd = inject(ChangeDetectorRef);

  public sidenavWidth$ = this.bp.observe(Breakpoints.Handset).pipe(
    map(({matches}) => matches ? 100 : 75)
  );
  public menu$: Observable<SidenavComponentMenuItem[]> = this.controller$.pipe(
    filter((msg): msg is MenuMessage => msg instanceof MenuMessage),
    map((msg) => msg.menu),
  );
  public isProcessing$: Observable<boolean> = this.controller$.pipe(
    filter((msg): msg is ProcessingMessage => msg instanceof ProcessingMessage),
    map((msg) => msg.isProcessing),
  );
  public title$!: Observable<string>;
  public contentRef: ComponentRef<SidenavComponent<T>> | undefined;

  public ngOnInit() {
    setTimeout(() => {
      this.sidenav.open();
      this.cd.detectChanges();
    });

    this.title$ = this.controller$.pipe(
      filter((msg): msg is TitleMessage => msg instanceof TitleMessage),
      map((msg) => msg.title),
      startWith(this.title),
    );

    this.controller$.pipe(
      filter((msg): msg is CloseMessage<T> => msg instanceof CloseMessage),
      map((msg) => msg.saveResult),
      takeUntil(this.onDestroy$),
    )
      .subscribe((data) => {
        this.onSaved(data);
        this.close();
      });
  }

  public ngOnDestroy() {
    this.contentRef?.destroy();
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }

  public open(): void {
    this.containerEl.nativeElement.style.transform = 'translateX(0)';
  }

  public close(): void {
    this.open();
    this.sidenav.close();
    this.cd.detectChanges();
  }

  public defer(): void {
    const width = this.sidenavEl.nativeElement.offsetWidth;
    this.containerEl.nativeElement.style.transform = 'translateX(-' + width + 'px)';
  }

  public createContent(): void {
    this.contentRef = this.contentView.createComponent(this.content.component);
    Object.assign(this.contentRef.instance, this.content.locals);
    this.contentRef.instance.controlMsg
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((msg) => this.controller$.next(msg));
  }
}
