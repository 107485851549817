import {Component} from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {TextareaField} from "../../class/textarea-field";

@Component({
  selector: 'lib-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.css'],
})
export class TextareaFieldComponent extends BaseFieldComponent<TextareaField> {

}
