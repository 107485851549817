import {Component} from "@angular/core";
import {BaseFieldComponent} from "../base-field.component";
import {DateRangeField} from "../../class/date-range-field";

@Component({
  selector: 'lib-date-range-field',
  templateUrl: './date-range-field.component.html',
  styleUrls: ['./date-range-field.component.css'],
})
export class DateRangeFieldComponent extends BaseFieldComponent<DateRangeField> {

}
