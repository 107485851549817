import { NgModule } from '@angular/core';
import {UsersRoutingModule} from "./users-routing.module";
import {UsersListComponent} from "./users-list/users-list.component";
import {EditUserComponent} from "./edit-user/edit-user.component";

@NgModule({
  imports: [
    UsersRoutingModule,
    UsersListComponent,
    EditUserComponent,
  ]
})
export class UsersModule { }
