import {BaseField, BaseFieldConfig} from "./base-field";
import {FieldType} from "../types";

export class CheckboxField extends BaseField<boolean> {
  type = FieldType.Checkbox;
  labelPosition: 'before' | 'after';
  indeterminate: boolean;
  constructor(config: CheckboxFieldConfig) {
    super(config);
    this.labelPosition = config.labelPosition || 'after';
    this.indeterminate = !!config.indeterminate;
    this.flex = config.flex === undefined ? 0 : config.flex;
  }
}

export interface CheckboxFieldConfig extends BaseFieldConfig<boolean> {
  labelPosition?: 'before' | 'after';
  indeterminate?: boolean;
}
