<div class="flex h-full flex-col box-border p-4">
  <div class="flex overflow-x-auto overflow-y-hidden gap-4 h-14">
    <div class="min-w-[150px]" *ngFor="let btn of toolbarButtons">
      <a class="w-full"
         mat-stroked-button
         [color]="btn.color"
         [disabled]="!!btn.disabled"
         queryParamsHandling="preserve"
         [routerLink]="btn.routerLink">
        <mat-icon>{{btn.icon}}</mat-icon>
        <div>{{btn.label}}</div>
      </a>
    </div>
  </div>
  <div class="flex justify-between items-center">
    <h1>Edit Employee</h1>
    <div class="flex items-center gap-4">
      <lib-customize-event #toggle="customizeEvent" eventType="eventTypesPortal" [defaultEventCode]="202"></lib-customize-event>
      <button mat-raised-button color="primary" (click)="save(toggle.isActive ? toggle.form.value : {})" [disabled]="(toggle.isActive && toggle.form.invalid) || (disableSave$ | async)">Save</button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="employee$ | async as employee" class="flex flex-grow overflow-auto">
    <mat-grid-list *ngIf="gridColumns$ | async as cols" [cols]="cols" class="h-full w-full mt-4" rowHeight="10px">
      <mat-grid-tile *ngFor="let section of sections"
                     [colspan]="(isHandset$ | async) ? 1 : section.cols"
                     [rowspan]="section.rowspan">
        <mat-card class="h-full w-full overflow-auto">
          <mat-card-header>
            <mat-card-title>{{section.title}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <dynamic-form [form]="form" [fields]="section.fields" [model]="employee" mergeFields></dynamic-form>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
