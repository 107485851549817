import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EmailActions} from "./action-types";
import {concatMap, filter, map, withLatestFrom} from "rxjs";
import {EmailService} from "../../service/email.service";
import {SignatureBlockService} from "../../service/entity-data/signature-block.service";
import {Store} from "@ngrx/store";
import {selectEmailSignatures, selectEmailTemplates, selectFields} from "./email.selectors";

@Injectable()
export class EmailEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private emailService = inject(EmailService);
  private signatureService = inject(SignatureBlockService);

  public loadEmailTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.loadEmailTemplates),
      withLatestFrom(this.store.select(selectEmailTemplates)),
      filter(([action, state]) => !state),
      concatMap(() => this.emailService.getTemplates()),
      map((templates) => EmailActions.emailTemplatesLoaded({templates}))
    )
  );
  public loadEmailSignatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.loadEmailSignatures),
      withLatestFrom(this.store.select(selectEmailSignatures)),
      filter(([action, state]) => !state),
      concatMap(() => this.signatureService.getAll()),
      map((signatures) => EmailActions.emailSignaturesLoaded({signatures}))
    )
  );
  public loadFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.loadFields),
      withLatestFrom(this.store.select(selectFields)),
      filter(([action, state]) => !state),
      concatMap(() => this.emailService.getTemplatePlaceholders()),
      map((fields) => EmailActions.fieldsLoaded({fields}))
    )
  );
}
