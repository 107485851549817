import {Component} from '@angular/core';
import {BaseFieldOptionsComponent} from "../base-field-options.component";
import {RadioField} from "../../class/radio-field";

@Component({
  selector: 'lib-radio-field',
  templateUrl: './radio-field.component.html',
  styleUrls: ['./radio-field.component.css'],
})
export class RadioFieldComponent extends BaseFieldOptionsComponent<RadioField> {

}
