<input
  matInput
  #inputEl
  autocomplete="off"
  type="text"
  (focus)="focused = true"
  (blur)="focused = false; touched = true; onTouched()"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [value]="value || ''"
  (keydown)="prevValue = inputEl.value"
  (keyup)="onKeyup($event)" />
