import {inject, Injectable} from "@angular/core";
import {LoadingOverlayComponent} from "../component/loading-overlay/loading-overlay.component";
import {ComponentPortal} from "@angular/cdk/portal";
import {catchError, map, merge, Observable, of, switchMap, tap, throwError} from "rxjs";
import {Overlay} from "@angular/cdk/overlay";

@Injectable({providedIn: 'root'})
export class LoadingService {
  private overlay = inject(Overlay);
  private overlayRef = this.overlay.create();
  private portal = new ComponentPortal(LoadingOverlayComponent);
  isLoading$ = merge(this.overlayRef.attachments(), this.overlayRef.detachments()).pipe(
    map(() => this.overlayRef.hasAttached()),
  );
  load<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => this.attach()),
      switchMap(() => obs$),
      tap(() => this.detach()),
      catchError((err) => {
        this.detach()
        return throwError(err);
      }),
    );
  }
  attach(): void {
    if (!this.overlayRef.hasAttached()) {
      this.overlayRef.attach(this.portal);
    }
  }
  detach(): void {
    this.overlayRef.detach();
  }
}
