import {DateRange, DefaultMatCalendarRangeStrategy} from "@angular/material/datepicker";
import {inject, Injectable} from "@angular/core";
import {DateAdapter} from "@angular/material/core";
import {DateRangeComponent} from "./date-range.component";

@Injectable()
export class DateRangeSelectionStrategy<D> extends DefaultMatCalendarRangeStrategy<D> {
  constructor(private _component: DateRangeComponent) {
    super(inject(DateAdapter));
  }
  override selectionFinished(date: D, currentRange: DateRange<D>): DateRange<D> {
    if (this._component.disableStartDate) {
      this._component.daterangePicker?.close();
      return new DateRange<D>(null, date);
    }
    return super.selectionFinished(date, currentRange);
  }
}
