import {Component, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'lib-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginErrorComponent {
  @Input({required: true}) public message!: string;
}
