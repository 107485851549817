import {createReducer, on} from "@ngrx/store";
import {initialEnumsState} from "./enums.state";
import {EnumsActions} from "./action-types";

export const enumsReducer = createReducer(
  initialEnumsState,
  on(EnumsActions.loadEnum, (state, { enumType }) => ({
    ...state,
    [enumType]: {
      ...state[enumType],
      loading: true,
    },
  })),
  on(EnumsActions.enumError, (state, { enumType }) => ({
    ...state,
    [enumType]: {
      ...state[enumType],
      loading: false,
      loaded: false,
      error: true,
    }
  })),
  on(EnumsActions.enumLoaded, (state, { enumType, values }) => ({
    ...state,
    [enumType]: {
      ...state[enumType],
      loading: false,
      loaded: true,
      error: false,
      values
    }
  })),
  on(EnumsActions.addEnum, (state, {enumType, value}) => ({
    ...state,
    [enumType]: {
      ...state[enumType],
      values: [value, ...(state[enumType].values || [])],
    }
  })),
  on(EnumsActions.deleteEnum, (state, {enumType, value}) => ({
    ...state,
    [enumType]: {
      ...state[enumType],
      values: (state[enumType].values as any[])?.filter((val) => val != value),
    }
  })),
  on(EnumsActions.updateEnum, (state, {enumType, oldValue, newValue}) => ({
    ...state,
    [enumType]: {
      ...state[enumType],
      values: (state[enumType].values as any[])?.map((val) => val == oldValue ? newValue : val),
    },
  })),
);
