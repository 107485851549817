import {createAction, props} from "@ngrx/store";
import {EnumsState} from "./enums.state";

export const loadEnum = createAction(
  '[Enums] Load Enums',
  props<{enumType: keyof EnumsState}>(),
);
export const enumLoaded = createAction(
  '[Enums] Enums Loaded',
  props<{enumType: keyof EnumsState, values: any[]}>()
);
export const enumError = createAction(
  '[Enums] Enums Error',
  props<{enumType: keyof EnumsState}>(),
);

export const addEnum = createAction(
  '[Enums] Add Enum',
  props<{enumType: keyof EnumsState, value: any}>()
);

export const deleteEnum = createAction(
  '[Enums] Delete Enum',
  props<{enumType: keyof EnumsState, value: any}>(),
);

export const updateEnum = createAction(
  '[Enums] Update Enum',
  props<{enumType: keyof EnumsState, oldValue: any, newValue: any}>(),
);
